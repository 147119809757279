<template>
  <div class="page-task-list-air">
    <TaskList
      ref="listRef"
    />
    


  </div>
</template>

<script>
import TaskList from "./list";

export default {
  components: {
    TaskList,
  },
  data() {
    return {
      pickerOptions: {}, // 设置时间禁选
      editProps: null,
      visible: {
        edit: false,
        notice: false,
        readonly: false,
      },
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.page-task-list-air {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  ::v-deep .el-input .el-input__icon {
    font-size: 16px;
    line-height: 34px;
  }
  .task-list-notice-wrap {
    display: flex;
    .map-box {
      width: 436px;
      height: 274px;
      border: 1px solid red;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 16px;
    }
    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
          width: 98px;
        }
        .prop-field-v {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.page-task-list-air .pub_dialog {
  width: 400px;
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
  bottom: auto;
  .headBtn {
    display: flex;
    justify-content: center;
  }
  .el-dialog__body {
    .date {
      display: flex;
      align-items: center;
      .dateEnd {
        .el-form-item__label {
          display: none;
        }
        .el-form-item__content {
          margin-top: 19px;
        }
      }
    }
    .el-color-picker__trigger {
      width: 100%;
    }
  }
}
</style>
