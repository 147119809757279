<template>
  <div class="page-task-achievement">
    <div class="iconfont icon-guanbi close-popup" @click="() => $emit('on-subTaskAchievementClose')"></div>
    <List :subTaskAchievementBaseId="subTaskAchievementBaseId "/>
  </div>
</template>

<script>
import List from "./list";
export default {
  components: { List },
  props: {
    subTaskAchievementBaseId: {
      type: Number,
      default: null
    }
  },
};
</script>

<style lang="scss" scoped>
.page-task-achievement {
  background: rgba(8, 37, 79, 1);
  width: 1600px;
  height: 800px;
  border: 2px solid #0067ab;
  position: absolute;
  top: 123px;
  left: 50%;
  margin-left: -800px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 100;
  .close-popup{
    position: absolute;
    top: 16px;
    right: 16px;
    color: #44b1fa;
    font-size: 24px;
    cursor: pointer;
  }
}
</style>