<template>
  <div class="popup">
    <div class="seeMore">
    <!-- 图片和视频选择弹框 -->
    <AchievementSelection 
      :subTaskAchievementBaseId="generalTaskReportId" 
      :aisPictrueOrVideoList="aisPictrueOrVideoList" 
      :selectType="selectType" v-if="selectType"  
      @on-selectpAv="selectType = null"
      @on-saveSelectpAv="saveSelectpAv"
    >
    </AchievementSelection>
    <!-- 关闭按钮 -->
    <div @click="$emit('on-TaskReport')" class="back-button"></div>
    <!-- 测边控制栏 -->
    <div v-if="edit_form" class="control-bar">
      <span 
        v-loading="downloadLoading"
        element-loading-text=""
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        v-if="edit_form.status !== 0" 
        title="下载" 
        class="iconfont icon-xiazaimoban span" 
        @click="subtaskReportExport"
      ></span>
      <template v-if="edit_form && edit_form.status === 0">
        <span title="审批" class="iconfont icon-shenpi span" @click="approval"></span>
        <span v-if="donEdit" title="编辑" @click="editReport" class="iconfont icon-genggai span"></span>
        <span v-if="!donEdit" title="保存" @click="saveModify" class="iconfont icon-baocun span"></span>
        <span v-if="!donEdit" title="取消编辑" @click="cancelEdit" class="iconfont icon-quxiao-moren span"></span>
      </template>
    </div>
    <div class="seeMore-center">
      <div class="report" v-loading="loading" element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      >
          <div style="width: 100%;padding-bottom:30px" v-if="edit_form">
            
            <div class="header">
              <div class="title"><span class="text">总任务作业报告</span></div>
            </div>
            <div class="body">
              <el-form
                ref="edit_form"
                :rules="rules.edit_form"
                :model="edit_form"
                class="linear-form vertical edit-form"
              >
                <!-- 机构信息 -->
                <div style="width: 100%" class="aa">
                  <el-form-item label="任务名称" prop="taskTitle">
                    <el-input
                      v-model="edit_form.taskTitle"
                      placeholder="请输入任务名称"
                      clearable
                      :readonly="true"
                    />
                  </el-form-item>
                  <el-form-item label="发起机构" prop="organizationName">
                    <el-input
                      v-model="edit_form.organizationName"
                      placeholder="请输入发起机构"
                      clearable
                      :readonly="true"
                    />
                  </el-form-item>
                  
                  <el-form-item label="处理机构" prop="applyOrganization">
                    <el-input
                      v-model="edit_form.applyOrganization"
                      placeholder="请输入处理机构"
                      clearable
                      :readonly="true"
                    />
                  </el-form-item>

                  <el-form-item label="发起需求人" prop="name">
                    <el-input
                      v-model="edit_form.name"
                      placeholder="请输入发起需求人"
                      clearable
                      :readonly="true"
                    />
                  </el-form-item>

                 <el-form-item label="任务类型" prop="cate">
                    <el-input
                      v-model="edit_form.cate"
                      placeholder="请选择任务类型"
                      clearable
                      :readonly="true"
                    />
                  </el-form-item>
                </div>
                <!-- 飞行记录 -->
                <div class="header" style="margin-bottom:20px">
                 <div class="title"><span class="text">飞行纪录</span></div>
                </div>
                <div style="width: 100%" class="aa">
                  <el-form-item label="飞行总架次" prop="sortieNum">
                    <el-input
                      v-model="edit_form.sortieNum"
                      placeholder="请输入飞行总架次"
                      clearable
                      :readonly="donEdit"
                    />
                  </el-form-item>
                  <el-form-item label="飞行总时间" prop="date">
                    <el-date-picker
                      v-model="edit_form.date"
                      type="datetimerange"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      clearable
                      :readonly="donEdit"
                    />
                  </el-form-item>

                  <el-form-item label="飞行总里程" prop="flightDistance">
                    <el-input
                      v-model="edit_form.flightDistance"
                      placeholder="请输入飞行总里程"
                      clearable
                      :readonly="donEdit"
                    />
                  </el-form-item>

                  <el-form-item label="飞行总时长" prop="flightTime">
                    <el-input
                      v-model="edit_form.flightTime"
                      placeholder="请输入飞行时长"
                      clearable
                      :readonly="donEdit"
                    />
                  </el-form-item>

                  <el-form-item label="飞手姓名" prop="operation">
                    <el-input
                      v-model="edit_form.operation"
                      placeholder="请输入飞手姓名"
                      clearable
                      :readonly="donEdit"
                    />
                  </el-form-item>

                  <el-form-item label="派单员" prop="handleUser">
                    <el-input
                      v-model="edit_form.handleUser"
                      placeholder="请输入飞派单员"
                      clearable
                      :readonly="donEdit"
                    />
                  </el-form-item>

                  <el-form-item label="详细作业位置" prop="origin">
                    <el-input
                      v-model="edit_form.origin"
                      placeholder="请输入详细作业位置"
                      clearable
                      :readonly="donEdit"
                    />
                  </el-form-item>

                  <el-form-item label="预警总次数" prop="warningNum">
                    <el-input
                      v-model="edit_form.warningNum"
                      placeholder="请输入预警总次数"
                      clearable
                      :readonly="true"
                    />
                  </el-form-item>
                  
                </div>
                <!-- 预警消息 -->
                <div class="header" style="margin-bottom:20px">
                  <div class="title"><span class="text">预警信息</span></div>
                </div>

                <div class="early-warning">
                  <div v-if="!donEdit" class="iconfont icon-tianjia add-warning" title="新增预警"  @click="addRow"></div>
                  <Table
                    :columns="columns"
                    v-if="!videoslist.url"
                    :dataSource="edit_form.ais"
                  >
                    <template #warningTypeName="{ row }">
                        <!-- 预警类别 -->
                        <span v-if="donEdit" :title="alertTypeDropDown.filter(item=>{return item.id == row.warningTypeId})[0].name" class="iconfont">{{
                          alertTypeDropDown.filter(item=>{
                            return item.id == row.warningTypeId
                          })[0].name
                        }}</span>
                        <el-form-item style="margin-bottom:0px" v-else>
                          <el-select  v-model="row.warningTypeId" size="mini" clearable  placeholder="请选择" style="padding-right: 0px;">
                            <el-option
                              v-for="item in alertTypeDropDown"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                            />
                          </el-select>
                        </el-form-item>
                    </template>
                    <template #warningPhotoUrl="{ row }">
                      <!-- 图片 -->
                      <div v-if="donEdit">
                        <div v-if="row.warningPhotoUrl" class="warningphotourl-img" >
                          <img @click="showPicture(row.warningPhotoStorage.substring(1,row.warningPhotoStorage.length-1).split(','))" :src="row.warningPhotoStorage.substring(1,row.warningPhotoStorage.length-1).split(',')[0]" alt="图片加载失败">
                        </div>
                        <div v-else>暂无</div>
                      </div>
                      <div v-else>
                        <div v-if="row.warningPhotoUrl" class="warningphotourl-img" >
                          <img @click="editPicture(row.warningPhotoStorage.substring(1,row.warningPhotoStorage.length-1).split(','))" :src="row.warningPhotoStorage.substring(1,row.warningPhotoStorage.length-1).split(',')[0]" alt="">
                        </div>
                        <div @click="editPicture([],row.id)"  v-else>
                          <span class="add-picture"></span>
                        </div>
                      </div>
                    </template>
                    <template #video="{ row }">
                      <span v-if="donEdit">
                        <span v-if="row.video" @click="showVideo(row)" title="视频" class="iconfont">
                          <span v-if="row.video.indexOf('/api') > -1 ">{{ row.videoTwo }}</span>
                          <span v-else>{{ row.video }}</span>
                        </span>
                        <div v-else>暂无</div>
                      </span>
                      <span v-else>
                        <span v-if="row.video"  @click="editViedo(row)" title="视频" class="iconfont">{{
                          row.video
                        }}</span>
                        <span @click="editViedo(row)" v-else>
                          <span class="add-picture"></span>
                        </span>
                      </span>
                        
                    </template>
                    <template #remark="{ row }">
                        <div  v-if="donEdit">
                          <span :title="row.remark" class="iconfont">{{
                            row.remark || '暂无'
                          }}</span>
                        </div>
                        <el-form-item v-else style="width:92%;margin-left:16px">
                          <el-input
                            v-model="row.remark"
                            placeholder="请输入目标事物信息"
                            clearable
                          />
                        </el-form-item>
                    </template>

                    <template #action="{ row }">
                        <span style="cursor:pointer" v-if="!donEdit" @click="deleteRow(row)"> 删除 </span>
                    </template>
                  </Table>
                  <!-- 视频播放 -->
                  <div v-else class="video">
                    <div class="close-video">
                      <i @click="videoslist.url = null" class="el-icon-circle-close"></i>
                    </div>
                    <VideoPlay  :datalist="videoslist" />
                  </div>
                </div>

                <div class="header" style="margin-bottom:20px">
                  <div class="title"><span class="text">报告总结</span></div>
                </div>
                <el-form-item prop="remark" style="width:100%">
                  <el-input
                  clear="textarea-content"
                    type="textarea"
                    :autosize="{ minRows: 10}"
                    placeholder="请输入报告总结"
                    v-model="edit_form.remark"
                    :readonly="donEdit"
                    >
                  </el-input>
                </el-form-item>
                <div v-if="edit_form.status === 1 || edit_form.status === 2" class="report-name">
                    报告编订人:{{edit_form.reportApplyUser}}
                </div>
              </el-form>
            </div>
          </div>
      </div>
    </div>

    <el-dialog
      title="温馨提示"
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="384px"
      class="taskapprove"
    >
      <div style="color: #cce8ff; text-align: center; font-size: 20px;">报告审批是否通过？</div>
      <div class="button">
        <div @click="handle_approve(1)" class="linear-btn">审批通过</div>
        <div @click="handle_approve(2)" class="linear-btn">审批不通过</div>
      </div>
    </el-dialog>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Table from "@/components/common/table";
import API from "@/api";
import dayjs from "dayjs";
import VideoPlay from "@/components/device/player/vidoeoJs";  // 播放视频组件
import AchievementSelection from "./achievementSelection.vue";  // 播放视频组件
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer"; // 轮播图片插件
import Vue from "vue";
import { nanoid } from "nanoid";
Vue.use(VueViewer);
export default {
  components: {
    Table,
    VideoPlay,
    AchievementSelection
  },
  props: {
    generalTaskReportId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  data() {
    
    return {
      downloadLoading: false, //下载报告转圈
      selectType: null, // 打开飞行成果选择时选择的是图片还是视频
      aisId: null, // 要修改的预警信息的id
      aisPictrueOrVideoList: null, // 要修改的预警已选择的图片或则视频
      columns: [  // 预警列表信息
        { prop: "warningTypeName", slot: true, label: "预警类别" },
        { prop: "warningPhotoUrl", slot: true, label: "图片" },
        { prop: "video", slot: true, label: "视频" },
        { prop: "remark", slot: true, label: "目标事物信息" },
      ],
      alertTypeDropDown: null, // 预警类型下拉
      videoslist: {
        url: null,
        videoSign: null,
        videoStartEnd: null
      }, // 视频链接
      dialogVisible: false,  // 审批的东西
      donEdit: true, // 不编辑
      list: null,
      edit_form: null, // 整个表单存储的信息，获取表单详情的东西存这里
      edit_form_copy: null, // 编辑的时候备份，取消编辑时用到
      rules: {
        edit_form: {
          sortieNum: [
            { required: true, message: '请输入飞行总架次', trigger: 'blur' }
          ],
          date: [
            { required: true, message: '请输入飞行总时间', trigger: 'blur' }
          ],
          flightDistance: [
            { required: true, message: '请输入飞行总里程', trigger: 'blur' }
          ],
          flightTime: [
            { required: true, message: '请输入飞行总时长', trigger: 'blur' }
          ],
          operation: [
            { required: true, message: '请输入飞手姓名', trigger: 'blur' }
          ],
          handleUser: [
            { required: true, message: '请输入派单员', trigger: 'blur' }
          ],
          origin: [
            { required: true, message: '请输入作业详细位置', trigger: 'blur' }
          ],
          warningNum: [
            { required: true, message: '请输入预警总次数', trigger: 'blur' }
          ],
        },
      },
      loading: false
    };
  },
  methods: {
    async subtaskReportExport() { // 总任务报告下载
      if(this.downloadLoading == true){
        return
      } else {
        this.downloadLoading = true
        const res = await API.TASK.SumReportExport(this.generalTaskReportId)
        this.downloadLoading = false
        const c = document.createElement("a");
        c.href = URL.createObjectURL(res);
        c.download = `${this.edit_form.taskTitle}.doc`;
        c.click();
        c.remove();
      }
    },
    // -------------------------------------------------------------编辑报告的函数开始
    editReport() { // 点击编辑按钮
      this.edit_form_copy = JSON.parse(JSON.stringify(this.edit_form))
      this.donEdit = false
      this.columns = [  // 预警列表信息
        { prop: "warningTypeName", slot: true, label: "预警类别" },
        { prop: "warningPhotoUrl", slot: true, label: "图片" },
        { prop: "video", slot: true, label: "视频" },
        { prop: "remark", slot: true, label: "目标事物信息" },
        { prop: "action", slot: true, label: "操作" },
      ]
    },
    cancelEdit() { // 点击取消编辑
      this.edit_form = this.edit_form_copy
      this.donEdit = true
      this.columns = [  // 预警列表信息
        { prop: "warningTypeName", slot: true, label: "预警类别" },
        { prop: "warningPhotoUrl", slot: true, label: "图片" },
        { prop: "video", slot: true, label: "视频" },
        { prop: "remark", slot: true, label: "目标事物信息" },
      ]
    },
    async saveModify() { // 保存修改
      this.$refs['edit_form'].validate( async(valid) => {
        if (valid) {
          this.edit_form.executeTime = this.edit_form.date[0]
          this.edit_form.endTime = this.edit_form.date[1]
          this.edit_form.ais.map(item => {
              Reflect.deleteProperty(item, 'id')
          })
          const res = await API.TASK.updateSumReport(this.edit_form)
          if(res.status == 1) {
            this.$message.success('修改成功')
            this.donEdit = true
            this.columns = [  // 预警列表信息
              { prop: "warningTypeName", slot: true, label: "预警类别" },
              { prop: "warningPhotoUrl", slot: true, label: "图片" },
              { prop: "video", slot: true, label: "视频" },
              { prop: "remark", slot: true, label: "目标事物信息" },
            ]
            this.async_get()
          }else{
            this.$message.warning(res.message)
          }
        } else {
          setTimeout(() => {  // 如果不通过验证，跳到没有通过验证的输入框并获取焦点
            let isError = document.getElementsByClassName("is-error");
            if (isError.length > 0) {
              isError[0].querySelector('input').focus()
            }
          }, 100)
        }
      });
    },
    approval() { // 点击审批按钮
      if(!this.donEdit){
        this.$message.warning('请先保存修改')
      }else {
        this.dialogVisible = true
      }
    },
    // -------------------------------------------------------------编辑报告的函数结束

    // --------------------------------------------------------------选择飞行成果的图片与视频
    editPicture(urls,id) {  // 点击编辑图片
      console.log(id);
      this.aisId = id
      let images = []
      urls.forEach(item => {
        images.push(item.trim())
      });
      this.aisPictrueOrVideoList = images
      this.selectType = 1
    },
    editViedo(data) { // 点击标记视频
      // this.aisPictrueOrVideoList = url
      this.aisPictrueOrVideoList = data.video ? data.video : null
      this.aisId = data.id
      this.selectType = 2
    },
    saveSelectpAv(urls,type) { // 选择了一些图片或视频，加回去
      if(type == 1) {
        let urlsCopy = []
        urls.forEach((item,index)=>{
          if(index == 0){
            urlsCopy.push(item)
          } else{
            urlsCopy.push(' '+item)
          }
        })
        urls = urls.map((item,index)=>{
          if(index == 0) {
            let index = item.indexOf("9000")
			      let right = item.slice(index+4)
            return right
          } else {
            let index = item.indexOf("9000")
			      let right = item.slice(index+4)
            return ' '+right
          }
        })
        this.edit_form.ais.forEach(item=>{
          if(item.id == this.aisId){
            if(urls.length>0){
              item.warningPhotoUrl = JSON.stringify(urls).replaceAll("\"","")
              item.warningPhotoStorage = JSON.stringify(urlsCopy).replaceAll("\"","")
            } else{
              item.warningPhotoUrl = ''
              item.warningPhotoStorage = ''
            }
          }
        })
      } else {
        this.edit_form.ais.forEach((item,index)=>{
          
          if(item.id == this.aisId){
            if(urls[0].indexOf('/api') > -1) {
              this.$set(this.edit_form.ais, index, { ...item, video:urls[0], videoTwo: urls[1]  } )
            } else {
              this.$set(this.edit_form.ais, index, { ...item, video:urls[0], videoTwo: null  } )
            }
          }
        })
      }
    },

    async getAlertTypeDropDown() {  // 获取预警类型下拉
      const res = await API.TASK.getAlertTypeDropDown( {pageNum:1,pageSize:1000} )
       
      this.alertTypeDropDown = res.data
    },

    showPicture(urls) { // 点击展示图片
        let images = []
        urls.forEach(item => {
          images.push(item.trim())
        });
        console.log(images);
        this.$viewerApi({
          images: images,
        })
    },
    showVideo(data) { // 点击展示视频
      if(data.video.indexOf('/api') > -1) {
        this.videoslist.url = data.videoTwo
      } else {
        this.videoslist.url = data.video
      }
    },
    async async_get() { // 获取子任务报告详情
      this.loading = false
      const id = this.generalTaskReportId;
      const res = await API.TASK.getGeneralTask(id);
      if(res.status === 1) {
        this.edit_form = res.data
        this.$set(this.edit_form,'date',[res.data.executeTime,res.data.endTime])
      } else {
         this.$message.error(res.message)
      }
      
    },
    async handle_approve(num) { // 审批接口请求
      let res;
      if (num === 1) {
        res = await API.TASK.SumReportApply({ ids: [this.edit_form.id], status: num });
      } else {
        res = await API.TASK.SumReportApply({ ids: [this.edit_form.id], status: num });
      }
      if (res.status === 1) {
        this.$el_message("审批成功");
        this.dialogVisible = false;
        this.async_get();
      }
    },
    // table删除一行
    deleteRow(value) {
      this.edit_form.ais = this.edit_form.ais.filter(item => item.id != value.id)
    },
    // tabie新增一行
    addRow() {
      this.edit_form.ais.push({
        id: 'mmc' + nanoid(),
        taskId: this.generalTaskReportId,
        warningPhotoStorage: "",
        warningPhotoUrl: "",
        warningTypeId: this.alertTypeDropDown[0].id,
        warningTypeName: this.alertTypeDropDown[0].name
      })

    }
  },
  watch: {
    'edit_form.ais':{
      handler(newVal){
        this.edit_form.warningNum = newVal.length
      }
    }
  },
  mounted() {
    this.getAlertTypeDropDown()
    this.async_get();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .edit-form {
    .warningphotourl-img{
      height: 70px;
      img{
        height: 100%;
        cursor: pointer;
      }
    }
    .add-picture{
      width: 32px;
      display: inline-block;
      height: 32px;
      background: url(./img/add.png) no-repeat;
      cursor: pointer;
    }
    .video{
      width: 100%;
      height: 480px;
      position: relative;
      .close-video{
        position: absolute;
        right: -24px;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }
    }
    .cpt-service-table .table-bd .table-tr{
      height: 80px;
    }
    .aa {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 6px);
      }
    }

    .grap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 60px);
      }
    }

    .linear-fields {
      width: 100%;
      margin-bottom: 20px;
      .el-form-item {
        width: 100%;
      }
    }
  }
}
.popup{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(8, 37, 79, .6);
  top: 0;
  left: 0;
  .seeMore {
  background: rgba(8, 37, 79, 1);
  width: 1200px;
  height: 800px;
  border: 2px solid #0067ab;
  position: absolute;
  top: 124px;
  left: 50%;
  margin-left: -600px;
  padding:20px 10px 0 0;
  box-sizing: border-box;
  z-index: 100;
  .back-button {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: url(~@/assets/platform/message/task/details/closure.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 20px;
    right: 20px;
  }
  .control-bar{
    position: absolute;
    padding: 10px;
    top: 30%;
    right: -64px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
    .span{
      font-size: 30px;
      margin-top: 6px;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      // border: 1px solid #0067ab;
      // color: #0067ab;
      border: 1px solid #9fc4ff;
      color: #9fc4ff;
      background-color: rgba(8, 37, 79, 1);
      cursor: pointer;
    }
  }
  &-center {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    overflow: auto;
    .report {
      width: 100%;
      height: 100%;
      .header {
        padding-top: 4px;
        height: 36px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
          width: 528px;
          height: 28px;
          background: url(~@/assets/platform/device/header.png) no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
      .early-warning{
        position: relative;
        width: 100%;
        ::v-deep{
          .table-bd{
            overflow: hidden;
          }
        }
        .add-warning{
          position: absolute;
          top: -54px;
          right: 0;
          color: #009aff;
          font-size: 18px;
          border: #009aff solid 1px;
          cursor: pointer;
          padding: 4px 6px;
        }
      }
      .body {
        padding: 24px 73px 0;
        height: 100%;
        .report-name{
          width: 100%;
          height: 50px;
          text-align: right;
          color: #9fc4ff;
          line-height: 50px;
        }
        .color {
          color: #b6d6ff;
          font-size: 18px;
        }
        .text {
          color: #b6d6ff;
          font-size: 16px;
          line-height: 30px;
        }
        .image {
          width: 80px;
          height: 70px;
        }
        .textarea-content{
          width: 100%;
          color: #9fc4ff;
        }
      }
    }
    .Side {
      padding-top: 310px;
      padding-left: 14px;
      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        width: 52px;
        height: 52px;
        border: 1px solid #009aff;
        .iconfont {
          cursor: pointer;
          font-size: 24px;
          color: #9fc4ff;
        }
      }
    }

  }
}
}

</style>
