<template>
  <div class="cpt-platform-task-handle-pilot">
    <Table
      :columns="columns"
      :dataSource="dataSource"
      style="max-height: 400px"
    >
      <template #free="{ row }">
        <div class="action">
          <span title="是否正在作业" class="iconfont">{{
            row.free === 1 ? "空闲" : "非空闲"
          }}</span>
        </div>
      </template>
      <template #status="{ row }">
        <div class="action">
          <span title="分配情况" class="iconfont">{{
            statusList[row.status]
          }}</span>
        </div>
      </template>
      <template #action="{ row }">
        <el-checkbox
          v-model="dataSource[row.index].checked"
          @change="rows(row.id)"
        ></el-checkbox>
      </template>
    </Table>
    <div class="save">
      <el-button type="primary" v-if="!subTaskReadOnly" @click="change()">保存</el-button>
    </div>
  </div>
</template>

<script>
import Table from "@/components/common/table";
import API from "@/api";
import dayjs from "dayjs";

export default {
  props: {
    id: { // 总任务id
      type: Number,
      default: null
    },
    flightUser: {
      type: Array,
      default: [],
    },
    subTaskReadOnly: { // 判断是否只读
      type: Boolean,
      default: false
    }
  },
  components: { Table },
  data() {
    return {
      selsectId: [],
      radioId: 0,
      columns: [
        { prop: "name", label: "运维人员" },
        { prop: "phone", label: "联系手机号" },
        { prop: "free", slot: true, label: "是否正在作业" },
        { prop: "status", slot: true, label: "分配情况" },
        { prop: "organizationName", label: "所属单位" },
        {
          prop: "action",
          slot: true,
          label: "操作",
          width: "200px",
        },
      ],
      dataSource: [],
      statusList: {
        0: "未分配",
        1: "已分配",
        2: "其他任务分配",
      },
    };
  },
  methods: {
    rows(id) {
      // 存储选择的运维
      if (this.selsectId.indexOf(id) > -1) {
        this.selsectId = [];
      } else {
        this.selsectId = [id];
      }
    },
    async operatorList() {
      // 获取列表
      const id = this.id;
      const { data } = await API.TASK.flyingUser({ taskId: id });
      data.map((item, index) => {
        item.checked = false;
        item.index = index;
        if (this.selsectId.length > 0 && this.selsectId[0] == item.id) {
          item.checked = true;
        }
      });
      this.dataSource = data;
    },
    async change() {
      // 保存处理需求
      if (this.selsectId.length <= 0) {
        this.$message.warning("未分配运维人员");
        return;
      }else{
        this.$emit('on-submitForm', this.selsectId)
      }
    },
  },
  watch: {
    $route() {
      this.operatorList();
    },
    "flightUser"(val) {
      if (val.length > 0) {
        this.selsectId = val.map((item) => {
          return item.id;
        });
      }
    },
    selsectId(val) {
      if (val.length > 0) {
        this.dataSource.map((item) => {
          if (item.id == val[0]) {
            item.checked = true;
          } else {
            item.checked = false;
          }
        });
      }
    },
  },
  mounted() {
    this.operatorList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: 600;
    color: #9fc4ff;
  }
  .el-collapse-item__content {
    color: #9fc4ff;
  }
  .el-collapse-item__content {
    padding-bottom: 16px;
  }
  .el-collapse-item__header {
    background: rgba(82, 39, 39, 0);
  }
  .el-collapse-item__wrap {
    background: rgba(82, 39, 39, 0);
    border: 0px;
  }
  .el-collapse-item__header {
    border: 0px;
  }
  .el-collapse {
    border: 0px;
  }
}
.cpt-platform-task-handle-pilot {
  margin-top: 16px;
  .action {
    .iconfont {
      font-size: 12px;
    }
  }
  
  .save {
    float: right;
    margin-top: 6px;
  }
}
.icon-chulixuqiu1 {
  cursor: pointer;
}
</style>
