<template>
  <div class="page">
    <!-- 列表 -->
    <div class="container" v-show="!addSubTask && !logPlaybackPopupIsShow && !subTaskReportIsShow">
      <div class="iconfont icon-guanbi close" @click="close"></div>
      <div class="add-button linear-btn" @click="async_get_list('unexecuted')">未执行子任务</div>
      <div class="area-button linear-btn" @click="openAreaPopup()">区域</div>
      <!-- 搜索 -->
      <div class="search">
        <div v-view="{ sign: 'hn:taskOrder:create' }" v-if="totalTaskInformation.taskStatus !== 1" @click="addSubTaskButton" class="linear-btn">新建子任务</div>
        <div>
          <el-form
            :model="search_form"
            style="margin-left: 24px"
            class="linear-form"
            inline
          >
            <el-form-item label="子任务名称:">
              <el-input
                v-model="search_form.name"
                size="mini"
                placeholder="请输入子任务名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="任务状态:">
              <el-select v-model="search_form.taskStatus" size="mini" clearable  placeholder="请选择任务状态" style="padding-right: 0px;">
                <el-option
                  v-for="item in taskStatus"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="时间选择:">
              <el-date-picker
                v-model="search_form.date"
                type="daterange"
                range-separator="至"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="linear-btn" @click="async_get_list" style="margin-left:20px">搜索</div>
      </div>
      <!-- 表格 -->
      <Table
        class="task-table"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        @size-change="
          (size) => {
            pagination.pageSize = size;
            async_get_list();
          }
        "
        @page-change="
          (page) => {
            pagination.page = page;
            async_get_list();
          }
        "
      >
        <template #taskStatus="{ row }">
          <div class="action">
            <span :style="getColor(row.taskStatus)">{{getWords(row.taskStatus)}}</span>
          </div>
        </template>
        <template #origin="{ row }">
          <div class="action">
            <span>{{`${row.destination}-${row.origin}`}}</span>
          </div>
        </template>
        <template #action="{ row }">
          <div class="action">
            <!-- reportStatus  0: 未审批  1： 审批通过  2：审批不通过 -->
            <span title="修改" v-view="{ sign: 'hn:taskOrder:update' }" v-if="row.taskStatus === 0 || row.taskStatus === -2" class="iconfont icon-chulixuqiu1" @click="updateSubTask(row.id)"></span>
            <span title="飞行日志" v-if="(user_info_hn.departmentId === 60 && row.taskStatus === 1) || row.reportStatus === 1" class="iconfont icon-chulixuqiu" @click="openLogPlayback(row)"></span>
            <span title="任务报告" v-if="(user_info_hn.departmentId === 60 && row.taskStatus === 1) || row.reportStatus === 1" class="iconfont icon-renwubaogao" @click="seeSubTaskReport(row.id)"></span>
            <span title="飞行成果" v-if="(user_info_hn.departmentId === 60) || row.reportStatus === 1" class="iconfont icon-ziliaoku" @click="ViewAchievementLibrary(row.id)"></span>
            <el-popover
              placement="right"
              width="126"
              visible-arrow="false"
              trigger="hover"
              popper-class="more-popover"
            >
              <div class="more-popover-box">
                <div
                  class="pointer"
                  @click="seeAirline(row.id)"
                >
                  预览航线
                </div>
                <div
                  class="pointer"
                  @click="subTaskDetails(row.id)"
                >
                  详情
                </div>
                <div
                  v-if="(row.taskStatus === 0 || row.taskStatus === 3) && user_info_hn.departmentId === 60"
                  class="pointer"
                  @click="cancelSubTask(row.id)"
                >
                  取消
                </div>
                <div
                  class="pointer"
                  @click="del(row.id)"
                  v-if="row.taskStatus === -1"
                  v-view="{ sign: 'hn:taskOrder:delete' }"
                >
                  删除
                </div>
              </div>
              <span slot="reference" class="iconfont icon-chakangengduo"></span>
            </el-popover>
          </div>
        </template>
      </Table>
    </div>
    <!-- 子任务报告弹框 -->
    <SubTaskReport v-if="subTaskReportIsShow" :subTaskReportId="subTaskReportId" @on-closeSubTaskReport="subTaskReportIsShow = false"></SubTaskReport>
    <!-- 飞行成果 -->
    <AchievementBase :subTaskAchievementBaseId="subTaskAchievementBaseId" v-if="subTaskAchievementIsShow" @on-subTaskAchievementClose="subTaskAchievementIsShow = false"></AchievementBase>
    <!-- 添加/修改 子任务弹框 -->
    <AddSubTask  v-if="addSubTask" :totalTaskInformation="totalTaskInformation" :subTaskReadOnly="subTaskReadOnly" @on-openAreaPopup="openAreaPopup" @on-async_get_list="async_get_list" :subTaskId="subTaskId" @on-close="addSubTask = false"></addSubTask>
    <!-- 日志回放弹框 -->
    <LogPlayback v-if="logPlaybackPopupIsShow" :historicalTrack="historicalTrack" @on-closeLogPlayback="logPlaybackPopupIsShow = false" :value="logPlaybackData"></LogPlayback>
    <!-- 航线预览，历史轨迹回放 -->
    <div class="out-content">
      <el-dialog
        :title="taskshow.list ? '预览航线' : '历史轨迹'"
        :close-on-click-modal="false"
        :append-to-body="true"
        :visible.sync="taskshow.show"
        v-if="taskshow.show"
        :before-close="clearTask"
        class="taskDialong"
        top="8vh"
      >
        <Draw
          v-if="taskshow"
          :taskshow="taskshow.list"
          :historicalTrack="historicalTrackList"
          :readonly="taskshow.show"
          class="taskDraw"
        />
      </el-dialog>
    </div>
    <!-- 区域展示弹框 -->
    <div v-if="areaPopupIsShow" class="area-select-popup">
      <div class="content">
        <div class="button">
          <el-button @click="areaPopupIsShow = false" type="primary">关闭</el-button>
        </div>
        <Drawky
          :airspcelist="areaPointListObj"
          :readonly="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Box from "@/components/platform/common/box";
import Table from "@/components/common/table";
import AddSubTask from "./addSubTask.vue"; // 新增子任务
import LogPlayback from "./logPlayback.vue"; // 日志回放
import Drawky from "@/components/drawky"; // 绘制区域的
import AchievementBase from "./achievement/index.vue"; // 飞行成果
import SubTaskReport from "./subTaskReport/index.vue"; // 子任务报告
import Draw from "@/components/draw";
import API from "@/api";
export default {
  props:{
      popupStates: {
          type: Function ,
          default: ()=>{},
      },
      totalTaskInformation: { // 总任务信息
        type: Object,
        default: {}
      }
  },
  components: {
    Box,
    Table,
    AddSubTask,
    LogPlayback,
    Draw,
    Drawky,
    AchievementBase,
    SubTaskReport
  },
  data() {
    return {
      taskshow: {
        show: false, // 预览航线是否展示
        list: null, // 预览航线存储数据
      },
      subTaskReportIsShow: false, // 控制子任务任务报告弹框是否显示
      subTaskReportId: null, // 子任务报告id
      historicalTrackList: [], // 历史轨迹存储
      areaPopupIsShow: false, // 区域弹框显示与隐藏
      areaPointListObj: null, // 存储绘制区域的信息
      logPlaybackPopupIsShow: false, // 日志回放弹框控制状态
      logPlaybackData: null, // 日志回放数据
      addSubTask: false, // 新增子任务弹框展示隐藏
      subTaskReadOnly: false, // 子任务是否只读
      subTaskId: null, // 如果要修改子任务，吧要修改的子任务id存起来传过去
      subTaskAchievementBaseId: null, // 查看子任务飞行成果时传过去的id
      subTaskAchievementIsShow: false, // 控制子任务飞行成果弹框是否显示
      taskStatus: [ //搜索任务状态下拉
        { id: 1, title: "已完成" },
        { id: 3, title: "执行中" },
        { id: 0, title: "待执行" },
        { id: -2, title: "已拒绝" },
        { id: -1, title: "已取消" },
      ],
      search_form: { // 搜索字段
        name: null,
        taskStatus: null,
        date: null,
      },
      columns: [
        { prop: "taskTitle", label: "子任务名称" },
        { prop: "executeTime", label: "执行日期" },
        { prop: "operators", label: "飞手" },
        { prop: "taskStatus", slot: true, label: "任务状态" },
        { prop: "origin", slot: true, label: "作业位置" },
        { prop: "action", slot: true, label: "操作" },
      ],
      dataSource: [], //请求回来的列表数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    }
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  methods: {
    seeSubTaskReport(id) { // 查看子任务报告
      this.subTaskReportId  = id
      this.subTaskReportIsShow = true
    },
    ViewAchievementLibrary(id) {  // 查看飞行成果
      this.subTaskAchievementBaseId = id
      this.subTaskAchievementIsShow = true
    },
    async cancelSubTask(id) { // 子任务取消
      let res = await API.TASK.cancelSubtask(id);
       
      if(res.code === 200) {
        this.$message.success('取消成功')
        this.async_get_list()
      } else {
        this.$message.error(res.message);
      }
    },
    // ---------------------------------------------------------------航线预览
    clearTask(done) { // 关闭航线轨迹展示
      this.taskshow.list = null;
      this.taskshow.show = false;
      done && done();
    },
    async seeAirline(id) {
      // 预览航线
      let res = await API.TASK.viewFlight(id);
      if (res.status === 1) {
        this.historicalTrackList = [];
        this.taskshow.list = res.data;
        this.taskshow.show = true;
      } else {
        this.$message.error(res.message);
      }
    },
    async historicalTrack(deviceHardId, taskId) { 
      // 历史轨迹 deviceHardId: 无人机id   taskId: 子任务id
      let res = await API.TASK.historicalTrack({ deviceHardId, taskId });
      if (res.code == 200 && res.data.track.length > 0) {
        let fdata = [];
        if (res.data.track.length > 500) {
          let pointList = Math.floor(res.data.track.length / 500);
          for (let j = 0; j < res.data.track.length; j++) {
            if (j % pointList === 0) {
              fdata.push(res.data.track[j].longitude * 1);
              fdata.push(res.data.track[j].latitude * 1);
              fdata.push(res.data.track[j].height * 1);
            }
          }
        } else {
          for (let j = 0; j < res.data.track.length; j++) {
            fdata.push(res.data.track[j].longitude * 1);
            fdata.push(res.data.track[j].latitude * 1);
            fdata.push(res.data.track[j].height * 1);
          }
        }
        this.historicalTrackList = fdata;
        this.taskshow.list = null;
        this.taskshow.show = true;
      } else {
        this.$message.error("未查询到历史轨迹");
      }
    },
    // -----------------------------------------------------------------区域显示
    openAreaPopup() {  // 查看区域弹框
        if(this.totalTaskInformation.locationArea){
          this.areaPointListObj = {regionList:null}
          let araeArr = []
          araeArr = this.totalTaskInformation.locationArea.split(' ')
          let lastAraeArr = []
          araeArr.forEach(item=>{
            let areaPoint = item.split(',')
            lastAraeArr.push(areaPoint[0]*1)
            lastAraeArr.push(areaPoint[1]*1)
          })
          this.areaPointListObj.regionList = lastAraeArr
          this.areaPopupIsShow = true
        } else {
          this.$message.warning('未绘制区域')
        }
    },
    openLogPlayback(row) { // 点击日志回放按钮
      this.logPlaybackData = row
      this.logPlaybackPopupIsShow = true
    },
    updateSubTask(id) { // 修改子任务
      this.subTaskId = id
      this.subTaskReadOnly = false
      this.addSubTask = true
    },
    subTaskDetails(id) { // 子任务详情
      this.subTaskId = id
      this.subTaskReadOnly = true
      this.addSubTask = true
    },
    addSubTaskButton() { // 新建子任务
      this.subTaskId = null
      this.subTaskReadOnly = false
      this.addSubTask = true
    },
    getColor(status) { // 任务状态颜色
      switch (status) {
        case 0:
          return "color: #16D979";
          break;
        case -1:
          return "color: #666";
          break;
        case 3:
          return "color: #FB8016";
          break;
        case 1:
          return "color: #009AFF";
          break;
        case -2:
          return "color: #F55252"
          break;
        default:
          break;
      }
    },
    getWords(status) { // 任务状态文字
      switch (status) {
        case 0:
          return "待执行";
          break;
        case -1:
          return "已取消";
          break;
        case 3:
          return "执行中";
          break;
        case 1:
          return "已完成";
          break;
        case -2:
          return "已拒绝"
          break;
        default:
          break;
      }
    },
    close() { // 关闭弹框
      this.popupStates()
    },
    async async_get_list(unexecuted) { // 搜索列表
      if(unexecuted === 'unexecuted'){  // 如果是直接查询未执行任务
        this.search_form.taskStatus = 0
        this.pagination.page = 1
        this.search_form.date = null
        this.search_form.name = ''
      }
      console.log(this.search_form);
      let obj = {
        sumTaskId: this.totalTaskInformation.id,
        pageNo: this.pagination.page,
        pageSize: this.pagination.pageSize,
        taskStatus: this.search_form.taskStatus,
        startTime: this.search_form.date?this.search_form.date[0]:'',
        endTime: this.search_form.date?this.search_form.date[1]:'',
        name: this.search_form.name
      }
      console.log(obj);
      const res = await API.TASK.taskOrderList(obj);
       
      if(res.code === 200){
        this.dataSource = res.data.data
        this.pagination.total = res.data.totalCount
      } else {
        this.$message.error('请求子任务列表失败');
      }
      console.log('111', this.dataSource);
    },
    async del(id) { // 删除某个子任务
        const res = await API.TASK.delSubtask(id)
         
        if(res.status === 1) {
          this.$message.success('删除成功')
          if(this.dataSource.length === 1) {
            if(this.pagination.page > 1) {
              this.pagination.page --
            }
          }
          this.async_get_list()
        } else{
          this.$message.error('删除失败')
        }
    }
  },
  mounted() {
    this.async_get_list()
  }
}
</script>

<style lang="scss" scoped>
/* form表单 */
::v-deep {
  
    .area-select-popup{
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(12, 34, 67, 0.5);
      z-index: 7000;
      .content{
        position: absolute;
        width: 1600px;
        height: 800px;
        left: 50%;
        top: 100px;
        margin-left: -800px;
        border: 1px solid #009aff;
        .button{
          position: absolute;
          top: 20px;
          right: 20px;
          width: 80px;
          z-index: 1000;
          .el-button--primary{
            background-color: #69b3e5;
          }
        }
      }
    }
  .edit-form {
    .aa {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 20px);
      }
    }

    .grap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 60px);
      }
    }

    .linear-fields {
      width: 100%;
      margin-bottom: 20px;
      .el-form-item {
        width: 100%;
      }
    }
  }
}
.page{
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(8, 37, 79, 0.6);
  .container{
    z-index: 10;
    box-sizing: border-box;
    position: absolute;
    background: rgba(8, 37, 79, 1);
    width: 1600px;
    top: 124px;
    left: 50%;
    margin-left: -800px;
    border-radius: 2px;
    border: 1px solid #2b9fed;
    padding: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .close{
        position: absolute;
        top: 18px;
        right: 16px;
        color: #44b1fa;
        font-size: 24px;
        cursor: pointer;
    }
    .add-button{
      position: absolute;
      top: 20px;
      right: 150px;
      width: 140px;
    }
    .area-button{
      position: absolute;
      top: 20px;
      width: 60px;
      right: 80px;
    }
    .search {
      width: 100%;
      display: flex;
      justify-content: left;
    }
    .task-table {
      width: 100%;
      height: 100%;
      flex: 1;
      margin-top: 27px;
      .action {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        .iconfont {
          cursor: pointer;
          font-size: 26px;
          color: #9fc4ff;
          margin-right: 4px;
          line-height: 26px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
  
}

  
.search ::v-deep {
  .el-input{
    width: 174px;
    .el-input__inner{
      width: 100% !important;
    }
  }
  .el-date-editor{
    .el-range__close-icon{
      margin-left: -20px;
    }
  }
}
</style>