<template>
  <div style="height:100%">
    <div class="page-service-achiev">
      <Search @on-search="handle_search" @upload="() => review.upload = true"/>
      <div class="achi-list-wrap">
        <div class="achi-list">
          <div class="achi-item" v-for="item in dataSource" :key="item.id">
            <el-popover
              popper-class="achi-action-popover"
              placement="right"
              width="400"
              trigger="click"
              style="zIndex:0"
              v-model="item.boolean"
            >
              <div class="action-list">
                <div @click="handle_review(item)" v-if="item.typeId != 4" class="action-item">查看</div>
                <div @click="handle_download(item)" class="action-item">
                  下载
                </div>
                <div @click="handle_before_rename(item)" class="action-item">
                  重命名
                </div>
              </div>
              <div class="achi-item" slot="reference">
                <div class="achi-icon-box">
                   <!-- // 图片 1 视频 2 文档 3 -->
                  <img v-if="item.typeId === 1"
                    class="achi-icon"
                    :src="item.storage"
                    alt="成果图片"
                  />
                  <!-- <video 
                    class="achi-icon"
                    v-if="item.typeId === 2"
                    :src=" VUE_APP_IMGIP === 'http://39.99.144.107' ? VUE_APP_IMGIP :  item.url"
                  >
                  </video> -->
                  <img v-if="item.typeId === 4 || item.typeId === 2"
                    class="achi-icon"
                    :src="require(`@/assets/platform/achi-0${item.typeId}.png`)"
                    alt="achi-type"
                  />
                </div>
                <div :title="item.name" class="achi-text-box">
                  <input
                    :ref="`achi-${item.id}`"
                    type="text"
                    v-model="item.name"
                    @keydown.enter="handle_rename(item)"
                  />
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="table-pagination">
        <Pagination
          @size-change="handle_size_change"
          @page-change="handle_page_change"
          :current-page="pagination.page || 1"
          :page-sizes="pagination.pageSizes || [10, 20, 50, 100]"
          :page-size="pagination.pageSize || 10"
          :total="pagination.total || 0"
          :size="size"
        />
      </div>
    </div>

    <el-dialog
      title="查看视频"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-if="datalist.url"
      :visible.sync="review.video.visible"
      width="500px"
      :before-close="onOpenDialog"
      class="videoShow"
    >
      <!-- <video
        ref="veo"
        style="width: 100%; height: 230px"
        autoplay="autoplay"
        controls
        v-if="review.video.url"
        :src="review.video.url"
      ></video> -->
      <VideoPlay  v-if="datalist.url" :datalist="datalist" />
    </el-dialog>

    <el-dialog
      v-loading="loading"
      element-loading-text="文件上传中，请耐心等待"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.4)"
      title="上传附件"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-if="review.upload"
      :visible.sync="review.upload"
      width="500px"
      :before-close="onUpload"
      class="videoShow upload-attachments"
    >
      <el-upload
        action=""
        accept=".jpg,.png,.mp4"
        :auto-upload='false'
        class="upload-demo"
        :on-change="fileChange"
        :on-remove="fileRemove"
        :limit="10"
        :multiple="true"
        :file-list="fileList"
        >
        <div slot="tip" class="el-upload__tip" style="margin-bottom: 10px;color:#fff">只能上传.png.jpg.mp4文件</div>
        <el-button type="primary" >点击上传</el-button>
      </el-upload>
      <div class="sub">
          <el-button  type="primary" @click="sub">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

import Box from "@/components/platform/common/box";
import Pagination from "@/components/common/pagination";
import VideoPlay from "@/components/device/player/vidoeoJs"
import Search from "./search";

import API from "@/api";
export default {
  components: { Box, Pagination, Search, VideoPlay },
  props: {
    subTaskAchievementBaseId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: false, //上传文件时加遮罩层
      search: {},
      dataSource: [],
      size:null,
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },
      review: {
        image: {
          list: [],
          options: {
            url: "data-source",
          },
        },
        video: {
          visible: false,
          url: null,
        },
        upload: false
      },
      id: null,
      VUE_APP_IMGIP: null,
      datalist: {
        url: null,
        videoSign: null,
        videoStartEnd: null
      }, //存储视频数据
      fileList: []
    };
  },
  methods: {
    async async_get_list() {
      const { page, pageSize } = this.pagination;
      const { data } = await API.TASK.GetSubTaskAchievementList({
        ...this.search,
        id: this.subTaskAchievementBaseId,
        pageNum: page,
        pageSize: pageSize,
      });
      data.data.forEach(v=>v.boolean=false)
      this.dataSource = data.data || [];
      this.pagination.page = data.pageNo;
      this.pagination.total = data.totalCount;
      
      if(this.dataSource && this.dataSource.length == 0){
        this.$el_message("暂无成果信息")
      }
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.async_get_list();
    },
    handle_page_change(page) {
      this.pagination.page = page;
      this.async_get_list();
    },

    handle_review(data) {
      //点击按钮关闭弹框
      data.boolean=false
      if (data.typeId == 1) {
        this.review.image.list.unshift(
          // require(`@/assets/platform/achi-0${data.typeId}.png`)
          data.storage
        );
        this.$viewerApi({
          images: this.review.image.list,
        });
      } else if (data.typeId == 2) {
        this.review.video.visible = true;
        this.datalist.url = data.storage.indexOf('/api') > -1  ? data.url : data.storage
        this.datalist.videoSign = data.videoSign
        this.datalist.videoStartEnd = data.videoStartEnd
      } else if (data.typeId == 4) {
        // window.open(data.storage, "_blank");
        window.open('http://vw.usdoc.cn/?w=0&src='+'https://zh.usdoc.cn/view/个人简历.docx', "_blank"); // 网上查的，这样写能预览文件 w=数字 0 1 2 3 4 5 可以出现不同的预览方式
      }
    },

    async handle_download(data) {
      //点击按钮关闭弹框
      data.boolean=false
      let res;
      if(data.typeId === 1 || data.typeId === 2) {
        res = await API.TASK.updownload({
          key: data.key,
        });
      } else {
        res = await API.TASK.subtaskReportExport(data.key);
      }
      const a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.download = data.typeId === 1?`${data.name}.png`:data.typeId === 2?`${data.name}.mp4`:`${data.name}.doc`;
      a.click();
      a.remove();
    },

    handle_before_rename(data) {
      //点击按钮关闭弹框
      data.boolean=false
      this.$refs[`achi-${data.id}`][0].focus();
    },
    async handle_rename(data) {
      let res = await API.TASK.taskDataUpdate({
        id: data.id,
        name: data.name
      })
      if (res.status === 1) {
        this.$el_message('修改成功');
        this.async_get_list();
      }
    },
    // 查看视频框
    onOpenDialog(done) {
      this.datalist.url = null
      this.datalist.videoSign = null
      this.datalist.videoStartEnd = null
      done && done()
   	},
    // 上传附件弹框
    onUpload(done) {
      this.fileList = []
      done && done()
    },
    fileChange(file, fileList) {
      let type = ["image/png","video/mp4","image/jpeg"]
      if (fileList&&fileList.length>10) {
        fileList.shift();
      }
      this.fileList = fileList
      
    },
    fileRemove(file, fileList){
      this.fileList = fileList
    },
    beforeAvatarUpload(file) {
      let type = ["image/png","video/mp4","image/jpeg"]
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
    },
    async sub() { // 提交文件
      if(this.fileList.length>0) {
        this.loading = true
        let res = null
        let formData = new FormData()
        this.fileList.map(item => formData.append("uploadFiles", item.raw))
        try {
          res = await API.TASK.uploads(formData);
          if (res.status === 1) {
            let imgType = ['png', 'jpg']
            res.data.map(item => {
              item.taskId = this.subTaskAchievementBaseId
              item.typeId = imgType.includes(item.name.substring(item.name.lastIndexOf(".") + 1)) ? 1 : 2
              item.affiliationTask = 0 // 0代表上传的子任务附件  1代表上传的是总任务附件
            })
            let addfile = await API.TASK.addData(res.data)
            if (addfile.status === 1) {
              this.$message.success('数据添加成功!')
              this.loading = false
              this.review.upload = false
              this.fileList = []
              this.async_get_list();
            } else {
              this.$message.error(addfile.message)
              this.loading = false
            }
          } else {
            this.$message.error(res.message);
            this.loading = false
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.warning('请先选择文件!');
      }
    }
  },
  mounted() {
    const { VUE_APP_IMGIP } = process.env;
    this.VUE_APP_IMGIP = VUE_APP_IMGIP
    this.async_get_list();
  },
};
</script>

<style lang="scss">
.achi-action-popover {
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;
  .popper__arrow {
    display: none;
  }
  .action-list {
    .action-item {
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;
      &:hover {
        color: #2cacff;
      }
    }
  }
  width: 98px !important;
}
.videoShow .el-dialog__body {
  width: 460px;
  height: 400px;
}
</style>

<style lang="scss" scoped>
/* 上传附件样式 */
.upload-attachments{
  ::v-deep{
    .el-dialog__body {
      width: 460px;
      height: auto !important;
    }
  }
}
::v-deep{
  .el-upload__tip{
    color: #fff;
    margin-top: 10px;
  }
  .sub{
    text-align: right;
    margin-top: 20px;
  }
  .el-upload-list__item:hover {
    background-color: #59749c;
  }
  .el-upload-list__item{
    color: #fff;
  }
  .el-upload-list__item-name{
    color: #fff;
  }
  .el-upload-list__item-name [class^=el-icon]{
    color: #fff;
  }
  .el-upload-list__item .el-icon-close{
    color: #fff;
  }
}
::v-deep {

.page-service-achiev {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 98%;
  box-sizing: border-box;
  .achi-list-wrap {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .achi-list {
      display: flex;
      flex-wrap: wrap;
      margin: 39px -73px -32px 0px;
      .achi-item {
        width: 156px;
        height: 150px;
        margin: 0 73px 32px 0;
        flex-direction: column;
        justify-content: space-between;
        .achi-icon-box {
          width: 156px;
          height: 115px;
          position: relative;
          .achi-icon {
            width: 100%;
            height: 100%;
          }
        }
        .achi-text-box {
          input {
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #a8bdd4;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            padding: 0;
            border: none;
            outline: none;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .table-pagination {
    flex-shrink: 0;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

}
.videoShow {
      .el-button--primary {
        background-color: #409EFF !important;
        border-color: #409EFF !important;
      }
  }
}
</style>