export const props = {
  onEdit: {
    type: Function,
    default: () => () => {},
  },
  edit:{
    type: Function,
    default: () => () => {},
  }
};
