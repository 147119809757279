<template>
  <div class="page-message-task">
    <div class="box" v-for="item in list" :key="item.id">
      <div class="new" :class="item.taskCateName ==='常态任务' ? 'news' : 'new'"><div class="icon">审批消息</div></div>
      <div class="about"  @click="$router.push(`/platform/task/details/${item.id}`)" >查看详情</div>
      <div class="flex">
        <div class="body" >
          <div class="xuncha">
            任务名称：{{item.taskTitle}}
          </div>
          <div :class="item.taskCateName ==='常态任务' ? 'renwu' : 'renwu3'">
            任务类型：{{ item.taskCateName }}
          </div>
          <div class="xuncha">
            任务场景：{{ item.taskTypeName }}
          </div>
          <div class="xuncha">
            备注信息：{{ item.remark }}
          </div>
          <div class="xuncha">
            当前审批机构：
            {{ 
              ([0,3].includes(item.papplyStatus) || item.taskPApplyList.length === 0)  ?  '暂无' : item.taskPApplyList[item.taskPApplyList.length-1].name
              /*(item.papplyStatus == 2 || item.papplyStatus == 1) ?
             item.taskPApplyList[item.taskPApplyList.length-1].name : 
             item.taskPApplyList[item.taskPApplyList.findIndex(items=> items.papplyStatus == 0)].name*/
             }} 
          </div>
          <div class="xuncha" >
            审批状态： <span :class="color[item.papplyStatus]">{{ item.status}}</span> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import API from "@/api";

export default {
  data() {
    return {
      id:1,
      list: [],
      apply: {
        0: "待审批",
        1: "审批通过",
        2: "审批不通过",
        3: "默认通过",
      },
      color:{
        0: 'renwu',
        1: 'renwu2',
        2: 'renwu3',
        3: 'renwu4'
      }
    }
  },
  mounted(){
    this.fetchData();
  },
  methods:{
    fetchData(){
      API.TASK.postapprovalmessage({
      }).then((res)=>{
        this.list = res.data.data
        console.log('this.list', this.list);
        this.list.map(item => {
          item.status = this.apply[item.papplyStatus]
        })
      })
      
    }
  }
};
</script>

<style lang="scss" scoped>
.page-message-task {
  display: flex;
  flex-flow: wrap;
  box-sizing: border-box;
  // padding: 32px;
  padding: 23px;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  .box {
    max-width: 456px;
    // padding-right: 17px;
    padding-right: 10px;
    width: 456px;
    height: 250px;
    position: relative;
    background: url(~@/assets/platform/message/message.png) no-repeat;
    &:nth-child(4n){
      padding-right: 0;
    }
    .about {
      position: absolute;
      top: 4px;
      right: 40px;
      margin-top: 8px;
      width: 98px;
      height: 32px;
      opacity: 0.8;
      background-image: linear-gradient(
        180deg,
        rgba(44, 123, 255, 0.5) 0%,
        rgba(72, 140, 255, 0) 100%
      );
      border: 1px solid #488cff;
      font-size: 17px;
      color: #9fc4ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      transition: 0.3s;
    }
    .flex {
      display: flex;
      height: 100%;
      .body {
        margin: 11px 0 0 21px;
        width: 860%;
        overflow-y: auto;
      }
    }
    .new {
      margin-top: 18px;
      margin-left: 12px;
      width: 80px;
      height: 26px;
      background: url(~@/assets/platform/message/news.png) no-repeat;
      .icon {
        width: 56px;
        height: 20px;
        margin-left: 27px;
        color: #6593dc;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .news{
      background: url(~@/assets/platform/message/news2.png) no-repeat;
    }
    .xuncha {
      color: #9fc4ff;
      padding-bottom: 10px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
      width: 90%;
    }
    .renwu {
      color: rgb(251,128,22);
      padding-bottom: 14px;
    }
    .renwu2 {
      color: rgb(22,217,121);
      padding-bottom: 14px;
    }
    .renwu3 {
      color: #FF4141;
      padding-bottom: 14px;
    }
    .renwu4 {
      color: yellowgreen;
      padding-bottom: 14px;
    }
    .PTU {
      height: 22px;
    }
  }
}
::-webkit-scrollbar{ width: 8px;height: 8px;background-color: #25498C;}
</style>