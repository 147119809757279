<template>
  <div class="cpt-task-list-search">
    <div style="display: flex; align-items: center">
      <div v-view="{sign:'hn:task-web:create'}" @click="$emit('on-edit')" class="linear-btn">发起需求</div>
      <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
        <el-form-item label="任务名称:">
          <el-input
            v-model="search_form.name"
            size="mini"
            placeholder="请输入任务名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="任务类型:">
          <el-select v-model="search_form.taskCateId" size="mini" clearable  placeholder="请选择任务类型" style="padding-right: 0px;">
            <el-option
              v-for="item in drd.cates"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="场景类型:">
          <el-select v-model="search_form.taskTypeId" size="mini" clearable placeholder="请选择场景类型">
            <el-option
              v-for="item in drd.scenes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态:">
          <el-select v-model="search_form.taskStatus" size="mini" clearable placeholder="请选择任务状态">
            <el-option
              v-for="item in drd.status"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="审批状态:" >
          <el-select v-model="search_form.applyStatus" size="mini" clearable placeholder="请选择审批状态">
            <el-option
              v-for="item in drd.apply"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="需求处理状态:">
          <el-select
            v-model="search_form.processingStatus"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in drd.processing"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="">
          <div @click="$emit('on-search', search_form)" class="linear-btn">
            搜索
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div style="display: flex;">
      <div>
        <div
      class="linear-btn"
      @click="$router.push('/platform/order/reportList')"
      style="margin-right:15px;"
      v-view="{sign:'hn:task-web:page:reportList'}"
    >
      报告列表
    </div>
    <div class="linear-btn" @click="$router.push('/platform/order/calendar')">日历排班</div>
      </div>

    <div v-view="{sign:'hn:driver'}">
      <div
        class="linear-btn"
        @click="$router.push('/platform/order/visual/view/task')"
        v-view="{sign:'hn:task-web:page:IntuitiveMode'}"
      >
      直观模式
      </div>
      <!-- <div class="linear-btn" @click="$router.push('/platform/flylog')">作业历史</div> -->
    </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { props } from "./props";
import API from "@/api";
export default {
  props: _.pick(props, ["onEdit"]),
  data() {
    return {
      rid: null,
      isEasy: false,
      search_form: {
        name: null,
        taskCateId: null,
        taskTypeId:null,
        taskStatus: null,
        applyStatus: null,
        processsingStatus: null,
      },
      drd: {
        cates: [
          { id: 2, title: "常态任务" },
          { id: 3, title: "临时紧急任务" },
        ],
        scenes: null,
        status: [
          { id: 0, title: "待执行" },
          { id: 3, title: "执行中" },
          { id: 1, title: "已完成" },
          { id: "-1", title: "已取消" },
          { id: "-2", title: "已拒绝" }
        ],
        apply: [
          { id: 0, title: "待审批" },
          { id: 1, title: "审批通过" },
          { id: 3, title: "默认通过" },
          { id: 2, title: "审批不通过" },
        ],
        processing: [
          { id: 0, title: "待处理" },
          { id: 1, title: "处理完成" },
          { id: 2, title: "无需处理" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  mounted() {
    this.async_get_list();
  },
  methods:{
      async async_get_list() {
      let res = await API.TASK.GetDrdCate();
      res = res.data;
      this.drd.scenes = res
    },
  }
};
</script>

<style lang="scss" scoped>
.cpt-task-list-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
::v-deep .el-input{
  width: 178px;
  .el-input__inner{
    width: 100% !important;
  }
}
</style>