<template>
  <div class="cpt-task-list-search">
    <div style="display: flex; align-items: center">
      
      <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
        <el-form-item label="局办单位:">
          <el-select
            v-model="search_form.typeId"
            size="mini"
            clearable
            placeholder="请选择局办单位"
          >
            <el-option
              v-for="item in drd.cates"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态:">
          <el-select
            v-model="search_form.status"
            size="mini"
            clearable
            placeholder="请选择任务状态"
          >
            <el-option
              v-for="item in drd.status"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item >
        <div @click="$emit('on-search', search_form)" class="linear-btn">
            搜索
          </div>
        </el-form-item>
        <el-form-item >
        <div style="margin-left:70px" @click="$emit('on-flyWork')" class="linear-btn">
            飞手排班
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div>
      
    <!-- <div @click="$emit('on-search', search_form)" class="linear-btn" style="margin-left: 20px" >
      批量排班
    </div> -->
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isEasy: false,
      search_form: {
        name: null,
        typeId: null,
        source: null,
        status: null,
      },
      drd: {
        cates: [
          // { id: 1, title: "飞行区" },
          { id: 2, title: "禁飞区" },
          { id: 3, title: "限飞区" },
        ],
        status: [
          { id: 1, title: "启用" },
          { id: 0, title: "禁用" },
        ],
        apply: [
          { id: 1, title: "自主创建" },
          { id: 0, title: "通用空域" },
        ],
      },
      data: {
        list: null,
        show: false,
        routeButton: false,
        routeButton1: false,
        routeButton2: false
      },
      visible: {
        upload: false,
      },
      fileList: [],
    };
  },
  created () {

  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.cpt-task-list-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  .allAir {
    z-index: 20;
  }
}
.padding {
  padding-right: 0px !important;
}

::v-deep .el-input {
  width: 178px;
  .el-input__inner {
    width: 100% !important;
  }
}
</style>