<template>
  <!-- 新增子任务弹框 -->
  <div class="add-subtask-popup">
    <div class="iconfont icon-guanbi close-subtask-popup" @click="() => $emit('on-close')"></div>
    <div class="content">
      <!-- title -->
      <div class="handle-title">
        <div class="handle-inner-title">{{!subTaskId?'新建子任务':subTaskReadOnly?'子任务详情':'修改子任务'}}</div>
      </div>
      <!-- 表单 -->
      <el-form class="linear-form vertical edit-form" :rules="rules.form" ref="formlist"
          :model="form">
        <el-form-item label="子任务名称" prop="taskTitle">
          <el-input
            v-model="form.taskTitle"
            placeholder="请输入子任务名称"
            clearable
            disabled
            :readonly="subTaskReadOnly"
          />
        </el-form-item>
        <el-form-item label="配备人员（个）" prop="providePersonCount">
          <el-input-number
            v-model="form.providePersonCount"
            placeholder="请输入配备人员数量"
            clearable
            :disabled="subTaskReadOnly"
            :min="1"
          />
        </el-form-item>
        <div class="linear-fields">
          <el-form-item label="配备无人机（架）" prop="deviceType" ref="Typedevice">
            <div class="overflow-auto">
            <div
              v-for="(item, index) in form.deviceType"
              :key="item.key"
              style="display: flex; align-items: center; margin-bottom: 6px"
            >
              <el-select
                v-model="item.deviceTypeId"
                placeholder="无人机类型"
                clearable
                :disabled="subTaskReadOnly"
                ref="selectChange1"
              >
                <el-option
                  v-for="item in drd.uav_type"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                  :disabled="item.disabled"
                />
              </el-select>
              <el-input-number
                v-model="item.count"
                placeholder="无人机数量"
                clearable
                :disabled="subTaskReadOnly"
                :min="1"
              />
              <span
                v-if="index === 0 && !subTaskReadOnly"
                @click="
                form.deviceType.length < drd.uav_type.length? form.deviceType.push({
                    deviceTypeId: null,
                    count: null,
                    key: nanoid(),
                  }):$message({message: `最多只能选择${drd.uav_type.length}种无人机类型`,type: 'warning'})
                "
                class="fields-icon el-icon-plus"
              ></span>
              <span
                v-if="index !== 0 && !subTaskReadOnly"
                @click="onetwo(index)"
                class="fields-icon icon-delete el-icon-minus"
              ></span>
            </div>
            </div>
          </el-form-item>
        </div>
        <div class="linear-fields">
          <el-form-item label="配备挂载（个）" prop="mountType">
            <div class="overflow-auto">
            <div
              v-for="(item, index) in form.mountType"
              :key="item.key"
              style="display: flex; align-items: center; margin-bottom: 6px"
            >
              <el-select
                v-model="item.mountTypeId"
                placeholder="挂载类型"
                clearable
                :disabled="subTaskReadOnly"
                ref="selectChange2"
              >
                <el-option
                  v-for="item in drd.mount_type"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                  :disabled="item.disabled"
                />
              </el-select>
              <el-input-number
                v-model="item.count"
                placeholder="挂载数量"
                clearable
                :disabled="subTaskReadOnly"
                :min="1"
              />
              <span
                v-if="index === 0 && !subTaskReadOnly"
                @click="
                form.mountType.length < drd.mount_type.length ? form.mountType.push({
                    mountTypeId: null,
                    count: null,
                    key: nanoid(),
                  }):$message({message: `最多只能选择${drd.mount_type.length}种挂载类型`,type: 'warning'})
                "
                class="fields-icon el-icon-plus"
              ></span>
              <span
                v-if="index != 0 && !subTaskReadOnly"
                @click="form.mountType.splice(index, 1)"
                class="fields-icon icon-delete el-icon-minus"
              ></span>
            </div>
            </div>
          </el-form-item>
        </div>
        

        <div class="linear-fields" style="width:100%">
          <el-form-item label="配备车辆" prop="vehicleIdList">
            <div class="overflow-auto">
              <el-cascader
                ref="elCascader"
                style="width:100%"
                class="input-orgname"
                placeholder="请选择要分配的车辆"
                v-model="form.vehicleIdList"
                :disabled="subTaskReadOnly"
                :options="vehicleArrayOptions"
                :show-all-levels="true"
                :props="{
                  children: 'vehicles',
                  label: 'carNumber',
                  value: 'id',
                  multiple: true,
                }"
                clearable
              >
              </el-cascader>
            </div>
          </el-form-item>
        </div>

        
        <el-form-item label="详细作业时间" prop="date" class="workData">
          <el-form-item prop="startedAt">
            <el-date-picker
              size="mini"
              v-model="form.startedAt"
              :picker-options="detailsPickerOptionsStart"
              @focus="detailsDateStartPickerFocus()"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择开始时间"
              :disabled="subTaskReadOnly"
            />
          </el-form-item>
          <span class="decr">—</span>
          <el-form-item prop="endAt">
            <el-date-picker
              :picker-options="detailsPickerOptionsEnd"
              size="mini"
              @focus="detailsDateEndtPickerFocus()"
              v-model="form.endAt"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择结束时间"
              :disabled="subTaskReadOnly"
            />
          </el-form-item>
        </el-form-item>
        
        <el-form-item label="需求单位:" class="aaxx" prop="dutyOrganName">
          <el-input
            v-model="form.dutyOrganName"
            placeholder="请输入需求单位"
            :readonly="subTaskReadOnly"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="发起需求人" prop="userName">
          <el-input
            v-model="form.userName"
            placeholder="请输入发起需求人"
            :readonly="subTaskReadOnly"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入联系电话"
            :readonly="subTaskReadOnly"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="选择街道:" prop="destination">
          <el-input
            v-model="form.destination"
            placeholder="请选择街道"
            disabled
          >
          </el-input>
        </el-form-item>

        <el-form-item label="详细作业位置:" required>
          <div style="display: flex; align-items: center">
            <el-form-item style="margin-bottom: 0px; flex: 1" prop="origin">
              <el-input
                v-model="form.origin"
                placeholder="请输入详细作业位置"
                :readonly="subTaskReadOnly"
                disabled
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="电池编号" prop="batteryNumbers">
          <el-select ref="selectChange3" :disabled="subTaskReadOnly"  v-model="form.batteryNumbers" placeholder="请选择电池编号" multiple  clearable class="Battery" :multiple-limit="2">
            <el-option
                  v-for="item in drd.BatteryList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="电池个数" prop="provideBatteryCount">
          <el-input-number
            v-model="form.provideBatteryCount"
            placeholder="请输入配备电池个数"
            clearable
            :disabled="subTaskReadOnly"
            :min="1"
          />
        </el-form-item> -->
        
        <el-form-item label="分配航线" prop="flightLineId">
          <div class="flightLine">
            <el-select  :disabled="subTaskReadOnly" v-model="form.flightLineId" placeholder="请选择航线" clearable>
              <el-option
                  v-for="item in drd.flightLine"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
            </el-select>
            <span v-if="!subTaskReadOnly" @click="resourcesIsShow = true" class="iconfont icon-chulixuqiu1 span" title="绘制航线"></span>
            <span @click="openAreaPopup" class="iconfont icon-luxianyulan span" title="区域查看"></span>
          </div>
        </el-form-item>
        <el-form-item label="选配AI" prop="ai">
            <el-select  multiple :disabled="subTaskReadOnly"  v-model="form.ai" placeholder="请选配AI" clearable>
              <el-option
                  v-for="item in aiList"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <!-- 绘制航线 -->
      <Resources v-if="resourcesIsShow" :closeResources="closeResources" :airLineCreate='airLineCreate'/>
      <!-- 运维人员选择 -->
      <!-- 已选择运维人员展示 -->
      <div
        class="select-man"
        v-if="flightUser && flightUser.length > 0"
      >
        <el-collapse v-model="activeName">
          <el-collapse-item title="之前已分配运维人员：" name="1">
            <div
              class="select-man-item"
              v-for="(item, index) in flightUser"
              :key="index"
            >
              <div class="item">姓名：{{ item.name }}</div>
              <div class="item">联系方式：{{ item.phone }}</div>
              <div class="item">所属单位：{{ item.ORG_NAME }}</div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!-- 运维人员列表 -->
      <Pilot v-if="!subTaskReadOnly" @on-submitForm="submitForm" :subTaskReadOnly="subTaskReadOnly" :id="totalTaskInformation.id" :flightUser="flightUser"/>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import API from "@/api";
import dayjs from "dayjs";
import Drawky from "@/components/drawky"; // 绘制区域的
import Resources from "./resources"; // 绘制航线的
import Pilot from "./pilot"; // 选择运维人员的
export default {
  props:{
    subTaskId: { // 修改子任务传过来的id
      type: Number,
      default: null
    },
    totalTaskInformation: { // 总任务信息
      type: Object,
      default: {}
    },
    subTaskReadOnly: { // 判断是否只读
      type: Boolean,
      default: false
    }
  },
  components: {
    Drawky,
    Pilot,
    Resources
  },
  data() {
    let editDevtype = (rule, value, callback) => {
      value.forEach((v)=>{
        if (v.deviceTypeId==null || v.deviceTypeId=='') {
            callback(new Error('请选择无人机类型'));
        }
        if (!v.count) {
          callback(new Error('无人机数量不能为0'));
        }
      })
      callback()
    };
    let editMountType = (rule, value, callback) => {
      value.forEach((v)=>{
        if (v.mountTypeId==null || v.mountTypeId=='') {
            callback(new Error('请选择挂载类型'));
        }
        if (!v.count) {
          callback(new Error('无人机数量不能为0'));
        }
      })
      callback()
    };
    let batteryNumbersValidator = (rule, value, callback) => {
      if(value.length == 0){
        callback(new Error("请选择电池"));
      } else if (value.length == 1) {
        callback(new Error("请选择两块电池"));
      }
      callback();
    };
    let dateStarteValidator = (rule, value, callback)=>{
      if(!this.form.startedAt){
        callback(new Error("请选择详细作业开始时间"));
      }
      callback();
    }
    let dateEndValidator = (rule, value, callback)=>{
      if(!this.form.endAt){
        callback(new Error("请选择详细作业结束时间"));
      }
      callback();
    }
    return {
      vehicleArrayOptions: [], // 车辆选择下拉列表
      areaPopupIsShow: false, // 控制区域弹框展示隐藏
      resourcesIsShow:false, // 控制绘制航线块是否打开
      flightUser: [], // 存储已分配人员信息
      activeName: "1",
      detailsPickerOptionsStart: {}, //详细作业开始时间限制
      detailsPickerOptionsEnd: {}, //详细作业结束时间限制
      selectList:null, // 需求单位下来列表
      aiList: null, // Ai下拉列表
      form: {
        taskTitle: this.subTaskId?'':`${this.totalTaskInformation.dutyOrganName}-${this.totalTaskInformation.taskTypeName}-${dayjs().format("YYYYMMDD-HH:mm:ss")}`, // 子任务名称
        vehicleIdList:[], //车辆选择
        date: 1,
        startedAt: null, // 详细作业开始时间
        endAt: null, // 详细作业结束时间
        destination: '海宁市-' + this.totalTaskInformation.destination, // 作业地点
        origin: this.totalTaskInformation.origin, // 详细作业地点
        dutyOrganName: this.totalTaskInformation.dutyOrganName, // 需求单位
        userName: this.totalTaskInformation.userName, // 相关联系人
        phone: this.totalTaskInformation.phone, // 相关联系人电话
        providePersonCount: 1, // 配置人员个数
        batteryNumbers: [], // 电池个数
        operatorIds: [], // 分配飞手
        flightLineId: null, // 分配航线
        ai: null,
        deviceType: [{ deviceTypeId: null, count: null, key: nanoid() }], // 无人机配备
        mountType: [{ mountTypeId: null, count: null, key: nanoid() }], // 挂载配备
      },
      rules: {
        form: {
          taskTitle: [
            { required: true, message: "请输入子任务名称", trigger: "blur" },
          ],
          date: [
            { required: true,   trigger: "blur" },
          ],
          startedAt: [
            { required: true, validator: dateStarteValidator,  trigger: "blur" },
          ],
          endAt: [
            { required: true, validator: dateEndValidator,  trigger: "blur" },
          ],
          destination: [
            { required: true, message: "请选择作业地点", trigger: "blur" },
          ],
          origin: [
            { required: true, message: "请输入详细作业地点", trigger: "blur" },
          ],
          dutyOrganName: [
            { required: true, message: "请选择需求单位", trigger: "blur" },
          ],
          userName: [
            { required: true, message: "请输入相关联系人", trigger: "blur" },
          ],
          phone: [
            { required: true, pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' },
          ],
          providePersonCount: [
            { required: true, message: "请输入配置人员个数", trigger: ["blur",'change']},
          ],
          batteryNumbers: [
            { required: true, validator: batteryNumbersValidator, trigger: "change" },
          ],
          deviceType: [
            { required: true, validator: editDevtype, trigger: "change" },
          ],
          mountType: [
            { required: true, validator: editMountType, trigger: "change" },
          ],
        },
      },
      drd: {
        uav_type: [],
        mount_type: [],
        task_type: [],
        task_cate: [],
        flightLine: [],
        BatteryList: [],
      },
    };
  },
  methods: {
    // ----------------------------------------------------------时间选择限制
    getNowTime(val) { // 时间时分秒限制
      let date = new Date(val);
      let hours = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hours < 10) hours = "0" + hours;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      let timeStr = `${hours}:${minute}:${second}`;
      return timeStr;
    },
    detailsDateStartPickerFocus() { // 详细作业开始时间获取焦点做选择限制
      this.detailsPickerOptionsStart = {
        disabledDate: (time) => {// 日期选择限制
          return (
            time.getTime() <new Date(this.totalTaskInformation.startedAt).getTime() - 8.64e7 + 1 || time.getTime() > new Date(this.totalTaskInformation.endAt).getTime()
          );
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    detailsDateEndtPickerFocus() { // 详细作业结束时间限制
      // 详细作业结束时间获取焦点做选择限制
      this.detailsPickerOptionsEnd = {
        disabledDate: (time) => {// 日期选择限制
          let day1 = this.form.startedAt?this.form.startedAt:this.totalTaskInformation.startedAt;
           // -8.64e7是为了能选同一天， +1 是为了他选了那天的时间00:00:00不能选前一天的
          return (
            time.getTime() < new Date(day1).getTime() - 8.64e7 + 1 ||
            time.getTime() > new Date(this.totalTaskInformation.endAt).getTime()
          );
        },
        selectableRange: "00:00:00 - 23:59:59", //  时分秒选择限制
      };
    },
    // ----------------------------------------------------------时间选择限制
    
    openAreaPopup() { // 打开区域弹框
      this.resourcesIsShow = false
      setTimeout(()=>{
        this.$emit('on-openAreaPopup')
      },0)
    },
    closeResources() { //控制绘制航线块关闭函数
        this.resourcesIsShow = false
    },
    airLineCreate(id) { //航线创建成功的函数
        this.FlightLine()   
        this.form.flightLineId = id*1
    },
    submitForm(ids) {  // 新增子任务、修改子任务按钮
      this.$refs['formlist'].validate(async(valid) => {
        if (valid) {
          let obj = JSON.parse(JSON.stringify(this.form))
          obj.startedAt =  dayjs(obj.startedAt).format("YYYY-MM-DD HH:mm:ss"),
          obj.endAt =  dayjs(obj.endAt).format("YYYY-MM-DD HH:mm:ss"),
          obj.operatorIds = ids
          obj.pid = this.totalTaskInformation.id
          obj.vehicleIdList = obj.vehicleIdList.map(item=>{
            return item[1]
          })
          obj.ai = obj.ai && obj.ai.length>0?obj.ai.toString(','):''
          if(this.subTaskId){ //  如果是修改
            obj.id = this.subTaskId
            const res = await API.TASK.updateSubtask(obj)
            if(res.status === 1){
              this.$message.success('修改成功')
              this.$emit('on-async_get_list')
              this.$emit('on-close')
            } else {
              this.$message.error(res.message)
              this.form.vehicleIdList = vehicleIdListCopy
            }
          } else {
            const res = await API.TASK.addSubtask(obj)
            if(res.status === 1){
              this.$message.success('添加成功')
              this.$emit('on-async_get_list')
              this.$emit('on-close')
            } else {
              this.$message.error(res.message)
            }
          }
          
        } else {
          setTimeout(() => {  // 如果不通过验证，跳到没有通过验证的输入框并获取焦点
            let isError = document.getElementsByClassName("is-error");
            if (isError.length > 0) {
              isError[0].querySelector('input').focus()
            }
          }, 100)
        }
      });
    },
    //同时选择需求单位和场景类型自动生成任务名称
    selectChange(dutyOrganId, selectList) {
      
    },
    onetwo(index){
      this.form.deviceType.splice(index, 1)
      this.form.deviceType.forEach((v)=>{
        
        if (v.deviceTypeId==null || v.deviceTypeId=='' || !v.count) {
            
        }else{
          this.$refs.Typedevice.clearValidate()
        }
      })
    },
    nanoid() {
      return nanoid();
    },
    closeArea() {  // 关闭区域弹框
        this.areaPopupIsShow = false
    },
    async getSubTaskData() { // 如果是修改子任务把子任务的数据请求回来并回显表单
      const {data} = await API.TASK.getOneSubtask(this.subTaskId)
      this.form.taskTitle = data.taskTitle
      this.form.startedAt = data.startedAt
      this.form.endAt = data.endAt
      this.form.origin = data.origin
      this.form.dutyOrganId = data.dutyOrganId
      this.form.userName = data.userName
      this.form.phone = data.phone
      this.form.batteryNumbers = data.batteryNumbers.map(item=>{
        return item*1
      })
      this.form.flightLineId = data.flightLineId
      this.form.deviceType = data.deviceType
      this.form.mountType = data.mountType
      this.form.ai = data.ai?data.ai.split(','):[]
      this.form.vehicleIdList =data.vehicleList && data.vehicleList.length>0 ? data.vehicleList.map(item=>{
        return [item.vehicleTypeId,item.vehicleId]
      }) : []
      this.flightUser = data.flightUser && data.flightUser.length?data.flightUser:[]
    },
    // ---------------------------------------------------------下方为获取输入框下拉数据的请求
    async drd_task_ais() { // AI下拉
      try {
        let res = await API.AI.List();
        this.aiList = res.data
      } catch (e) {
        console.error(e);
      }
    },
    async drd_task_type() {
      try {
        let res = await API.TASK.GetDrdType();
        res = res.data
        this.drd.task_type = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_task_cate() {
      try {
        let res = await API.TASK.GetDrdCate();
        res = res.data
        this.drd.task_cate = res.map((item) => ({
          ...item,
          title: item.name,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_uav_type() {
      try {
        let res = await API.UAV.GetDrdType();
        res = res.data
        this.drd.uav_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async drd_mount_type() {
      try {
        let res = await API.MOUNT.GetDrdType();
        res = res.data
        
        this.drd.mount_type = res.map((item) => ({
          ...item,
          title: item.name,
          disabled: false
        }));
      } catch (e) {
        console.error(e);
      }
    },
    async getAllOrganization() { // 获取需求单位下拉列表
      let { data } = await API.AIRWAY.getAllOrganizationBack();
      this.selectList = this.getTreeData(data);
    },
    getTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].child);
        }
      }
      return data;
    },
    async FlightLine() {  // 获取航线下拉数据
      const { data } = await API.TASK.Drd()
      this.drd.flightLine = data
    },
    async VehicleList() {  // 车辆列表获取
      try {
        let res = await API.TASK.VehicleList();
        this.vehicleArrayOptions = res.data
        this.vehicleArrayOptions.map(item=>{
          item.carNumber = item.carTypeName
          if(!item.vehicles){
            item.disabled = true
          }
        })
      } catch (e) {
        console.error(e);
      }
    },
    async getBatteryList() {  // 电池编号下拉选择获取
      const { data } = await API.TASK.BatteryList()
      this.drd.BatteryList = data
    }
  },
  watch: {
    $route(){
      this.drd_task_type();
      this.drd_task_cate();
      this.drd_uav_type();
      this.drd_mount_type();
      this.FlightLine()
      this.getBatteryList()
    },
    'form.startedAt': { // 详细作业时间开始时间发生变化
      handler: function(val){
        if(this.form.endAt && new Date(this.form.startedAt).getTime() > new Date(this.form.endAt).getTime()){
          this.form.endAt = null
        }
        if(val){
          const date = new Date(
            new Date(val).setHours(0, 0, 0, 0)
          ).getTime();
          const today1 = new Date(new Date(this.totalTaskInformation.startedAt).setHours(0, 0, 0, 0)).getTime();
          const today2 = new Date(new Date(this.totalTaskInformation.endAt).setHours(0, 0, 0, 0)).getTime();
          if (date != today1 && date != today2) {// 如果选的日期不是和当天同一天
            this.detailsPickerOptionsStart.selectableRange = "00:00:00 - 23:59:59";
          } else if(date == today1 && date == today2 ){ // 如果是开始和结束时间是同一天
              this.detailsPickerOptionsStart.selectableRange = `${this.getNowTime(this.totalTaskInformation.startedAt)} - ${this.getNowTime(this.totalTaskInformation.endAt)}`;
          } else if (date == today1) { // 如果是同一天
            this.detailsPickerOptionsStart.selectableRange = `${this.getNowTime(this.totalTaskInformation.startedAt)} - 23:59:59`;
          } else if (date == today2) {
            this.detailsPickerOptionsStart.selectableRange = `00:00:00 - ${this.getNowTime(this.totalTaskInformation.endAt)}`;
          }

          if (
            new Date(val).getHours() <new Date(this.totalTaskInformation.startedAt).getHours() &&
            new Date(val).getDay() == new Date(this.totalTaskInformation.startedAt).getDay() &&
            new Date(val).getDate() == new Date(this.totalTaskInformation.startedAt).getDate()
          ) { // 如果和开始日期同一天，点击的时候让它是开始日期
            this.form.startedAt = new Date(this.totalTaskInformation.startedAt);
          }
          if (
            new Date(val).getHours() > new Date(this.totalTaskInformation.endAt).getHours() &&
            new Date(val).getDay() == new Date(this.totalTaskInformation.endAt).getDay() &&
            new Date(val).getDate() == new Date(this.totalTaskInformation.endAt).getDate()
          ) {
            this.form.startedAt = new Date(this.totalTaskInformation.endAt);
          }
        }
      },
      deep:true,
      immediate:false
    },
    'form.endAt': { // 详细作业时间开始时间发生变化
      handler: function(val){
        let startTime = this.form.startedAt?this.form.startedAt:this.totalTaskInformation.startedAt
        if(val){
          const date = new Date(new Date(val).setHours(0, 0, 0, 0)).getTime();
          const today1 = new Date(new Date(startTime).setHours(0, 0, 0, 0)).getTime();
          const today2 = new Date(new Date(this.totalTaskInformation.endAt).setHours(0, 0, 0, 0)).getTime();
          if (date != today1 && date != today2) {// 如果选的日期不是和当天同一天
            this.detailsPickerOptionsEnd.selectableRange = "00:00:00 - 23:59:59";
          } else if(date == today1 && date == today2 ){ // 如果是开始和结束时间是同一天
              this.detailsPickerOptionsEnd.selectableRange = `${this.getNowTime(startTime)} - ${this.getNowTime(this.totalTaskInformation.endAt)}`;
          } else if (date == today1) { // 如果是同一天
            this.detailsPickerOptionsEnd.selectableRange = `${this.getNowTime(startTime)} - 23:59:59`;
          } else if (date == today2) {
            this.detailsPickerOptionsEnd.selectableRange = `00:00:00 - ${this.getNowTime(this.totalTaskInformation.endAt)}`;
          }
        }

        
        if (
          new Date(val).getHours() <new Date(startTime).getHours() &&
          new Date(val).getDay() == new Date(startTime).getDay() &&
          new Date(val).getDate() == new Date(startTime).getDate()
        ) { // 如果和开始日期同一天，点击的时候让它是开始日期
          this.form.endAt = new Date(startTime);
        }
        if (
          new Date(val).getHours() > new Date(this.totalTaskInformation.endAt).getHours() &&
          new Date(val).getDay() == new Date(this.totalTaskInformation.endAt).getDay() &&
          new Date(val).getDate() == new Date(this.totalTaskInformation.endAt).getDate()
        ) {
          this.form.endAt = new Date(this.totalTaskInformation.endAt);
        }

        if(val && new Date(val).getTime()<new Date(startTime)) {
          this.form.endAt = new Date(startTime);
        }
      },
      deep:true,
      immediate:false
    },
    'form.mountType': {   // 配备挂载下拉选择限制
      handler: function(val){
        let indexarr = []
        val.forEach(item=>{
          if(item.mountTypeId) {
            indexarr.push(item.mountTypeId)
          }
        })
        
        this.drd.mount_type.forEach(itemIn=>{
            itemIn.disabled = false
        })

        indexarr.forEach(item=>{
          this.drd.mount_type.forEach(itemIn=>{
            if(itemIn.id == item) {
              itemIn.disabled = true
            }
              
          })
        })
      },
      deep:true,
      immediate:true
    },
    "form.deviceType": {  // 配备无人机下拉限制
      handler: function(val){
        let indexarr = []
        val.forEach(item=>{
          if(item.deviceTypeId) {
            indexarr.push(item.deviceTypeId)
          }
        })
        this.drd.uav_type.forEach(itemIn=>{
            itemIn.disabled = false
        })

        indexarr.forEach(item=>{
          this.drd.uav_type.forEach(itemIn=>{
            if(itemIn.id == item) {
              itemIn.disabled = true
            }
              
          })
        })
      },
      deep:true,
      immediate:true
    },
  },
  mounted() {
    this.drd_task_type();
    this.drd_task_cate();
    this.drd_uav_type();
    this.drd_mount_type();
    this.FlightLine()
    this.getBatteryList()
    this.VehicleList()
    this.getAllOrganization()
    this.drd_task_ais()
    if(this.subTaskId){
      this.getSubTaskData()
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .cpt-platform-task-handle-form .Battery .el-tag {
    background-color: #009aff;
    border: none;
    color: #9fc4ff;
  } 
  .el-cascader__tags .el-tag  {
    background-color: #009aff;
    border: none;
    color: #9fc4ff;
  }
  .el-tag.el-tag--info {
    background-color: #009aff;
    border: none;
    color: #9fc4ff;
}
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: 600;
    color: #9fc4ff;
  }
  .el-collapse-item__content {
    color: #9fc4ff;
  }
  .el-collapse-item__content {
    padding-bottom: 16px;
  }
  .el-collapse-item__header {
    background: rgba(82, 39, 39, 0);
  }
  .el-collapse-item__wrap {
    background: rgba(82, 39, 39, 0);
    border: 0px;
  }
  .el-collapse-item__header {
    border: 0px;
  }
  .el-collapse {
    border: 0px;
  }
}
.add-subtask-popup{
  background: rgba(8, 37, 79, 1);
  width: 1200px;
  height: 800px;
  border: 2px solid #0067ab;
  position: absolute;
  top: 80px;
  left: 50%;
  margin-left: -600px;
  padding-right: 10px;
  box-sizing: border-box;
  z-index: 100;
  .close-subtask-popup{
    position: absolute;
    top: 16px;
    right: 16px;
    color: #44b1fa;
    font-size: 24px;
    cursor: pointer;
  }
  .content{
    width: 100%;
    height: calc(100% - 40px);
    margin: 20px 0;
    padding: 0 50px;
    box-sizing: border-box;
    overflow: auto;
    ::v-deep{
      .select-man {
        margin-bottom: 16px;
        color: #9fc4ff;
        font-size: 16px;
        .select-man-item {
          margin-top: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 70%;
          .item {
            width: 33%;
          }
        }
      }
      .handle-title {
        width: 392px;
        margin: 0 auto 24px;
        flex-shrink: 0;
        background-image: linear-gradient(
          270deg,
          rgba(105, 180, 255, 0) 0%,
          rgba(105, 180, 255, 0.3) 47%,
          rgba(105, 180, 255, 0) 100%
        );
        box-sizing: border-box;
        padding: 4px 0;
        position: relative;
        height: 32px;
        &:first-child {
          margin-top: 0px;
        }
        &::before {
          content: " ";
          display: block;
          width: 5px;
          height: 2px;
          background: #4dabff;
          position: absolute;
          left: 0;
          top: 0;
        }
        &::after {
          content: " ";
          display: block;
          width: 5px;
          height: 2px;
          background: #4dabff;
          position: absolute;
          right: 0;
          top: 0;
        }
        .handle-inner-title {
          width: 100%;
          height: 100%;
          background-image: linear-gradient(
            270deg,
            rgba(5, 130, 255, 0) 0%,
            rgba(5, 130, 255, 0.5) 47%,
            rgba(5, 130, 255, 0) 100%
          );
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          &::before {
            content: " ";
            display: block;
            width: 5px;
            height: 2px;
            background: #4dabff;
            position: absolute;
            left: 0;
            bottom: 0;
          }
          &::after {
            content: " ";
            display: block;
            width: 5px;
            height: 2px;
            background: #4dabff;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
      .edit-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .el-form-item__label{
          text-align: left !important;
          float: none !important;
        }
        .flightLine {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .el-select {
            width: 84%;
          }
          .span{
            width: 40px;
            height: 36px;
            border: 1px solid #009aff;
            margin-left: 5px;
            box-sizing: border-box;
            color: #fff;
            cursor: pointer;
            font-size: 20px;
            text-align: center;
            line-height: 36px;
          }
        }
        .area-button{
          width: 40px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #009aff;
          margin-left: 5px;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
        }
        .el-form-item {
          width: calc(50% - 6px);
        }
        .linear-fields {
          width: calc(50% - 6px);
          margin-bottom: 20px;
          border: none;
          max-height: none;
          padding: 0;
          .el-form-item {
            width: 100%;
          }
          .overflow-auto{
              overflow: auto;
              padding: 30px;
              max-height: 40px;
              border: 1px solid #27526e;
              .add-and-red{
                margin-bottom: 18px;
              }
          }
        }
        .workData {
          width: 50%;
          margin-bottom: 0px;
          .el-form-item__content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .decr{
              color: #009aff;
              line-height: 40px;
              padding: 0 10px;
              margin-top: -20px;
            }
            .el-date-editor{
              border: 0px;
            }
          }
        }
      }
    }
  }
  
}


</style>