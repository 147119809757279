<template>
  <div class="popup">
    <div class="page-service-achiev">
      <!-- 标题 -->
      <div class="title">
        <span>{{selectType == 1?'图片选择':'选择视频'}}</span>
      </div>
      <!-- 关闭按钮 -->
      <div @click="$emit('on-selectpAv')" class="back-button"></div>
      <!-- 上传附件按钮 -->
      <div class="upload-achiev linear-btn" @click="review.upload = true">上传附件</div>
      <!-- 保存按钮 -->
      <div class="save-achiev linear-btn" @click="saveSelect">保存</div>
      <!-- 列表 -->
      <div class="achi-list-wrap">
        <div class="achi-list">
          <div class="achi-item" v-for="(item) in dataSource" :key="item.id">
            <div class="select-box" :style="(selectType === 1 ? check.includes(item.storage): check.includes(item.storage)) ? 'background: #488cff' : 'background: #fff' "  @click="selectBottom(item)"></div>
            <el-popover
              popper-class="achi-action-popover"
              placement="right"
              width="400"
              trigger="click"
              style="zIndex:0"
              v-model="item.boolean"
            >
              <div class="action-list">
                <div @click="handle_review(item)" v-if="item.typeId != 4" class="action-item">查看</div>
                <div @click="handle_download(item)" class="action-item">
                  下载
                </div>
                <div @click="handle_before_rename(item)" class="action-item">
                  重命名
                </div>
              </div>
              <div class="achi-item" slot="reference">
                <div class="achi-icon-box">
                   <!-- // 图片 1 视频 2 文档 3 -->
                  <img v-if="item.typeId === 1"
                    class="achi-icon"
                    :src="item.storage"
                    alt="成果图片"
                  />
                  <img v-if="item.typeId === 4 || item.typeId === 2"
                    class="achi-icon"
                    :src="require(`@/assets/platform/achi-0${item.typeId}.png`)"
                    alt="achi-type"
                  />
                </div>
                <div :title="item.name" class="achi-text-box">
                  <input
                    :ref="`achi-${item.id}`"
                    type="text"
                    v-model="item.name"
                    @keydown.enter="handle_rename(item)"
                  />
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="table-pagination">
        <Pagination
          @size-change="handle_size_change"
          @page-change="handle_page_change"
          :current-page="pagination.page || 1"
          :page-sizes="pagination.pageSizes || [10, 20, 50, 100]"
          :page-size="pagination.pageSize || 10"
          :total="pagination.total || 0"
          :size="size"
        />
      </div>
    </div>

    <el-dialog
      title="查看视频"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-if="datalist"
      :visible.sync="review.video.visible"
      width="500px"
      :before-close="onOpenDialog"
      class="videoShow"
    >
      <VideoPlay  v-if="datalist" :datalist="datalist" />
    </el-dialog>

    <el-dialog
      title="上传附件"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-if="review.upload"
      :visible.sync="review.upload"
      width="500px"
      :before-close="onUpload"
      class="videoShow upload-attachments"
    >
      <el-upload
          action=""
          :accept="selectType == 1?'.jpg,.png':'.mp4'"
          :auto-upload='false'
          class="upload-demo"
          :on-change="fileChange"
          :on-remove="fileRemove"
          :limit="10"
          :multiple="true"
          :file-list="fileList"
          >
          <div slot="tip" class="el-upload__tip" style="margin-bottom: 12px">{{selectType == 1?'只能上传.png.jpg文件':'只能上传.mp4文件'}}</div>
          <el-button type="primary" >点击上传</el-button>
      </el-upload>
      <div class="sub">
          <el-button  type="primary" @click="sub">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

import Box from "@/components/platform/common/box";
import Pagination from "@/components/common/pagination";
import VideoPlay from "@/components/device/player/vidoeoJs"

import API from "@/api";
export default {
  components: { Box, Pagination, VideoPlay },
  props: {
    subTaskAchievementBaseId: {
      type: Number,
      default: null
    },
    selectType: {
      type: Number,
      default: null
    },
    aisPictrueOrVideoList: {
      type: Array | String,
      default: null
    }
  },
  data() {
    return {
      search: {},
      dataSource: [],
      size:null,
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0,
      },
      review: {
        image: {
          list: [],
          options: {
            url: "data-source",
          },
        },
        video: {
          visible: false,
          url: null,
        },
        upload: false
      },
      id: null,
      VUE_APP_IMGIP: null,
      datalist: null, //存储视频数据
      fileList: [],
      check: []
    };
  },
  methods: {
    saveSelect() { // 保存选择
      this.$emit('on-saveSelectpAv',this.check,this.selectType)
      this.$emit('on-selectpAv')
    },
    selectBottom(data) {
      if(this.selectType === 1) {
        if(this.check.includes(data.storage)) {
          this.check = this.check.filter(item => item != data.storage)
        } else {
          this.check.push(data.storage)
        }
      } else {
        if(this.check.length < 1) {
          this.check.push(data.storage)
          if(data.storage.indexOf('/api') > -1) {
            this.check.push(data.url)
          }
        } else {
          if (this.check.includes(data.storage)) {
            this.check = []
          } else {
            this.check = []
            this.check.push(data.storage)
            if(data.storage.indexOf('/api') > -1) {
              this.check.push(data.url)
            }
          }
        }
      }
    },
    async async_get_list() { // 获取飞行成果数据
      const { page, pageSize } = this.pagination;
      const { data } = await API.TASK.GetAchievementList({
        id: this.subTaskAchievementBaseId,
        typeId: this.selectType,
        pageNum: page,
        pageSize: pageSize,
      });
      data.data.forEach(v=>v.boolean=false)
      this.dataSource = data.data || [];
      this.dataSource.map(item => {
        if (this.selectType === 1) {
          if (this.aisPictrueOrVideoList && this.aisPictrueOrVideoList.length > 0 && this.aisPictrueOrVideoList.includes(item.storage)) {
          this.check.push(item.storage)
          }
        } else {
          if (this.aisPictrueOrVideoList && this.aisPictrueOrVideoList.length > 0 && this.aisPictrueOrVideoList.includes(item.storage)) {
          this.check.push(item.storage)
          }
        }
      })
      this.pagination.page = data.pageNo;
      this.pagination.total = data.totalCount;
      
      if(this.dataSource && this.dataSource.length == 0){
        this.$el_message("暂无成果信息")
      }
    },
    handle_size_change(size) {  // 改变一页个数
      this.pagination.pageSize = size;
      this.async_get_list();
    },
    handle_page_change(page) { // 改变页数
      this.pagination.page = page;
      this.async_get_list();
    },

    handle_review(data) {  // 查看
      //点击按钮关闭弹框
      data.boolean=false
      if (data.typeId == 1) {
        this.review.image.list = [data.storage]
        this.$viewerApi({
          images: this.review.image.list,
        });
      } else if (data.typeId == 2) {
        this.review.video.visible = true;
        this.datalist = data
      }
    },

    async handle_download(data) { // 下载
      if(data.typeId === 2 && data.storage.indexOf('/api') > -1) {
        window.open(data.storage,'_blank');
      } else {
        //点击按钮关闭弹框
        data.boolean=false
        let res;
        if(data.typeId === 1 || data.typeId === 2) {
          res = await API.TASK.updownload({
            key: data.key,
          });
        } else {
          res = await API.TASK.subtaskReportExport(data.key);
        }
        const a = document.createElement("a");
        a.href = URL.createObjectURL(res);
        a.download = data.typeId === 1?`${data.name}.png`:data.typeId === 2?`${data.name}.mp4`:`${data.name}.doc`;
        a.click();
        a.remove();
      }
    },

    handle_before_rename(data) { // 点重命名时
      //点击按钮关闭弹框
      data.boolean=false
      this.$refs[`achi-${data.id}`][0].focus();
    },
    async handle_rename(data) {  // 重命名请求
      let res = await API.TASK.taskDataUpdate({
        id: data.id,
        name: data.name
      })
      if (res.status === 1) {
        this.$el_message('修改成功');
        this.async_get_list();
      }
    },
    // 查看视频框
    onOpenDialog(done) {
      this.datalist = null
      done && done()
   	},
    // ----------------------------------------------------------------------------上传附件弹框
    onUpload(done) {  // 关掉弹框之后做的一些事情
      this.fileList = []
      done && done()
    },
    fileChange(file, fileList) { // 用户点上传文件的时候做的一些验证
      let type = ["image/png","video/mp4","image/jpeg"]
      if (fileList&&fileList.length>10) {
        fileList.shift();
      }
      this.fileList = fileList
      
    },
    fileRemove(file, fileList){ // 移除某个文件操作
      this.fileList = fileList
    },
    async sub() { // 提交文件
      if(this.fileList.length>0) {
        let res = null
        let formData = new FormData()
        this.fileList.map(item => formData.append("uploadFiles", item.raw))
        try {
          res = await API.TASK.uploads(formData);
          if (res.status === 1) {
            let imgType = ['png', 'jpg']
            res.data.map(item => {
              item.taskId = this.subTaskAchievementBaseId
              item.typeId = imgType.includes(item.name.substring(item.name.lastIndexOf(".") + 1)) ? 1 : 2
              item.affiliationTask = 1 // 0代表上传的子任务附件  1代表上传的是总任务附件
            })
            let addfile = await API.TASK.addData(res.data)
            if (addfile.status === 1) {
              this.$message.success('数据添加成功!')
              this.review.upload = false
              this.fileList = []
              this.async_get_list();
            } else {
              this.$message.error(addfile.message)
            }
          } else {
            this.$message.error(res.message);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$message.warning('请先选择文件!');
      }
    }
  },
  mounted() {
    const { VUE_APP_IMGIP } = process.env;
    this.VUE_APP_IMGIP = VUE_APP_IMGIP
    this.async_get_list();
  },
};
</script>

<style lang="scss">
.achi-action-popover {
  background: rgba(12, 34, 67, 0.5);
  min-width: auto;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #488cff;
  border-radius: 0;
  .popper__arrow {
    display: none;
  }
  .action-list {
    .action-item {
      text-align: center;
      font-size: 14px;
      color: #cce8ff;
      letter-spacing: 0;
      font-weight: 400;
      line-height: 30px;
      cursor: pointer;
      &:hover {
        color: #2cacff;
      }
    }
  }
  width: 98px !important;
}
.videoShow .el-dialog__body {
  width: 460px;
  height: 400px;
}
</style>

<style lang="scss" scoped>
.upload-attachments{
  ::v-deep{
    .el-dialog__body {
      width: 460px;
      height: auto !important;
    }
  }
}
/* 上传附件样式 */
::v-deep{
  .el-upload__tip{
    color: #fff;
    margin-top: 10px;
  }
  .sub{
    text-align: right;
    margin-top: 20px;
  }
  .el-upload-list__item:hover {
    background-color: #59749c;
  }
  .el-upload-list__item{
    color: #fff;
  }
  .el-upload-list__item-name{
    color: #fff;
  }
  .el-upload-list__item-name [class^=el-icon]{
    color: #fff;
  }
  .el-upload-list__item .el-icon-close{
    color: #fff;
  }
}
.popup{
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(8, 37, 79, 0.2);
  z-index: 200;
  width: 100%;
  height: 100%;
  ::v-deep{
    .page-service-achiev {
      position: absolute;
      top: 124px;
      left: 50%;
      margin-left: -820px;
      background: rgba(8, 37, 79, .9);
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 1640px;
      height: 700px;
      box-sizing: border-box;
      border: 2px solid #0067ab;
      padding: 20px 30px;
      z-index: 100;
      .title{
        text-align: center;
        color: #2cacff;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
        border-bottom: 1px solid #0067ab;
      }
      .back-button {
        position: absolute;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-image: url(~@/assets/platform/message/task/details/closure.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 16px;
        right: 30px;
      }
      .upload-achiev{
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 160px;
      }
      .save-achiev{
        position: absolute;
        cursor: pointer;
        top: 10px;
        width: 70px;
        right: 70px;
      }
      .achi-list-wrap {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        .achi-list {
          display: flex;
          flex-wrap: wrap;
          margin: 39px -73px -32px 0px;
          .achi-item {
            width: 156px;
            height: 150px;
            margin: 0 73px 32px 0;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            .select-box{
              position: absolute;
              top: 0;
              right: -30px;
              width: 20px;
              height: 20px;
              background: #fff;
              border-radius: 50%;
              cursor: pointer;
            }
            .achi-icon-box {
              width: 156px;
              height: 115px;
              position: relative;
              .achi-icon {
                width: 100%;
                height: 100%;
              }
            }
            .achi-text-box {
              input {
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                color: #a8bdd4;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                padding: 0;
                border: none;
                outline: none;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
      .table-pagination {
        flex-shrink: 0;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

    }
  }
}


.videoShow {
      .el-button--primary {
        background-color: #409EFF !important;
        border-color: #409EFF !important;
      }
  }
</style>