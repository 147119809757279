<template>
  <div class="dd">
    <div class="left">
      <div class="head">
        智慧调度
      </div>
      <div>
        <el-menu
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      >
      <!-- <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>人员看板</span>
        </template>
        <el-submenu index="1-4">
          <template slot="title">选项4</template>
          <el-menu-item index="1-4-1">选项1</el-menu-item>
        </el-submenu>
      </el-submenu> -->
      <el-menu-item v-for="item in data"  :key="item.value" :index="item.value" @click="updata(item.value)">
        <i class="el-icon-menu"></i>
        <span slot="title">{{item.name}}</span>
      </el-menu-item>
      <!-- <el-menu-item index="2">
        <i class="el-icon-menu"></i>
        <span slot="title">主任务列表</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-document"></i>
        <span slot="title">日历列表</span>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-setting"></i>
        <span slot="title">飞行成果</span>
      </el-menu-item> -->
    </el-menu>
      </div>
      
    </div>
    <div class="body">
      <!-- <div :class="'img' + value">

      </div> -->
      <Sh v-if="value === '1'" />
      <Rw v-if="value === '2'" />
      <Rl v-if="value === '3'" /> 
      <Cgk v-if="value === '4'" />
    </div>
  </div>
</template>

<script>
import Sh from "../message/task"
import Rw from "../task/list"
import Rl from "../calendar"
import Cgk from "../task/achievement"
export default {
  components: {
    Sh,Rw,Rl,Cgk
  },
  data() {
    return {
      activeIndex: "1",
      value: '1',
      data: [
        {
          name: '审核中心',
          value: '1'
        },
        {
          name: '主任务列表',
          value: '2'
        },
        {
          name: '日历列表',
          value: '3'
        },{
          name: '飞行成果',
          value: '4'
        }
      ]
    }
  },
  methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      updata(index) {
        this.value = index
        console.log('index', this.value);
      }
    },
  watch: {
    activeIndex: function (value) {
      console.log('value', value);
    }
  }
}
</script>

<style lang="scss" scoped>
  .dd {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    ::v-deep{
      .left {
      width: 200px;
      height: 100%;
      background: #928b8980;
      .head {
        height: 40px;
        text-align: center;
        line-height: 40px;

        
      }
      .el-menu-vertical-demo {
          border-right: none;
        }
    }
    .body {
      width: 100%;
      height: 100%;
      overflow: auto;
      .img1 {
        width: 100%;
        height: 100%;
        background: url("~@/assets/counteract/sp.png") no-repeat;
        background-size:99% 99%
      }
      .img2 {
        width: 100%;
        height: 100%;
        background: url("~@/assets/counteract/rw.png") no-repeat;
        background-size:99% 99%
      }
      .img3 {
        width: 100%;
        height: 100%;
        background: url("~@/assets/counteract/rl.png") no-repeat;
        background-size:99% 99%
      }
      .img4 {
        width: 100%;
        height: 100%;
        background: url("~@/assets/counteract/cgk.png") no-repeat;
        background-size:99% 99%
      }
    }
    }
  }
    
  
</style>