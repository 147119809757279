<template>
  <div>
    <div class="handle-title">
      <div class="handle-inner-title">绘制航线</div>
    </div>
    <div class="map">
      <!-- 地球 -->
      <Draw
        :move="(value) => move(value)"  
        :leftOnclick="(value) => leftOnclick(value)" 
        :rigthOnclick="(value) => rigthOnclick(value)" 
        :linelist="linelist"
        ref="drawRef"
      />
      <!-- 表单整理框 -->
      <Dialog
      title="手动规划"
      :isShowClose="true"
      :containerStyle="{
        position:'absolute',
        width: '356px',
        height:'451px',
        top: '160px',
        left: leftVal.leftFirst,
      }"
      :visible.sync="isShow"
      @onClose="handleClose"
    >
    <el-form
        ref="edit_form"
        :rules="rules"
        :model="edit_form"
        class="linear-form vertical"
        label-width="100px"
      >
       <el-form-item label="航线名称:" prop="flightName" ref="filename">
          <el-input
            v-model="edit_form.flightName"
            placeholder="请输入航线名称"
            clearable
            class="input-name"
          />
        </el-form-item>
         <el-form-item label="默认高度:" prop="name">
          <el-input
            v-model="edit_form.points[action.curr_index].alt"
            placeholder="请输入默认高度"
            clearable
            class="input-name"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          />
          <span class="label-name">m</span>
        </el-form-item>
         <el-form-item label="默认速度:" prop="speed" ref="speed">
          <el-input
            v-model="edit_form.speed"
            placeholder="请输入默认速度"
            clearable
            class="input-name"
          />
          <span class="label-name">m/s</span>
        </el-form-item>
         <el-form-item label="默认偏航角:" prop="name">
          <el-input
            v-model="edit_form.points[action.curr_index].pitch"
            placeholder="请输入默认偏航角"
            clearable
            class="input-name"
          />
          <span class="label-name">(°)</span>
        </el-form-item>
         <el-form-item label="默认俯仰角:" prop="name">
          <el-input
            v-model="edit_form.points[action.curr_index].gimbalPitch"
            placeholder="请输入默认俯仰角"
            clearable
            class="input-name"
          />
          <span class="label-name">(°)</span>
        </el-form-item>
         <el-form-item label="绘制航点:">
          <div class="btn-submit" style="width: 84px;height: 32px;" @click="visible.editdialog=!visible.editdialog">
              <img src="~@/assets/images/icons/RouteMapping.png">
              <span>{{edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
              ? ''
              : (action.curr_index + 1)}}</span>
          </div>
        </el-form-item>
    </el-form>
        <div class="foot-btn-box">
          <div class="btn-submit" @click="submitForm">
          保存航线
        </div>
        <div class="btn-submit" @click="handleClose()">
          重置
        </div>
        </div>
    </Dialog>

    <Dialog
      :title="edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
              ? '航点'
              : '航点'+(action.curr_index + 1)"
      :isShowClose="true"
      :plus="edit_form.points.length >= 2?true:false"
      @redcurr="redcurr"
      @addcurr="addcurr"
      :containerStyle="{
        position:'absolute',
        width: '356px',
        height:'451px',
        top: '160px',
        left: leftVal.leftSecond,
      }"
      :style="visible.editdialog?'visibility:visible':'visibility:hidden'"
      @onClose="visible.editdialog=false"
    >
      <el-form
        ref="edit_form2"
        :rules="rules"
        :model="edit_form"
        class="linear-form vertical"
        label-width="80px"
      >
       <el-form-item label="经度:" prop="points">
              <el-input
                v-model="edit_form.points[action.curr_index].lon"
                placeholder="请输入经度"
                clearable
                :disabled="visible.readonly"
                class="input-name"
              ></el-input>
        </el-form-item>
       <el-form-item label="纬度:" prop="points">
              <el-input
                v-model="edit_form.points[action.curr_index].lat"
                placeholder="请输入纬度"
                clearable
                :disabled="visible.readonly"
                class="input-name"
              ></el-input>
        </el-form-item>
          <el-form-item label="高度:" prop="alt">
          <el-input
            v-model="edit_form.points[action.curr_index].alt"
            :min="0"
            clearable
            :disabled="visible.readonly"
            oninput="value=value.replace(/[^\d]/g,'')"
            class="input-name"
            placeholder="请输入高度"
          />
          <span class="label-name">m</span>
        </el-form-item>
        <el-form-item label="偏航角:" prop="pitch">
          <el-input
            v-model="edit_form.points[action.curr_index].pitch"
            class="aainput"
            :disabled="visible.readonly"
            style="width:200px"
            placeholder="请输入偏航角"
          ></el-input>
          <span class="label-name">(°)</span>
        </el-form-item>
        <el-form-item label="俯仰角:" prop="gimbalPitch">
          <el-input
            v-model="edit_form.points[action.curr_index].gimbalPitch"
            :disabled="visible.readonly"
            style="width:200px"
            placeholder="请输入俯仰角"
          ></el-input>
          <span class="label-name">(°)</span>
        </el-form-item>
        <el-form-item label="动作:">
          <div style="display:flex;align-items:center;">
            <div class="btn-submit" style="width: 88px;height: 30px;lineHeight: 30px;" @click="() => (action.visible = true)">
            <i style="marginRight:5px;fontStyle:normal">+</i>增加</div>
            <div style="margin-left:10px" class="label-name">{{
              this.edit_form.points[this.action.curr_index].actions.length
            }}个动作</div>
          </div>
        </el-form-item>
      </el-form>
      <div class="foot-btn-box" v-if="!visible.readonly">
          <div class="btn-submit" @click="visible.editdialog = false">确定</div>
          <div class="btn-del" v-if="edit_form.points.length > 2" @click="delcurr">删除航点</div>
      </div>
    </Dialog>

    <el-dialog
      title="新增航点动作"
      :visible.sync="action.visible"
      width="326px"
      top="0"
      :close-on-click-modal="false"
      :modal="false"
      class="pub_dialog_hddz"
      :style="`left:${leftVal.leftThird}`"
    >
      <div class="form-submit-item" v-if="!visible.readonly">
        <div class="item-label-style" style="color:#9fc4ff;marginBottom:3px">航点动作</div>
        <el-dropdown style="width: 100%" trigger="click">
          <div class="form-submit-item">
            <div class="linear-btn" style="width: 100%;fontSize:14px;">
              <i style="marginRight:5px;fontStyle:normal">+</i>
              增加
            </div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in action.types"
              :key="index"
              :disabled="visible.readonly"
            >
              <div @click="handle_add_action(item)">{{ item.label }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="list-box">
        <div
          class="action-item"
          v-for="(item,index) in edit_form.points[action.curr_index].actions"
          :key="item.actionType"
        >

          <div class="flex">
            <div class="item-label-style">{{ item.label + ':' }}</div>
            <div class="item-input" v-if="item.actionType=='STAY'">
            <el-switch style="marginRight:23px" v-model="item.boolean" :disabled="visible.readonly" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
              <el-input
                :disabled="!item.boolean"
                v-model="item.actionParam"
                oninput="value=value.replace(/[^\d]/g,'')"
                :readonly="visible.readonly"
              >
              </el-input>
            </div>
            <div class="item-input" v-else-if="item.actionType=='START_TAKE_PHOTO'">
              <el-switch style="marginRight:23px" v-model="item.boolean" :disabled="visible.readonly" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
              <el-input
                :disabled="!item.boolean"
                v-model="item.actionParam"
                oninput="value=value.replace(/[^\d]/g,'')"
                :readonly="visible.readonly"
                class="item-inputer"
              >
              </el-input>
            </div>
            <div class="item-inputder" v-else>
              <el-switch v-model="item.boolean" :disabled="visible.readonly" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
            </div>
            <span class="item-labeler" v-if="item.actionType=='START_TAKE_PHOTO'||item.actionType=='STAY'">{{
              item.actionType == "START_TAKE_PHOTO" ? "张" : "s"
            }}</span>
            <div
              @click="handle_delete_action(index, item)"
              class="iconfont icon-shanchu"
              title="删除"
              style="color: #FF7070"
              v-if="!visible.readonly"
            ></div>
          </div>
        </div>
      </div>
    </el-dialog>
    
      <!-- <el-dialog
        title="手动规划"
        :visible.sync="isShow"
        width="400px"
        top="1vh"
        :close-on-click-modal="false"
        :modal="false"
        class="pub_dialog"
        :before-close="handleClose"
      >
        <div class="headBtn">
          <div class="linear-btn" @click="redcurr()">上一航点</div>
          <div class="airwayNum">
            <span class="current">{{
              edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
                ? 0
                : action.curr_index + 1
            }}</span>
            /
            <span class="total">{{
              edit_form.points[0].lon === 0 && edit_form.points[0].lat === 0
                ? 0
                : edit_form.points.length
            }}</span>
          </div>
          <div class="linear-btn" @click="addcurr()">下一航点</div>
        </div>
        <el-form ref="edit_form" :rules="rules" :model="edit_form" class="linear-form vertical">
          <el-form-item label="航线名称" prop="flightName">
            <el-input
              v-model="edit_form.flightName"
              placeholder="请输入航线名称"
              clearable
            />
          </el-form-item>
          <el-form-item label="航线速度" prop="speed">
            <el-input
              v-model="edit_form.speed"
              placeholder="请输入航线速度"
              clearable
            >
            <i slot="suffix" class="speedSlot">m/s</i>
            </el-input>
          </el-form-item>

          <el-form-item label="目标位置" prop="destination">
              <el-input
              v-model="edit_form.destination"
              placeholder="请输入目标位置"
              clearable
              class="destination"
            />
          </el-form-item>

          <el-form-item label="航线状态" prop="status" v-if="editProps">
            <el-radio-group v-model="edit_form.status">
              <el-radio-button :label="1">启用</el-radio-button>
              <el-radio-button :label="0">禁用</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item prop="points">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
                <div style="width: 48%;">
                  <el-form-item prop="longitude">
                    <div style="color:#9fc4ff;margin-bottom: 4px;"><span style="color:#F56C6C">*</span> 经度</div>
                  <el-input-number
                  v-model="edit_form.points[action.curr_index].lon"
                  placeholder="经度"
                  :min="-180"
                  :max="180"
                  clearable
                ></el-input-number>
                
                  </el-form-item>
                </div>

                <div style="width: 48%;">
                  <el-form-item prop="latitude">
                      <div style="color:#9fc4ff;margin-bottom: 4px;"><span style="color:#F56C6C">*</span> 纬度</div>
                  <el-input-number
                    v-model="edit_form.points[action.curr_index].lat"
                    placeholder="纬度"
                    :min="-90"
                    :max="90"
                    clearable
                  ></el-input-number>
                  </el-form-item>
                  
                </div>
            </div>
          </el-form-item>
          <el-form-item label="高度" prop="alt">
            <el-input-number
              v-model="edit_form.points[action.curr_index].alt"
              placeholder="高度"
              :min="0"
              clearable
            />
          </el-form-item>

          <el-form-item label="偏航角" prop="pitch">
            <el-input-number
              v-model="edit_form.points[action.curr_index].pitch"
              :min="-90"
              :max="90"
              class="aainput"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="俯仰角" prop="gimbalPitch">
            <el-input-number
              v-model="edit_form.points[action.curr_index].gimbalPitch"
              :min="-90"
              :max="90"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="航点动作">
            <div
              class="linear-btn"
              style="width: 100%"
              @click="() => (action.visible = true)"
            >
              {{ this.edit_form.points[this.action.curr_index].actions.length}}个动作
            </div>
          </el-form-item>

          <div class="form-submit-item">
            <div @click="handleSave" class="linear-btn">保存</div>
            <div v-if="this.edit_form.points.length > 2" @click="delcurr" class="linear-btn">删除</div>
          </div>
        </el-form>
      </el-dialog> -->
      <!-- 动作弹框 -->
      <!-- <el-dialog
        title="新增航点动作"
        :visible.sync="action.visible"
        width="316px"
        :close-on-click-modal="false"
        :modal="false"
        class="pub_dialog_hddz"
      >
          <div class="form-submit-item">
            <el-dropdown style="width: 100%" trigger="click">
              <div class="form-submit-item">
              <div class="linear-btn" style="width: 100%">增加<i class="el-icon-plus el-icon--right"></i></div>
            </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in action.types"
                  :key="index"
                >
                  <div @click="handle_add_action(item)">{{ item.label }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="list-box">
            <div
              class="action-item"
              v-for="(item, index) in edit_form.points[action.curr_index].actions"
              :key="index"
            >
              <div class="flex">
                <div class="item-label">{{ item.label }}</div>
                <div v-if="!item.input"
                @click="handle_delete_action(index, item)"
                class="iconfont icon-shanchu"
                title="删除"
              ></div>
              </div>
              
              <div class="flex" v-if="item.input">
                <div  class="item-input">
                <el-input-number
                  v-model="item.actionParam"
                  :is="item.input"
                  :min="item.min"
                  :max="item.max"
                ></el-input-number>
              </div>
              <div
                @click="handle_delete_action(index, item)"
                class="iconfont icon-shanchu"
                title="删除"
              ></div>
              </div>
              
            </div>
          </div>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Draw from "@/components/draw";
import { transformCartesian2WGS84 } from "@/utils/cesium";
import { nanoid } from "nanoid";
import Dialog from "@/components/common/dialog";
export default {
  components: {
    Draw,
    Dialog
  },
  props: { 
      closeResources: {
        type: Function,
        default: () => () => {},
      },
      airLineCreate: {
        type: Function,
        default: () => () => {},
      },
   },
  data() {
    let points = (rule, value, callback) => { // 经纬度验证
        for(let index=0; index<value.length; index++){
          if (value[index].lon === undefined || value[index].lat === undefined) {
              this.errorIndex.latAndlon = index
              break
          }else{
            this.errorIndex.latAndlon = -1
          }
        }
        if (value[this.action.curr_index].lon === undefined || value[this.action.curr_index].lat === undefined) {
            callback(new Error('经纬度信息不能为空，请补充完整该航点经纬度信息'));
        }
        callback()
    }
    let altValidator = (rule, value, callback) => { // 高度验证
        for(let index=0; index<this.edit_form.points.length; index++){
          if (this.edit_form.points[index].alt === undefined) {
              this.errorIndex.alt = index
              break
          }else{
            this.errorIndex.alt = -1
          }
        }
        if (this.edit_form.points[this.action.curr_index].alt === undefined) {
            callback(new Error('请输入高度'));
        }
        callback()
    }
    let pitchValidator = (rule, value, callback) => { // 偏航角验证
        for(let index=0; index<this.edit_form.points.length; index++){
          if (this.edit_form.points[index].pitch === undefined) {
              this.errorIndex.pitch = index
              break
          }else{
            this.errorIndex.pitch = -1
          }
        }
        if (this.edit_form.points[this.action.curr_index].pitch === undefined) {
            callback(new Error('请输入偏航角'));
        }
        callback()
    }
    let gimbalPitchValidator = (rule, value, callback) => { // 俯仰角验证
        for(let index=0; index<this.edit_form.points.length; index++){
          if (this.edit_form.points[index].gimbalPitch === undefined) {
              this.errorIndex.gimbalPitch = index
              break
          }else{
            this.errorIndex.gimbalPitch = -1
          }
        }
        if (this.edit_form.points[this.action.curr_index].gimbalPitch === undefined) {
            callback(new Error('请输入俯仰角'));
        }
        callback()
    }
    return {
      visible:{
        editdialog:false
      },
      leftVal:{
        leftFirst:'0px',
        leftSecond:'370px',
        leftThird:'740px'
      },
      isShow:true, // 控制组件是否展示
      errorIndex: { // 保存是否有航点信息不完整
        latAndlon: -1, //经纬度
        alt: -1, // 高度
        pitch: -1, // 偏航角
        gimbalPitch: -1, // 俯仰角
      }, 
      linelist: null,
      editProps: null,
      rules: { //校验规则
        flightName: [
          { required: true, message: "请输入航线名称", trigger: "blur" },
        ],
        speed: [
          { required: true, message: "请输入航线速度", trigger: "blur" },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的航线速度格式,可保留两位小数' }
        ],
        destination: [
          { required: true, message: "请输入目标位置", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请输入航线状态", trigger: "blur" },
        ],
        points: [ 
          { required: true, validator: points, trigger: 'change' }
        ],
        alt: [
          { required: true, validator: altValidator, trigger: 'change' }
        ],
        // latitude: [
        //     { required: true, message: "请输入纬度", trigger: "blur" },
        //     {
        //       type: 'number', min:0, max: 90, message: "请输入正确纬度", trigger: "blur"
        //     }
        // ],
        // longitude: [
        //   { required: true, message: "请输入经度", trigger: "blur" },
        //   {
        //     type: 'number', min:0, max: 180, message: "请输入正确经度", trigger: "blur"
        //   }
        // ],
        // 用来验证偏航角俯仰角的必填，然后说去掉了，线注释吧
        // pitch: [
        //   { required: true, validator: pitchValidator, trigger: 'change' }
        // ],
        // gimbalPitch: [
        //   { required: true, validator: gimbalPitchValidator, trigger: 'change' }
        // ]
      },
      edit_form: {
        flightName: null,
        destination: '',
        speed: 5,
        status: 1,
        points: [
          {
            lon: 0,
            lat: 0,
            alt: 100,
            pitch: 0,
            gimbalPitch: 0,
            actions: [],
            uuid: null
          },
        ],
      },
      action: {
        visible: false,
        types: [
          {
            label: "悬停",
            input: "el-input-number",
            min: 1,
            max: 32000,
            actionType: "STAY",
            actionParam: 10,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            actionParam: 0,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            actionParam: 0,
          },
          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            actionParam: 0,
          },
        ],
        curr_index: 0,
      },
      
    };
  },
  watch: {
    isShow (val) {
      if(val == false){
        this.closeResources()
      }
    }
  },
  methods: {
    leftOnclick(value) {
      const cartesian3 = value.positions[value.positions.length - 1];
      // 判断是否点击修改某点
      if (!isNaN(value.index)) {
        this.action.curr_index = value.index
        // this.move();
      } else {
        const aa = transformCartesian2WGS84(cartesian3);
        const first = this.edit_form.points[0];
        const last = this.edit_form.points[this.edit_form.points.length - 1];
        // 判断双击事件重复录入航点
        if (aa.lng === last.lon  && aa.lat === last.lat) {
        } else {
          // 判断第一个点，第一次点击航点覆盖默认点位信息
          if (first.lon === 0 && first.lat === 0) {
            // console.log("覆盖");
            this.edit_form.points[0].lon = aa.lng;
            this.edit_form.points[0].lat = aa.lat;
            this.edit_form.points[0].uuid = nanoid()
          } else {
            // console.log("新增");
            this.action.curr_index++;
            this.edit_form.points.push({
              lon: aa.lng,
              lat: aa.lat,
              alt: this.edit_form.points[this.action.curr_index -1].alt,
              pitch: 0,
              gimbalPitch: 0,
              actions: [],
              uuid: nanoid()
            });
          }
        }

      }
    },
    rigthOnclick(data) {
      if( data.positions.uuid ) {
        this.action.curr_index = 0;
        this.edit_form.points = [
          {
            lon: 0,
            lat: 0,
            alt: 120,
            pitch: 0,
            gimbalPitch: 0,
            actions: [],
          },
        ];
      } else {
        if( this.edit_form.points.length <= 1 ) {
          this.$el_message('无法删除首点')
        } else {
          this.action.curr_index --
          this.edit_form.points.pop()
        }
      }
      
    },
    move(data) {
      if (!isNaN(data.index)) {
        const aa = transformCartesian2WGS84(data.move_position);
      this.action.curr_index = data.index
      this.edit_form.points[data.index].lon = aa.lng;
      this.edit_form.points[data.index].lat = aa.lat;
      }
      
    },
    delcurr() { // 删除某个航点
      let index = this.action.curr_index
      if (index > 0) {
        this.action.curr_index --
        this.edit_form.points.splice(index , 1)
        this.$refs["drawRef"].clear(index)
      } else {
        this.$el_message('无法删除首点')
      }
    },
    // 上个航点
    redcurr() {
      if (this.action.curr_index > 0) {
        this.action.curr_index--;
      }
    },
    // 下个航点
    addcurr() {
      if (this.action.curr_index < this.edit_form.points.length - 1) {
        this.action.curr_index++;
      }
    },
    handle_add_action(item) {
      this.edit_form.points[this.action.curr_index].actions.push({...item})
    },
    handle_delete_action(index, item) {
      this.edit_form.points[this.action.curr_index].actions.splice(index, 1);
    },
    edit(value) {
      this.action.curr_index = 0

      this.edit_form.flightName = value.flightName
      // this.init_form(value);
      let { points } = JSON.parse(value.flightCourseJson)

      this.edit_form.points = points

      this.edit_form.status =
        value.status === "禁用"
          ? (this.edit_form.status = 0)
          : (this.edit_form.status = 1);

          
      
        this.editProps = value;

    },
    // 计算距离
    transformWGS842Cartesian(position, alt) {
        return position
            ? Cesium.Cartesian3.fromDegrees(
                position.lng,
                position.lat,
                (position.alt = alt || position.alt),
                Cesium.Ellipsoid.WGS84
            )
            : Cesium.Cartesian3.ZERO;
    },
    submitForm() {
      let list = [];
      list.push(
        this.checkForm("edit_form"),
        this.checkForm("edit_form2")
      );
      Promise.all(list)
        .then(() => {
          console.log("通过检测");
          this.handleSave()
        })
        .catch(() => {
          console.log("未通过");
        });
    },
    checkForm(formName) {
      console.log(this.$refs[formName]);
      return new Promise((resolve, reject) => {
        this.$refs[formName].validate(valid => {
          if (valid) {
            resolve();
          } else reject();
        });
      })
    },
    // 保存
    async handleSave() {
      // this.$refs["edit_form"].validate(async (valid) => {
        if(this.errorIndex.latAndlon !== -1){
          this.action.curr_index = this.errorIndex.latAndlon
        } else if (this.errorIndex.alt !== -1){
          this.action.curr_index = this.errorIndex.alt
        } else if (this.errorIndex.pitch !== -1){
          this.action.curr_index = this.errorIndex.pitch
        } else if (this.errorIndex.gimbalPitch !== -1){
          this.action.curr_index = this.errorIndex.gimbalPitch
        } else if (this.edit_form.points.length <= 1){
          this.$message.warning('请选择航点')
        } else {
          const { date, points, ...rest } = this.edit_form;
          const flightCourse = {
            filename: rest.flightName,
            line: {
              baseSpeed: rest.speed
            },
            points,
            baseSpeed: rest.speed
          }
          let distance = points.reduce((total, item, index) => {
            let dist = 0;
            if (points[index + 1]) {
              let cd = Cesium.Cartesian3.distance(
                this.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
                this.transformWGS842Cartesian({
                  lng: points[index + 1].lon,
                  lat: points[index + 1].lat,
                })
              );
              dist = cd;
            }
            return total + parseInt(dist);
          }, 0);

          const params = {
            ...rest,
            pointCount: points.length,
            distance,
            time: parseInt(distance / rest.speed),
            flightCourseJson: JSON.stringify(flightCourse),
            issafe: 1,
          };

          let res;
          
          res = await API.AIRWAY.addFlightLine({
            ...params,
            status: 1,
          });
          if (res.status === 1) {
            this.$el_message("创建成功");
            this.$refs["edit_form"].resetFields();
            this.handleClose()
            this.editProps = null;
            this.airLineCreate(res.data)
            this.closeResources();
          }
        }
      // });
    },
    // 关闭弹框前清除信息
    handleClose(done) {
      this.action.curr_index = 0;
      this.edit_form.points = [
          {
            lon: 0,
            lat: 0,
            alt: 120,
            pitch: 0,
            gimbalPitch: 0,
            actions: [],
            uuid: null
          },
        ]
        done && done()
    },
    editLine(value) {
      this.linelist = value
    }
  },

  inject: ["toggle_mode"],
};
</script>

<style lang="scss" scoped>
::v-deep .linear-form.vertical .el-form-item .el-form-item__label{
  float: left;
  text-align: right;
  line-height: 36px;
  font-size: 16px;
}
::v-deep .linear-form.vertical .el-form-item .el-form-item__content .el-input.input-name {
  width: 200px;
}
::v-deep .cpt-service-dialog .dialog-content{
    padding: 0 6px 5px 14px;
  }
  .label-name{
      margin-left: 4px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #BBCFF0;
      line-height: 21px;
    }
    .btn-submit{
       display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 36px;
      background: linear-gradient(180deg, rgba(28,91,197,0.6900) 0%, #091A38 100%);
      opacity: 0.8;
      border: 1px solid #488CFF;
      text-align: center;
      line-height: 34px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #9FC4FF;
      &:hover{
        opacity: 1;
        cursor: pointer;
      }
    }
    .btn-del{
      width: 100px;
      height: 34px;
      background: linear-gradient(180deg, rgba(182,81,81,0.6900) 0%, #091A38 100%);
      opacity: 0.8;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #FF7880;
      text-align: center;
      border: 1px solid #FF7070;
      line-height: 34px;
      border-bottom: 1px solid #FF7070;
      &:hover{
        opacity: 1;
        cursor: pointer;
      }
    }
    .foot-btn-box{
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  .map{
      width: 100%;
      height: 800px;
      position: relative;
      border: #009aff solid 1px;
  }
</style>
<style lang="scss" scoped>
// ::v-deep{
//   .linear-form.vertical .el-form-item .el-form-item__label {
//     line-height: 20px !important;
//   }
// }
.page-task-list {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  .task-list-notice-wrap {
    display: flex;
    .map-box {
      width: 436px;
      height: 274px;
      border: 1px solid red;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 16px;
    }
    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
          width: 98px;
        }
        .prop-field-v {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
  
}
</style>

<style lang='scss' scoped>
.pub_dialog {
  width: 400px;
  position: absolute;
  right: 10px;
  top: -10px;
  left: auto;
  bottom: auto;
  .headBtn {
    display: flex;
    justify-content: center;
    .airwayNum {
    height: 36px;
    line-height: 36px;
    font-size: 17px;
    color: #9fc4ff;
    padding: 0 20px;
  }
  }
}
// .pub_dialog_hddz {
//   width: 400px;
//   position: absolute;
//   right: 420px;
//   top: 0;
//   left: auto;
//   bottom: auto;
//   .action-item {
//     :hover{
//       background: rgba(12, 34, 67, 0.55) !important;
//     }
//     .flex {
//     display: flex;
//     justify-content: space-between;
//     height: 40px;
//     line-height: 40px;
//     .iconfont {
//       font-size: 30px;
//       color: #9fc4ff;
//     }
//     .item-label {
//       font-size: 14px;
//     color: #9fc4ff;
//     letter-spacing: 0;
//     display: block;
//     flex-shrink: 0;
//     float: none;
//     text-align: left;
//     }
//   }
//   }
//   .footer {
//     margin-top: 20px;
//     // .form-submit-item {
//       display: flex;
//       justify-content: space-between;
//     // }
//   }
  
// }

.pub_dialog_hddz {
  width: 326px;
  position: absolute;
  top: 160px;
  bottom: auto;
  .item-label-style{
        color:#9fc4ff;
        margin-bottom:3px
      }
  .action-item {
    margin: 10px 0;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      .iconfont {
        font-size: 30px;
        color: #9fc4ff;
        cursor: pointer;
      }
      .item-label {
        font-size: 14px;
        color: #9fc4ff;
        letter-spacing: 0;
        display: block;
        flex-shrink: 0;
        float: none;
        text-align: left;
        width: 60px;
        text-align: center;
      }
      .item-labeler {
        display: block;
        text-align: center;
        color: #9fc4ff;
        width: 10px;
      }
      .item-inputder{
        width: 180px;
        text-align: left;
      }
      .item-input {
        display: flex;
        align-items: center;
        width: 180px;
        // justify-content: space-around;
        justify-content: flex-end;
        .el-input{
          width: 94px;
          height: 36px;
        }
        .el-input .el-input__inner {
        color: #9fc4ff;
        width: 100%;
        background: rgba(3,24,49,0.8000);
        border: 1px solid #009AFF;
        margin-left: 0;
      }
      .item-inputer.el-input .el-input__inner{
        color: #9fc4ff;
        width: 94px;
        background: rgba(3,24,49,0.8000);
        border: 1px solid #009aff;
       }
      }
    }
  }
  .footer {
    margin-top: 20px;
    // .form-submit-item {
    display: flex;
    justify-content: space-between;
    // }
  }
}
.item-input .el-input-number .el-input .el-input__inner{
  height: 32px;
    box-sizing: border-box;
    background: rgba(12, 34, 67, 0.5);
    border: 1px solid #009aff;
    border-radius: 0;
    font-size: 16px;
    color: #9fc4ff;
    letter-spacing: 0;
    font-weight: 400;
    line-height: 32px;
}

.item-input .el-input-number .el-input-number__decrease{
  box-sizing: border-box;
    background: transparent;
    border-right: 1px solid #009aff;
    color: #fff;
    height: 32px;
}
.item-input .el-input-number .el-input-number__increase{
  background: transparent;
    border-left: 1px solid #009aff;
    color: #fff;
    box-sizing: border-box;
    height: 32px;
}
.showline .el-dialog .el-dialog__body{
  padding: 20px;
}
.speedSlot {
    line-height: 36px;
    font-size: 14px;
    color: #9fc4ff;
  }
</style>
