<template>
  <Box>
    <div class="page-service-airList">
      <Search @on-search="clickSearch" @on-flyWork="clickFlyWork"/>
      <div>
  <el-calendar v-model="value"> 
  <template
    slot="dateCell"
    slot-scope="{data}">
    <!-- 判断是否为当前月(current-month)，是就展示日期，否则啥也不展示 -->
    <p v-if="data.type == 'current-month'">
      {{ data.day.split('-').slice(1).join('-') }}
    </p>
    <!-- 先判断是否为当前月，且数据是否为空 -->
    <div class="arrangeWork" v-if="data.type == 'current-month' && scheduling.length > 0">
   	  <!-- 遍历数据，判断当前日期的日是否与数据中的（index+1）相等，也就是说判断是否为当天排班 -->
      <div class="left-box">
        <!-- {{data.day.split('-')[2] == index+1 ? '上段： ' + item.tasks.length?item.tasks[index].taskTitle:'' : ''}} -->
        <div class="item-box" v-for="(itemer,indexer) in someValue(data.day.split('-')[2],scheduling)" :key="itemer.id">
          <el-tooltip class="item" effect="dark" :content="`点击查看任务详情`" placement="left">
            <input @click="toDetails(itemer)" :style="styleColor(itemer)" class="title-label" :class="indexer>1?'shenglue':''" :value="inpValue(data.day.split('-')[2],itemer)" readonly="readonly">
          </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="`点击查看更多`" placement="left">
            <span @click="showMore(someValue(data.day.split('-')[2],scheduling))" class="click-label" :class="indexer>1?indexer==2?'xianshi':'':''">更多</span>
            </el-tooltip>
        </div>
      </div>
      <el-tooltip class="item" effect="dark" :content="`点击查看排班`" placement="top">
      <div class="right-box" @click="sumbitTodayDialog(data.day)">
        <div class="Schedule-box">
          <span>早班: </span>
          <span v-for="(item,index) in flyValue(data.day.split('-')[2],flyList,'morning')" :key="index"><i v-if="index>=1">,</i>{{item?item:'暂无'}}</span>
        </div>
        <div class="Schedule-box">
          <span>中班: </span>
          <span v-for="(item,index) in flyValue(data.day.split('-')[2],flyList,'middle')" :key="index"><i v-if="index>=1">,</i>{{item?item:'暂无'}}</span>
        </div>
        <div class="Schedule-box">
          <span>晚班: </span>
          <span v-for="(item,index) in flyValue(data.day.split('-')[2],flyList,'evening')" :key="index"><i v-if="index>=1">,</i>{{item?item:'暂无'}}</span>
        </div>
      </div>
      </el-tooltip>
    </div>
  </template>
</el-calendar>
<div class="bottom-pag">
  <!-- <Pagination
        :page-sizes="pagination.pageSizes || [5, 10, 50, 100]"
        :page-size="pagination.pageSize || 10"
        :total="pagination.total || 100"
        :size="10"/> -->
</div>
<div class="top-qiu">
    <div class="qiu-box" v-for="item in options" :key="item.id">
      <i class="qiu" :style="`backgroundColor:${item.color}`"></i>
      <span class="item-label-name">{{item.title}}</span>
    </div>
</div>
      </div>
    </div>
  <!-- 更多 -->
    <el-dialog
  title="任务"
  :visible.sync="showMoreValue"
  width="30%"
  :before-close="handleCloseMore"
  custom-class="more-box"
  v-if="showMoreValue"
  :close-on-click-modal='false'
  >
    <div class="item-lable-name" v-for="item in showMoreList" :key="item.taskTitle">
    <el-tooltip class="item" effect="dark" :content="`点击查看<${item.taskTitle}>任务详情`" placement="right">
      <span :style="item.taskStatus==1?'color:orange':item.taskStatus==0?'color:yellowgreen':'color:red'" @click="toDetails(item)">{{item.taskTitle}}</span>
    </el-tooltip>
    </div>
    </el-dialog>
  <!-- 飞手排班弹框 -->
  <el-dialog
  :title="weekFly?'新增飞行排班':'编辑飞行排班'"
  :visible.sync="flyWorkShow"
  width="55%"
  :before-close="CloseBox"
  custom-class="fly-box"
  v-if="flyWorkShow"
  :close-on-click-modal='false'
  >
 <div class="title-box">
  <div class="title-label" v-for="item in dayList" :key="item.id">{{item.value}}</div>
 </div>

<div class="content-body-box">
    <div class="content-box">
    <div class="title-value">
    <div class="title-label">早班</div>
    </div>
   <el-form
        :model="data_form"
        class="linear-form"
        inline
      >
  <div class="daylist" v-for="(item,index) in morningShift" :key="index">
    <div class="dayitem" v-for="itemer in morningShift[index]" :key="itemer.id">
      <el-form-item>
        <el-select v-model="itemer.flyPeople" placeholder="请选择" clearable>
          <el-option
            v-for="item in flyPeople"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
   </el-form>
  <div class="addDelete">
    <div @click="ClearMoring" class="linear-btn">清空早班</div>
    <div @click="addMorningList" style="margin: 0 20px" class="linear-btn">+新增行</div>
    <div @click="removeMorningList" class="linear-btn" v-if="morningShift.length>1">-删减行</div>
  </div>
  </div>

  <div class="content-box">
    <div class="title-value">
    <div class="title-label">中班</div>
    </div>
   <el-form
        :model="data_form"
        class="linear-form"
        inline
      >
  <div class="daylist" v-for="(item,index) in middleShift" :key="index">
    <div class="dayitem" v-for="itemer in middleShift[index]" :key="itemer.id">
      <el-form-item>
        <el-select v-model="itemer.flyPeople" placeholder="请选择" clearable>
          <el-option
            v-for="item in flyPeople"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
   </el-form>
  <div class="addDelete">
    <div @click="ClearMiddle" class="linear-btn">清空中班</div>
    <div @click="addMiddleList" style="margin: 0 20px" class="linear-btn">+新增行</div>
    <div @click="removeMiddleList" class="linear-btn" v-if="middleShift.length>1">-删减行</div>
  </div>
  </div>

  <div class="content-box">
    <div class="title-value">
    <div class="title-label">晚班</div>
    </div>
   <el-form
        :model="data_form"
        class="linear-form"
        inline
      >
  <div class="daylist" v-for="(item,index) in eveningShift" :key="index">
    <div class="dayitem" v-for="itemer in eveningShift[index]" :key="itemer.id">
      <el-form-item>
        <el-select v-model="itemer.flyPeople" placeholder="请选择" clearable>
          <el-option
            v-for="item in flyPeople"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
   </el-form>
  <div class="addDelete">
    <div @click="ClearEvening" class="linear-btn">清空晚班</div>
    <div @click="addEveningList" style="margin: 0 20px" class="linear-btn">+新增行</div>
    <div @click="removeEveningList" class="linear-btn"  v-if="eveningShift.length>1">-删减行</div>
  </div>
  </div>
</div>

  <span slot="footer" class="dialog-footer">
    <div class="linear-btn" @click="CloseBox">取 消 修 改</div>
    <div class="linear-btn" style="margin: 0 20px" @click="ClearWeek">清 空 排 班</div>
    <div class="linear-btn"  @click="submit">确 认 排 班</div>
  </span>
</el-dialog>
  <!-- 任务详情 -->
    <AddSubTask style="position: fixed;top:100px" v-if="showDetails"  :subTaskReadOnly="true" :subTaskId="DetailsId" @on-close="showDetails = false"></AddSubTask>
  <!-- 单日提交 -->
  <el-dialog
  :title="todayState+ ' ' + todayTitle +' 排班'"
  :visible.sync="submitDialog"
  width="50%"
  :before-close="CloseSubmitDialog"
  custom-class="today-box"
  v-if="submitDialog"
  :close-on-click-modal='false'
  >
       <el-form
        :model="data_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
    <div class="item-box">
        <el-form-item>
          <div style="display: flex;align-items:center">
         <div class="item-title">早班:</div>
            <div style="margin:0 5px" v-for="(item,index) in morningToday" :key="index">
          <el-select v-model="item.flyPeople" placeholder="请选择" clearable>
          <el-option
            v-for="item in flyPeople"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        </div>
        <div @click="addTodayList('morning')" class="linear-btn" style="width:30px;height:30px"> + </div>
        <div @click="removeTodayList('morning')" v-if="morningToday.length>1" class="linear-btn" style="width:30px;height:30px;margin-left:5px"> - </div>
          </div>
        </el-form-item>
    </div>
    <div class="item-box">
       <el-form-item>
        <div style="display: flex;align-items:center">
        <div class="item-title">中班:</div>
          <div  style="margin:0 5px" v-for="(item,index) in middleToday" :key="index">
          <el-select v-model="item.flyPeople" placeholder="请选择" clearable>
          <el-option
            v-for="item in flyPeople"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        </div>
       <div @click="addTodayList('middle')" class="linear-btn" style="width:30px;height:30px"> + </div>
       <div @click="removeTodayList('middle')" v-if="middleToday.length>1" class="linear-btn"  style="width:30px;height:30px;margin-left:5px"> - </div>
        </div>
       </el-form-item>
    </div>
    <div class="item-box">
       <el-form-item>
        <div style="display: flex;align-items:center">
      <div class="item-title">晚班:</div>
          <div  style="margin:0 5px" v-for="(item,index) in eveningToday" :key="index">
          <el-select v-model="item.flyPeople" placeholder="请选择" clearable>
          <el-option
            v-for="item in flyPeople"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        </div>
       <div @click="addTodayList('evening')" class="linear-btn" style="width:30px;height:30px"> + </div>
       <div @click="removeTodayList('evening')" v-if="eveningToday.length>1" class="linear-btn"  style="width:30px;height:30px;margin-left:5px"> - </div>
        </div>
       </el-form-item>
    </div>
       </el-form>
         <span slot="footer" class="dialog-footer">
          <div class="linear-btn" @click="ClearFlyList">清除排班</div>
          <div class="linear-btn" style="margin:0 20px" @click="CloseSubmitDialog">取 消 修 改</div>
          <div class="linear-btn" @click="sumbitToday">确 认 排 班</div>
        </span>
    </el-dialog>
  <div class="qiehuan">
    <div class="linear-btn" @click="prevMonth">上个月</div>
    <div class="linear-btn" @click="toMonth">本月</div>
    <div class="linear-btn" @click="nextMonth">下个月</div>
  </div>
  </Box>
</template>

<script>
import AddSubTask from './addSubTask.vue'
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
// import Handle from '@/pages/platform/task/handle'
import Pagination from "@/components/common/pagination";
import dayjs from 'dayjs'
import API from '@/api'
export default {
  components: { Box, Search , Pagination , AddSubTask },
  data() {
    return {
      //任务详情id
      DetailsId:null,
      //飞手
      flyList:[],
      //单日排班id
      taskId:null,
      //单日排班日期
      todayTitle:null,
      //单日排班状态名称--新增或者编辑
      todayState:'',
      //控制单日提交弹框显示隐藏
      submitDialog:false,
      //单日早中晚班
      morningToday:[],
      middleToday:[],
      eveningToday:[],
      //记录第一次更改单日排班之后，
      //是否又修改为无飞手排班了
      flystate:0,
      //任务详情
      showDetails:false,
      //查看更多弹框
      showMoreValue:false,
      morningValue:[],
      middleValue:[],
      eveningValue:[],
      //当前周的日期
      dates:[],
      //控制飞手排班显示隐藏
      flyWorkShow:false,
      //周排班的数据
      weekData:[],
      //判断周排班是否是都没有数据，默认是都有，数据为false
      weekFly:false,
      //周排班日期对应的id
      taskIdList:[],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      search: {},
      searchMonth:'2019-03-04',
      searchMonth:'2019-03-24',
      //渲染的数据
      scheduling:[],
      //任务对应颜色指示
      options:[
        {
              id:1,
              title:'已完成',
              color:'orange'
          },{
              id:2,
              title:'已预期',
              color:'red'
          },{
              id:3,
              title:'待执行',
              color:'yellowgreen'
          }
      ],
    //飞手排班标题
    dayList:[
      {id:1,value:'周一'},
      {id:2,value:'周二'},
      {id:3,value:'周三'},
      {id:4,value:'周四'},
      {id:5,value:'周五'},
      {id:6,value:'周六'},
      {id:7,value:'周日'},
    ],
    data_form:{},
    //早班
    morningShift:[],
    //中班
    middleShift:[],
    //晚班
    eveningShift:[],
    //飞手下拉列表
    flyPeople:[],
    //数据请求月份参数
    timeOfMonth:null,
    //日历组件绑定的时间值,需要修改绑定的值要传入对应的格式
    value:new Date(),
    //获取选中的某一天
    onclickPop:null
    };
  },
  methods: {
    //单日排班清除飞手
    ClearFlyList(){
      let clear=(arr)=>{
        arr.forEach(v=>{
          v.flyPeople=null
        })
      }
      clear(this.morningToday)
      clear(this.middleToday)
      clear(this.eveningToday)
    },
    //日历飞手显示
    flyValue(t,v,state){
      let list=[]
      v.forEach((value,index)=>{
        if (index+1==t) {
          if (value && value.pilotDetails) {
            //根据数据判断如果没有排班就返回null，然后就在页面显示暂无
            list.push({})
            if (JSON.parse(value.pilotDetails).morningValue.length) {
              list[0].morningValue=(JSON.parse(value.pilotDetails).morningValue)
            } else {
              list[0].morningValue=[null]
            }
            if (JSON.parse(value.pilotDetails).middleValue.length) {
              list[0].middleValue=(JSON.parse(value.pilotDetails).middleValue)
            } else {
              list[0].middleValue=[null]
            }
            if (JSON.parse(value.pilotDetails).eveningValue.length) {
              list[0].eveningValue=(JSON.parse(value.pilotDetails).eveningValue)
            } else {
              list[0].eveningValue=[null]
            }
            // list.push(JSON.parse(value.pilotDetails))
          }else{
          list.push({
            morningValue:[null],
            middleValue:[null],
            eveningValue:[null]
          })
        }
        }
      })
      //判断传入的（早班，中班，晚班）状态判断返回对应的数据
      if (state=="morning") {
        return list[0].morningValue
      } else if(state=="middle") {
        return list[0].middleValue
      } else if (state=="evening") {
        return list[0].eveningValue
      }
      
    },
    //保存并提交单日提交飞手排班
    sumbitToday(){
      let morningList=[]
      let middleList=[]
      let eveningList=[]
      let from_=[]
      this.morningToday.forEach((value,indedx)=>{
        if (value.flyPeople) {
          morningList.push(value.flyPeople)
        }
      })
      this.middleToday.forEach((value,indedx)=>{
        if (value.flyPeople) {
          middleList.push(value.flyPeople)
        }
      })
      this.eveningToday.forEach((value,indedx)=>{
        if (value.flyPeople) {
          eveningList.push(value.flyPeople)
        }
      })
      let pilotDetails;
      if (morningList.length || middleList.length || eveningList.length ) {
         pilotDetails=JSON.stringify({morningValue:morningList,middleValue:middleList,eveningValue:eveningList})
      }else{
         pilotDetails=''
      }
      // .replace(/\"/g,"")
      if (this.todayState=='新增' && !this.flystate) {
        from_.push({pilotDate:this.todayTitle,pilotDetails})
        API.SCHEDULING.addScheduling(from_).then((res)=>{
          if (res.code==200) {
            //根据当前的月份在当前月份刷新数据
            this.getList(this.todayTitle)
           this.$message({
             message: '新增成功',
              type: 'success'
            });
          }else{
            this.$message.error('出错了,未提交成功。')
            }
            this.CloseSubmitDialog()
        })
      } else {
        from_.push({id:this.taskId,pilotDetails})
        API.SCHEDULING.upPilot(from_).then((res)=>{
          let messageVal=this.flystate==0?'编辑成功':'新增成功'
          if (res.code==200) {
           //根据当前的月份在当前月份刷新数据
           this.getList(this.todayTitle)
           this.$message({
             message: messageVal,
              type: 'success'
            });
          }else{
            this.$message.error('出错了,未提交成功。')
          }
            this.CloseSubmitDialog()
        })
      }

    },  
    //关闭单日飞手排班的弹框
    CloseSubmitDialog(){
      this.submitDialog=false
      this.morningToday=[]
      this.middleToday=[]
      this.eveningToday=[]
      this.flystate=0
    },
    //单日排班添加飞手
    addTodayList(v){
      if (v=="morning") {
        this.morningToday.push({flyPeople:null})
      } else if(v=="middle") {
        this.middleToday.push({flyPeople:null})
      } else if (v=="evening") {
        this.eveningToday.push({flyPeople:null})
      }else {
      this.morningToday.push({flyPeople:null})
      this.middleToday.push({flyPeople:null})
      this.eveningToday.push({flyPeople:null})
      }
    },
    //删除单日排班一个飞手
    removeTodayList(v){
      if (v=="morning") {
        this.morningToday.pop()
      } else if(v=="middle") {
        this.middleToday.pop()
      } else if (v=="evening") {
        this.eveningToday.pop()
      }else {
      this.morningToday.pop()
      this.middleToday.pop()
      this.eveningToday.pop()
      }
    },
    //点击日历中每一个的右侧div展示单日飞手排班
   async sumbitTodayDialog(v){
    this.scheduling.forEach((value,index)=>{
          if (index==v.split('-')[2]-1) {
            if (value.pilot) {
              this.taskId=value.pilot.id
            }else{
              this.taskId=null
            }
          }
    })
      this.todayTitle=v
      if (!this.flyPeople.length) {
      //飞手下拉
      const { data } = await API.TASK.flyingUser();
      this.flyPeople = data
      }
      
     await API.SCHEDULING.getList({pilotDate:v}).then((res)=>{
      //判断是否为编辑
        if (res.data.length && res.data[0].pilotDetails) {
        this.todayState='编辑'
        let flyVal=JSON.parse(res.data[0].pilotDetails)
        if (flyVal.eveningValue.length ) {
          flyVal.eveningValue.forEach((value,index)=>{
           this.eveningToday.push({flyPeople:value})
        })
        }else{
          this.eveningToday.push({flyPeople:null})
        }
        if (flyVal.middleValue.length ) {
        flyVal.middleValue.forEach((value,index)=>{
           this.middleToday.push({flyPeople:value})
        })
        }else{
        this.middleToday.push({flyPeople:null})
        }
        if (flyVal.morningValue.length ) {
        flyVal.morningValue.forEach((value,index)=>{
          this.morningToday.push({flyPeople:value})
        })
        }else{
          this.morningToday.push({flyPeople:null})
        }
        }else{
          //当第一次修改后又将飞手排班修改为无，就需要将状态改变，以达到提交的时候让用户以为是添加
          //因为在修改第一次后再将数据修改为空，会将数据保存为空字符串并且提交
          if (res.data.length && res.data[0].pilotDetails=='') {
            this.flystate=1
          }
          this.todayState='新增'
          this.addTodayList()
        }
      })
      this.submitDialog=true
      // let res = API.SCHEDULING.addScheduling(from_value)
    },
    //根据任务状态id返回不同的颜色
    styleColor(itemer){
      console.log(itemer);
      if (itemer.taskStatus==1) {
        return 'color:orange'
      } else if(itemer.taskStatus==0) {
       return 'color:yellowgreen'
      }else  if(itemer.taskStatus==null){
        return 'color:#ccc'
      }else {
        return 'color:red'
      }
      // if (itemer.taskStatus==3) {
      //   return 'color:red'
      // }
      // itemer.taskStatus==1?'color:orange':itemer.taskStatus==0?'color:yellowgreen':'color:red'
    },
    //筛选出任务名称返回给input
    inpValue(t,itemer){
      if (itemer.indexer+1==t) {
        return itemer.taskTitle
      }
    },
    //取消修改飞手排班的点击事件，关闭飞手排班
    CloseBox(){
      this.morningShift = []
      this.middleShift = []
      this.eveningShift = []
      this.morningValue=[]
      this.middleValue=[]
      this.eveningValue=[]
      this.flyWorkShow = false
    },
  //   nowMonth(){
  //    this.scheduling=[]
  //    this.typeId=null
  //   API.SCHEDULING.List().then((res)=>{
  //   for (const key in res.data) {
  //     this.scheduling.push(res.data[key])
  //   }
  //  })
  //   },
    //获取日历列表任务数据
    getList(timeOfMonth){
      //更改月份将排班人员显示清空
      this.weekData=[]
      this.flyList=[]
      this.scheduling=[]
      this.timeOfMonth=timeOfMonth || null
      API.SCHEDULING.List({timeOfMonth:this.timeOfMonth}).then((res)=>{
      for (const key in res.data) {
      this.scheduling.push(res.data[key])
      }
      this.scheduling.forEach((v,i)=>{
        this.flyList.push(v.pilot)
      })
      })
    },
    //点击关闭任务详情弹框
    handleCloseDetails(){
      this.showDetails=false
    },
    //点击任务名称弹出子任务详情或者弹出提示
    toDetails(item){
      if (item.id) {
      this.showMoreValue=false
      this.DetailsId=item.id
      this.showDetails=true
      }else{
         this.$message({
          message: '该天暂无任务信息',
          type: 'warning'
        });
      }
      
    },
    //点击关闭更多弹框
    handleCloseMore(){
      this.showMoreValue=false
    },
    //点击更多弹出更多弹框
    showMore(v){
      this.showMoreList=v
      this.showMoreValue=true
    },
    //筛选数据中的任务数据
    someValue(t,v){
      if (t) {
        let list=[]
        v.forEach((value,index)=>{
          if (t==index+1) {
            if (value.tasks.length) {
            value.tasks.forEach((val,ind)=>{
            if (val.taskTitle) {
              list.push({
                taskTitle:val.taskTitle,
                id:val.id,
                taskStatus:val.taskStatus,
                indexer:index
              })
            }
          })
          }else{
            list=[{
              taskTitle:'暂无任务数据',
                taskStatus:null,
                indexer:index,
                id:null
            }]
          }
          }
        })
          return  list
      }
    },
    //清空早班
    ClearMoring(){
    this.morningShift.forEach(value=>{
        value.forEach(v=>{
          v.flyPeople=null
        })
      })
    },
    //清空中班
    ClearMiddle(){
    this.middleShift.forEach(value=>{
        value.forEach(v=>{
          v.flyPeople=null
        })
      })
    },
    //清空晚班
    ClearEvening(){
    this.eveningShift.forEach(value=>{
        value.forEach(v=>{
          v.flyPeople=null
        })
      })
    },
    //清空排班按钮
    ClearWeek(){
      this.ClearMoring()
      this.ClearMiddle()
      this.ClearEvening()
    },
    //点击飞手排班保存按钮
    submit(){
      //获取一周日期的id
       this.scheduling.forEach((value,index)=>{
        this.dates.forEach(v=>{
          if (index==v.split('-')[2]-1) {
            if (value.pilot) {
              this.taskIdList.push(value.pilot.id)
            }else{
              this.taskIdList.push(null)
            }
          }
        })
    })
      //将一周内的人员一一添加入数组，然后进行后续操作
      this.morningShift.forEach((v,i)=>{
        v.forEach((v,i)=>{
          if (v.flyPeople) {
            this.morningValue.push({timer:this.dates[i],flyPeople:v.flyPeople})
          }else{
            this.morningValue.push({timer:this.dates[i],flyPeople:null})
          }
        })
      })
      this.middleShift.forEach((v,i)=>{
        v.forEach((v,i)=>{
          if (v.flyPeople) {
            this.middleValue.push({timer:this.dates[i],flyPeople:v.flyPeople})
          }else{
            this.middleValue.push({timer:this.dates[i],flyPeople:null})
          }
        })
      })
      this.eveningShift.forEach((v,i)=>{
        v.forEach((v,i)=>{
          if (v.flyPeople) {
            this.eveningValue.push({timer:this.dates[i],flyPeople:v.flyPeople})
          }else{
            this.eveningValue.push({timer:this.dates[i],flyPeople:null})
          }
        })
      })
      //获取对应日期的早中晚班
      let morningValueList=[]
      let middleValueList=[]
      let eveningValueList=[]
      for (let index = 0; index < this.dates.length; index++) {
      morningValueList[index]={timer:null,flyPeople:[]}
      this.morningValue.forEach((v,i)=>{
        if (this.dates[index]==v.timer) {
          morningValueList[index].timer=v.timer
          //当有飞手的数据时添加，没有则默认为数组，渲染时就不会出现在页面上，只会显示一个暂无
          if (v.flyPeople) {
          morningValueList[index].flyPeople.push(v.flyPeople)
          }
        }
      })
        middleValueList[index]={timer:null,flyPeople:[]}
      this.middleValue.forEach((v,i)=>{
        if (this.dates[index]==v.timer) {
          middleValueList[index].timer=v.timer
          if (v.flyPeople) {
            middleValueList[index].flyPeople.push(v.flyPeople)
          }
        }
      })
        eveningValueList[index]={timer:null,flyPeople:[]}
      this.eveningValue.forEach((v,i)=>{
        if (this.dates[index]==v.timer) {
          eveningValueList[index].timer=v.timer
          if (v.flyPeople) {
          eveningValueList[index].flyPeople.push(v.flyPeople)
          }
        }
      })
    }
    //将数据分为新增以及编辑
    let from_value=[]
    let from_value2=[]
    if (this.morningValue.length || this.middleValue.length || this.eveningValue.length) {
        for(let i = 0; i < 7; i++) {
          if (morningValueList[i].timer || middleValueList[i].timer || eveningValueList[i].timer) {
            //判断是否是新增，新增就给一个日期参数，编辑给id参数
            if (!this.taskIdList[i]) {
              from_value.push({pilotDate:this.dates[i],pilotDetails:{morningValue:[],middleValue:[],eveningValue:[]}})
            }else{
              from_value2.push({id:this.taskIdList[i],pilotDetails:{morningValue:[],middleValue:[],eveningValue:[]}})
            }
            if (from_value.length) {
              from_value.forEach((value,index)=>{
              if (value&&value.pilotDate==this.dates[i]) {
                if ( morningValueList[i].timer==this.dates[i] ) {
                  morningValueList[i].flyPeople.forEach((val,ind)=>{
                from_value[index].pilotDetails.morningValue.push(val) 
              })
                }
            if ( middleValueList[i].timer==this.dates[i] ) {
              middleValueList[i].flyPeople.forEach((val,ind)=>{
                from_value[index].pilotDetails.middleValue.push(val) 
              })
            }
            if ( eveningValueList[i].timer==this.dates[i] ) {
              eveningValueList[i].flyPeople.forEach((val,ind)=>{
                from_value[index].pilotDetails.eveningValue.push(val) 
              })
            }
              from_value[index].pilotDetails=JSON.stringify(from_value[index].pilotDetails)
            }
            })
            }
            if (from_value2.length) {
              from_value2.forEach((value,index)=>{
                if (value&&value.id==this.taskIdList[i]) {
                  if ( morningValueList[i].timer==this.dates[i] ) {
                    morningValueList[i].flyPeople.forEach((val,ind)=>{
                      from_value2[index].pilotDetails.morningValue.push(val) 
              })
                }
            if ( middleValueList[i].timer==this.dates[i] ) {
              middleValueList[i].flyPeople.forEach((val,ind)=>{
                from_value2[index].pilotDetails.middleValue.push(val) 
              })
            }
            if ( eveningValueList[i].timer==this.dates[i] ) {
              eveningValueList[i].flyPeople.forEach((val,ind)=>{
                from_value2[index].pilotDetails.eveningValue.push(val) 
              })
            }
              from_value2[index].pilotDetails=JSON.stringify(from_value2[index].pilotDetails)
            }
            })
            }
          }
        }
    }
    let messageVal='保存成功'
    //测试数据
    // from_value2=[
    //    {id: 8, pilotDetails: '{"morningValue":["林全才"],"middleValue":["林全才"],"eveningValue":["林全才"]}'},
    //    {id: 5, pilotDetails: '{"morningValue":["王五"],"middleValue":["王五"],"eveningValue":["王五"]}'},
    //    {id: 6, pilotDetails: '{"morningValue":[],"middleValue":["颜芝","王五"],"eveningValue":["林全才"]}'},
    //    {id: 7, pilotDetails: '{"morningValue":["颜芝"],"middleValue":["王五"],"eveningValue":["龚帆"]}'}
    // ]
    // from_value=[
    //     {pilotDate: '2022-08-21', pilotDetails: '{"morningValue":["李四"],"middleValue":["王五"],"eveningValue":["叶继欢"]}'},
    //    {pilotDate: '2022-08-22', pilotDetails: '{"morningValue":["颜芝"],"middleValue":["林全才"],"eveningValue":["张子豪"]}'},
    //    {pilotDate: '2022-08-23', pilotDetails: '{"morningValue":[],"middleValue":["颜芝","李四"],"eveningValue":["王五"]}'}
    // ]
    if (from_value.length&&!from_value2.length) {
      API.SCHEDULING.addScheduling(from_value).then(res=>{
        if (res.code==200) {
           //根据当前的月份在当前月份刷新数据
           this.toMonth()
           this.$message({
             message: messageVal,
              type: 'success'
            });
          }else{
            this.$message.error('出错了,未提交成功。')
          }
      })
    } else if(from_value2.length&&!from_value.length) {
      API.SCHEDULING.upPilot(from_value2).then(res=>{
          if (res.code==200) {
           //根据当前的月份在当前月份刷新数据
           this.toMonth()
           this.$message({
             message: messageVal,
              type: 'success'
            });
          }else{
            this.$message.error('出错了,未提交成功。')
          }
        })
    }else{
          let res1 = API.SCHEDULING.addScheduling(from_value)
          let res2 = API.SCHEDULING.upPilot(from_value2)
          Promise.all([res1,res2]).then(res=>{
                res.forEach(v=>{
                  if (v.code==200) {
                    this.toMonth()
                    this.$message({
                  message: messageVal,
                    type: 'success'
                  });
                  }else{
                    this.$message.error('出错了,未提交成功。')
                  }
                })
          })
    }
      this.CloseBox()
    },
    //添加一行数据（早班）
    addMorningList(){
      this.morningShift.push(
        [
        {id:1,value: 0,label:'早班',flyPeople:null,timer:this.dates[0]},
        {id:2,value: 0,label:'早班',flyPeople:null,timer:this.dates[1]},
        {id:3,value: 0,label:'早班',flyPeople:null,timer:this.dates[2]},
        {id:4,value: 0,label:'早班',flyPeople:null,timer:this.dates[3]},
        {id:5,value: 0,label:'早班',flyPeople:null,timer:this.dates[4]},
        {id:6,value: 0,label:'早班',flyPeople:null,timer:this.dates[5]},
        {id:7,value: 0,label:'早班',flyPeople:null,timer:this.dates[6]},
        ]
      )
    },
    //删除一行数据（早班）
    removeMorningList(){
      this.morningShift.pop()
    },
    //添加一行数据（中班）
    addMiddleList(){
      this.middleShift.push(
        [
        {id:1,value: 0,label:'中班',flyPeople:null,timer:this.dates[0]},
        {id:2,value: 0,label:'中班',flyPeople:null,timer:this.dates[1]},
        {id:3,value: 0,label:'中班',flyPeople:null,timer:this.dates[2]},
        {id:4,value: 0,label:'中班',flyPeople:null,timer:this.dates[3]},
        {id:5,value: 0,label:'中班',flyPeople:null,timer:this.dates[4]},
        {id:6,value: 0,label:'中班',flyPeople:null,timer:this.dates[5]},
        {id:7,value: 0,label:'中班',flyPeople:null,timer:this.dates[6]},
        ]
      )
    },
    //删除一行数据（中班）
    removeMiddleList(){
      this.middleShift.pop()
    },
    //添加一行数据（晚班）
    addEveningList(){
      this.eveningShift.push(
        [
        {id:1,value: 0,label:'晚班',flyPeople:null,timer:this.dates[0]},
        {id:2,value: 0,label:'晚班',flyPeople:null,timer:this.dates[1]},
        {id:3,value: 0,label:'晚班',flyPeople:null,timer:this.dates[2]},
        {id:4,value: 0,label:'晚班',flyPeople:null,timer:this.dates[3]},
        {id:5,value: 0,label:'晚班',flyPeople:null,timer:this.dates[4]},
        {id:6,value: 0,label:'晚班',flyPeople:null,timer:this.dates[5]},
        {id:7,value: 0,label:'晚班',flyPeople:null,timer:this.dates[6]},
        ]
      )
    },
    //删除一行数据（晚班）
    removeEveningList(){
      this.eveningShift.pop()
    },
    //点击子组件的搜索按钮
    clickSearch(search_form){
        console.log(search_form);
    },
    //点击飞手排班添加一行数据
    addList(){
      if (!this.morningShift.length && !this.middleShift.length && !this.eveningShift.length ) {
      this.addMorningList()
      this.addMiddleList()
      this.addEveningList()
      }
    },
    //打开飞行排班弹框
   async clickFlyWork(){
     //是否有本周的日期存在，不存在就调用获取本周日期方法
      if (!this.dates.length) this.getWeekTime()
      //判断是否有本周的数据了，如果刷新了数据就会初始化这个数据，然后重新筛选
      if (!this.weekData.length) {
        this.scheduling.forEach((value,index)=>{
        this.dates.forEach((v)=>{
          if (index==v.split('-')[2]-1) {
            this.weekData.push(value.pilot)
          }
        })
    })
      }
    let morningLength=[]
    let middleLength=[]
    let eveningLength=[]
    //获取最大的数组长度
    this.weekData.forEach((value,index)=>{
      if (value && value.pilotDetails) {
      morningLength.push(JSON.parse(value.pilotDetails).morningValue.length)
      middleLength.push(JSON.parse(value.pilotDetails).middleValue.length)
      eveningLength.push(JSON.parse(value.pilotDetails).eveningValue.length)
      }else{
      morningLength.push(0)
      middleLength.push(0)
      eveningLength.push(0)
      }
    })
      let maxMorning=morningLength[0]
      let maxMiddle=middleLength[0]
      let maxEvening=eveningLength[0]
      for (let index = 1; index <= 7; index++) {
        if (maxMorning<morningLength[index]) {  
            maxMorning=morningLength[index]
        }
        if (maxMiddle<middleLength[index]) {  
            maxMiddle=middleLength[index]
        }
        if (maxEvening<eveningLength[index]) {  
            maxEvening=eveningLength[index]
        }
      }
      //根据原本的数据格式生产对应的数据
      //根据获取到的数组最大长度，将每一天每一行的数据加入显示的数组
      for (let index = 0; index < maxMorning; index++) {
        this.morningShift.push([])
        this.weekData.forEach((value,indexer)=>{
          if (value && value.pilotDetails) {
          this.morningShift[index].push(
            {id:indexer,value: 0,label:'早班',flyPeople:JSON.parse(value.pilotDetails).morningValue[index],timer:value.pilotDate}
          )
          }else{
             this.morningShift[index].push(
            {id:indexer,value: 0,label:'早班',flyPeople:null,timer:value.pilotDate}
          )
          }
        })
      }
      for (let index = 0; index < maxMiddle; index++) {
        this.middleShift.push([])
        this.weekData.forEach((value,indexer)=>{
          if (value && value.pilotDetails) {
          this.middleShift[index].push(
            {id:indexer,value: 0,label:'早班',flyPeople:JSON.parse(value.pilotDetails).middleValue[index],timer:value.pilotDate}
          )
          }else{
           this.middleShift[index].push(
            {id:indexer,value: 0,label:'早班',flyPeople:null,timer:value.pilotDate}
          )
          }
        })
      }
      for (let index = 0; index < maxEvening; index++) {
        this.eveningShift.push([])
        this.weekData.forEach((value,indexer)=>{
          if (value && value.pilotDetails) {
          this.eveningShift[index].push(
            {id:indexer,value: 0,label:'早班',flyPeople:JSON.parse(value.pilotDetails).eveningValue[index],timer:value.pilotDate}
          )
          }else{
           this.eveningShift[index].push(
            {id:indexer,value: 0,label:'早班',flyPeople:null,timer:value.pilotDate}
          )
          }
        })
      }
      //是否有飞手列表数据，这是在第一次点击会请求接口，第二次就无需调用
      if (!this.flyPeople.length) {
      try {
      //飞手下拉
      const { data } = await API.TASK.flyingUser();
      this.flyPeople = data
      } catch (error) {
        this.$message.error(error+'错误111')
      }
      }
      //点击后添加早，中，晚班一行数据,当这一周都没有数据时就默认添加一条数据
      if (!maxMorning&&!maxMiddle&&!maxEvening) {
        this.addList()
        //判断这一周是否都是没有排班的,true就表示这周都没有排班，显示就为新增排班，否则为编辑
        this.weekFly=true
      }else{
        if (!maxMorning) {
        this.addMorningList()
       }
       if (!maxMiddle) {
        this.addMiddleList()
      } 
       if (!maxEvening) {
        this.addEveningList()
      }
      }
      //完成后打开弹框
      this.flyWorkShow=true
    },
  //获取本周的日期
       getWeekTime(){
                // var new_Date = new Date(2021,11,10) //获取指定日期当周的一周日期
                let new_Date = new Date() //获取本周一周日期
                let timesStamp = new_Date.getTime();
                let currenDay = new_Date.getDay();
                let dates = [];
                for(let i = 0; i < 7; i++) {
                    let das = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString();
                    das.replace(/[年月]/g, '.').replace(/[日上下午]/g, '');
                    let dater = das.split('/');
                     dater[1]= dater[1]<10?0+dater[1]:dater[1]
                     dater[2]= dater[2]<10?0+dater[2]:dater[2]
                     das = dater.join("-")
                    dates.push(das);
                }
                this.dates = dates
            },
      //下个月的点击事件
      nextMonth(value) {
      let year = this.value.getFullYear();// 当前年份
      let month = this.value.getMonth() + 1;// 当前月份
      let day = this.value.getDate();// 当前天数
      let nextyear = year;
      let nextmonth = parseInt(month) + 1;
      // 判断下一月是否会进入下一年
      if (nextmonth === 13) {
        nextyear = parseInt(year) + 1;
        nextmonth = 1;
      }
      // 计算下一年下一个月有多少天
      let nextday = new Date(nextyear, nextmonth, 0);
      let nextdays = nextday.getDate();
      if (day > nextdays) {
        day = nextdays;
        if (day < 10) {
          day = '0' + day;
        }
      }
      if (nextmonth < 10) {
        nextmonth = '0' + nextmonth;
      }
      // let nexttime = nextyear + '-' + nextmonth + '-' + day;
      let nexttime = nextyear + '-' + nextmonth + '-' + '01';
      // 将得到的年月日格式转换为标准时间，与饿了么时间格式相同才能联动
      this.value = new Date(nexttime);
      this.getList(nexttime)
    },
    //上个月的点击事件
    prevMonth(time) {
      let year = this.value.getFullYear();// 当前年份
      let month = this.value.getMonth() + 1;// 当前月份
      let day = this.value.getDate();// 当前天数
      let prevyear = year;
      let prevmonth = parseInt(month) - 1;
      // 判断上一月是否会进入上一年
      if (prevmonth === 0) {
        prevyear = parseInt(year) - 1;
        prevmonth = 12;
      }
      // 计算上一年上一个月有多少天
      let prevday = new Date(prevyear, prevmonth, 0);
      let prevdays = prevday.getDate();
      if (day > prevdays) {
        day = prevdays;
        if (day < 10) {
          day = '0' + day;
        }
      }
      if (prevmonth < 10) {
        prevmonth = '0' + prevmonth;
      }
      // let prevtime = prevyear + '-' + prevmonth + '-' + day;
      let prevtime = prevyear + '-' + prevmonth + '-' + '01';
      // 将得到的年月日格式转换为标准时间，与饿了么时间格式相同才能联动
      this.value = new Date(prevtime);
      //获取数据
      this.getList(prevtime)
    },
    //本月的点击事件
    toMonth(){
                //获取本月第一天
                let new_Date = new Date() 
                let das = new_Date.toLocaleDateString();
                    das.replace(/[年月]/g, '.').replace(/[日上下午]/g, '');
                    let dater = das.split('/');
                     dater[1]= dater[1]<10?0+dater[1]:dater[1]
                     dater[2]= '01'
                     das = dater.join("-")
                     this.getList(das)
                     this.value = new Date(das);
    }
  },
 async created () {
  //获取本月的数据并且渲染到页面
  this.toMonth()
  },
  mounted() {
    document.querySelectorAll('.el-calendar-table td.current').forEach(v=>{
      v.onclick=()=>{
        this.onclickPop=v
        // v.className='current is-selected'
      }
    })
  },
};
</script>

<style lang="scss" scoped>
//禁止除了当日日期以外的地方点击
::v-deep .el-calendar-table:not(.is-range) td.next{
  pointer-events: none;
}
//禁止除了当日日期以外的地方点击
::v-deep .el-calendar-table:not(.is-range) td.prev{
pointer-events: none;
}
::v-deep .el-calendar__button-group {
  display: none;
}
::v-deep .el-button-group .el-button{
  background-color: transparent;
  color: #9fcfff;
  border: 1px solid #488cff;
}
::v-deep .el-button-group .el-button:hover{
  background-color: #48a1fa;  
  color: #fff;
}
::v-deep .el-calendar{
  background-color: transparent;
  color: #9fcfff;
}
::v-deep .el-calendar__title{
  color: #9fcfff;
}
::v-deep .el-calendar-table thead th{
  color: #9fcfff;
}
::v-deep .el-calendar-table .el-calendar-day:hover{
  background-color: #4798e9;
  color: rgb(8, 58, 194);
}
::v-deep .el-calendar-table td.is-selected{
  background-color: rgba(41, 125, 221, 0.7);
  color: #fff;
}
::v-deep .el-calendar-table tbody::after{
  background-color: #48a1fa;
  color: #fff;
}
::v-deep .el-calendar-table tbody::before{
  border: 1px solid black;
}
::v-deep  .el-calendar__header{
  border-bottom:none;
}
::v-deep  .el-calendar-table td{
  border-bottom:1px solid #488cff;
  border-right:1px solid #488cff;
}
::v-deep .el-calendar-table tr:first-child td {
  border-top:1px solid #488cff;
}
::v-deep .el-calendar-table tr td:first-child {
  border-left:1px solid #488cff;
}
.page-service-airList {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .task-table {
    flex: 1;
    margin-top: 27px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .iconfont {
        cursor: pointer;
        font-size: 26px;
        color: #9fc4ff;
        margin-right: 4px;
        line-height: 26px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .bottom-pag{
    display: flex;
    justify-content: flex-end;
  }
  .top-qiu{
   position: absolute;
   top: 50px;
   right: 700px;
   display: flex;
   .qiu-box{
    margin-right: 20px;
     .qiu{
       display: inline-block;
       background-color: #fff;
       width: 25px;
       height: 25px;
       border-radius: 50%;
     }
     .item-label-name{
       color: #9fc4ff;
       vertical-align: super;
       margin-left: 10px;
     }
   }
  }
  .arrangeWork{
    display: flex;
    .title-label{
    cursor: pointer;
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    padding: 0;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    }
    .shenglue{
      display: none;
    }
    .click-label{
      display: none;
      color: #9fcfff;
      font-size: 14px;
    }
    .xianshi{
      display: inherit;
    }
    .left-box{
      .item-box{
        width: 80px;
        margin-right: 10px;
      }
    }
    .right-box{
      display: flex; 
      flex-direction: column;
      .Schedule-box{
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:1;
        overflow:hidden;
        text-overflow:ellipsis;
      }
    }
  }
}
.fly-box{
  .title-box{
      display: flex;
      justify-content: space-around;
        margin-bottom: 20px;
      .title-label{
        font-size: 18px;
        color: #9fc4ff;
      }
    }
    .content-body-box{
      overflow: auto;
      height: 500px;
      width: 100%;
      .content-box{
        overflow-y: auto;
          .title-value{
            width: 100%;
            text-align: center;
            border-top: 1px solid #488cff;
            border-bottom: 1px solid #488cff;
            padding: 5px 0;
            .title-label{
              font-size: 20px;
              color: #9fc4ff;
            }
          }
          .daylist{
            display: flex;
            justify-content: space-around;
            margin-top: 20px;
          }
          .addDelete{
            display: flex;
            width: 100%;
            justify-content: center;
            margin: 20px 0;
            font-size: 18px;
            color: #fff;
          }
      }
    }
    .dialog-footer{
      display: flex;
      justify-content: center;
    }
}
.more-box {
  display: flex;
  flex-direction: column;
  .item-lable-name{
    padding: 10px 0;
    color: #488cff;
    .item{
      cursor: pointer;
    }
  }
}
.qiehuan{
  display: flex;
  position: absolute;
  right: 50px;
  top: 80px;
  .linear-btn{
    margin-right: 10px;
  }
}
.today-box{
  .item-box{
    width: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 10px 0;
    overflow: auto;
    .item-title{
      width: 40px;
      margin-right: 20px;
    }
  }
      .dialog-footer{
      display: flex;
      justify-content: center;
    }
}
</style>