<template>
    <el-dialog
      title="日志回放"
      v-if="logData"
      :visible.sync="logShow"
      width="935px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="logTable">
        <div style="width: 436px; height: 479px">
          <VideoPlay v-if="videoslist" :datalist="videoslist" />
        </div>
        <div class="tableRight">
          <div class="anniu tableRight-down">
            <div class="linear-btn" @click="downLog">下载日志</div>
            <div
              class="linear-btn log"
              @click="historicalTrack(logData.deviceHardId, journalid)"
            >
              历史轨迹
            </div>
          </div>
          <div class="logBody">
            <div class="props-box">
              <div class="prop-field">
                <div class="prop-field-k">无人机名称：</div>
                <div class="prop-field-v">{{ logData.deviceName }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">无人机ID：</div>
                <div class="prop-field-v">{{ logData.deviceHardId }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">飞行开始时间：</div>
                <div class="prop-field-v">{{ logData.addTime }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">飞行结束时间：</div>
                <div class="prop-field-v">{{ logData.endTime }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">所属任务：</div>
                <div class="prop-field-v">{{ logData.taskTitle }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">场景类型：</div>
                <div class="prop-field-v">{{ logData.taskTypeName }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">任务类型：</div>
                <div class="prop-field-v">{{ logData.taskCateName }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">责任单位：</div>
                <div class="prop-field-v">{{ logData.organizationName }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">飞行时长：</div>
                <div class="prop-field-v">
                    {{ logData.flightTime }}
                </div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">飞行距离：</div>
                <div class="prop-field-v">
                  {{
                    logData.flightDistance != null
                      ? logData.flightDistance + "m"
                      : "暂无"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
</template>

<script>
import API from "@/api";
import VideoPlay from "@/components/device/player/vidoeoJs";
export default {
  components: {
    VideoPlay
  },
  props:{
      value: {
        type: Object,
        default: null
      },
      historicalTrack: {
        type: Function,
        default: ()=>{}
      }
  },
  data() {
    return{
      logShow: true,
      videoslist: null, // 视频数据
      logData: null,
      journalid:null  // 子任务id
    }
  },
  methods: {
    async log(value) { // 获取日志回放数据
      this.journalid = value.id;
      const res = await API.TASK.taskLog({
        taskId: value.id,
        type: 0,
      });
      if (res.status === 1) {
        this.key = res.data.key;
        this.logData = res.data;
        
        if (this.logData.flightTime != null) {
          console.log(this.logData.flightTime);
          this.logData.flightTime = this.formatSeconds(this.logData.flightTime) 
        }else {
          this.logData.flightTime = '暂无'
        }
        this.videoslist = res.data.videos.length >= 1 ? res.data.videos[0] : null;
        console.log(this.videoslist);
      } else {
        this.$message.error(res.message);
      }
    },
    formatSeconds(value) {
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
      result = `${h}:${m}:${s}`
      return result
    },
    // 关闭日志弹框
    handleClose() {
      this.$emit('on-closeLogPlayback')
    },
    // 日志下载
    async downLog() {
        try {
          const blob = await API.TASK.exportData({
            taskId: this.logData.taskId,
          });
          if (blob) {
            let binaryData = [];
            binaryData.push(blob);
            const a = document.createElement("a");
            a.href = URL.createObjectURL(new Blob(binaryData));
            a.download = `${this.value.taskTitle}.xls`;
            a.click();
          }
        } catch (e) {
          console.error(e);
        }
    },
  },
  mounted() {
    this.log(this.value)
  }
}
</script>

<style lang="scss" scoped>
  .logTable {
    display: flex;
    .tableRight {
      padding-left: 16px;
      width: 436px;
      &-down {
        display: flex;
        .log {
          margin-left: 50px;
        }
      }
    }
    .logBody {
      padding-top: 14px;
      display: flex;
      .props-box {
        .prop-field {
          margin-bottom: 22px;
        }
      }
    }
    .logFooter {
      display: flex;
      align-items: center;
      .block {
        width: 220px;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
      .el-slider__button {
        width: 11px;
        height: 11px;
      }
    }

    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 16px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
          width: 120px;
          text-align: left;
        }
        .prop-field-v {
          font-size: 16px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
          max-width: 300px;
        }
      }
    }
  }

</style>