import { render, staticRenderFns } from "./search.vue?vue&type=template&id=445c4ce6&scoped=true"
import script from "./search.vue?vue&type=script&lang=js"
export * from "./search.vue?vue&type=script&lang=js"
import style0 from "./search.vue?vue&type=style&index=0&id=445c4ce6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445c4ce6",
  null
  
)

export default component.exports