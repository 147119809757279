<template>
  <Box>
    <div class="page-service-task-list">
      <Search @on-edit="onEdit" @on-search="handle_search" />
      <Table
        class="task-table"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        @size-change="
          (size) => {
            pagination.pageSize = size;
            async_get_list();
          }
        "
        @page-change="
          (page) => {
            pagination.page = page;
            async_get_list();
          }
        "
      >
        <template #taskStatusName="{ row }">
          <div class="action">
            <span
              :style="getColor(row.taskStatusName)"
              :title="row.taskStatusName"
              v-if="(row.applyStatusName !== '待审批' && row.applyStatusName !== '审批不通过') || row.taskStatusName == '已取消'"
              >{{ row.taskStatusName }}</span
            >
          </div>
        </template>

        <template #origin="{ row }">
          <div class="action">
            <span class="origin" :title="row.destination+ '-' + row.origin"
              >{{ row.destination }}-{{ row.origin }}</span
            >
          </div>
        </template>

        <template #startedAt="{ row }">
          <div class="action">
            <span class="origin" :title="row.startedAt + '-' + row.endAt"
              >{{ row.startedAt }}-{{ row.endAt }}</span
            >
          </div>
        </template>

        <template #applyStatusName="{ row }">
        <div class="action">
          <el-tooltip :style="getApplyColor(row.applyStatusName)" class="item" effect="dark" :content="row.approvalRemark?row.approvalRemark:row.applyStatusName" placement="top-start">
            <span>{{ row.applyStatusName }}</span>
          </el-tooltip>
        </div>
      </template>

        <template #action="{ row }">
          <div class="action">
            <!-- <span
              v-if=" ['默认通过', '审批通过'].includes(row.applyStatusName) && row.taskStatusName != '已取消' && row.taskStatusName != '已完成' && user_info_hn.departmentId === 60"
              @click="$router.push(`/platform/order/handle/${row.id}`)"
              title="处理需求"
              v-view="{ sign: 'hn:task-web:handle' }"
              class="iconfont icon-chulixuqiu1"
              v-show="hide"
            ></span> -->
            <span @click="openSubTask(row)" 
              title="子任务列表"
              class="iconfont"
              style="margin:2px 4px 0 0"
              v-if="!row.flag && row.applyStatusName != '待审批' && row.applyStatusName != '审批不通过'"
              v-view="{ sign: 'hn:taskOrder:list' }"
            >
              <img style="width:20px;height:20px"  src="../img/subtask.png" alt="">
            </span>
            <!-- <span
              v-view="{ sign: 'hn:task-web:configureAI' }"
              v-if="
                (row.userId == user_info_hn.uid || user_info_hn.departmentId === 60) &&
                row.taskStatusName != '已取消' &&
                row.taskStatusName != '已完成' &&
                row.applyStatusName != '审批通过' 
              "
              @click="handle_show_choose_ai(row)"
              title="选配AI"
              class="iconfont icon-xuanpeiAI"
            ></span> -->
            <span
              @click="handle_show_approve(row)"
              v-if="row.flag && row.taskStatusName != '已取消'"
              title="审批"
              class="iconfont icon-shenpi"
              v-view="{ sign: 'hn:task-web:superApprove' }"
            ></span>
            <span
              v-if="row.reportStatus === 1 && row.taskStatusName === '已完成' || (user_info_hn.departmentId === 60 && !row.flag && row.applyStatusName != '待审批' && row.applyStatusName != '审批不通过')"
              title="飞行成果"
              v-view="{ sign: 'hn:task-web:taskDataSearch' }"
              class="iconfont icon-ziliaoku"
              @click="$router.push(`/platform/order/achievement?id=${row.id}`)"
            ></span>
            <span
              v-if="
                (row.reportStatus === 1 && row.taskStatusName === '已完成') ||
                (user_info_hn.departmentId === 60 &&
                  row.taskStatusName === '已完成')
              "
              @click="openGeneralTaskReport(row.id)"
              title="任务报告"
              v-view="{ sign: 'hn:task-web:report' }"
              class="iconfont icon-renwubaogao"
            ></span>

            <!-- <span
              v-if="row.taskStatusName === '已完成'"
              @click="() => log(row)"
              title="飞行日志"
              v-view="{ sign: 'hn:task-web:taskFlightLog' }"
              class="iconfont icon-chulixuqiu"
            ></span> -->

            <span
              title="完成需求"
              class="iconfont icon-fenpeirenwu"
              @click="taskFinish(row.id)"
              v-if="row.taskStatusName !== '已完成' && row.taskStatusName !== '待执行' && row.taskStatusName !== '已取消' && !row.flag  && row.applyStatusName !== '审批不通过' && row.applyStatusName !== '待审批'"
              v-view="{ sign: 'hn:task-web:sumTaskFinish' }"
            ></span>

            <!-- <span
              title="重新下单"
              v-view="{ sign: 'hn:task-web:reCreate' }"
              class="iconfont icon-zhongxinxiadan-xuanzhong"
              @click="() => edit(row, true)"
              v-if="
                row.taskStatusName === '已完成' &&
                row.reportStatus === 2 &&
                row.userId == user_info_hn.uid
              "
            ></span> -->
            <el-popover
              placement="right"
              width="126"
              visible-arrow="false"
              trigger="click"
              popper-class="more-popover"
            >
              <div class="more-popover-box">
                <div
                  class="pointer"
                  @click="$router.push(`/platform/order/seeMore/${row.id}`)"
                  v-view="{ sign: 'hn:task-web:detail' }"
                >
                  查看更多
                </div>
                <div v-view="{ sign: 'hn:task-web:create:flight' }">
                <div
                  class="pointer"
                  v-view="{ sign: 'hn:task-web:previewFlight' }"
                  @click="seeAirline(row.id)"
                >
                  预览航线
                </div>
                </div>
                <div
                  class="pointer"
                  v-view="{ sign: 'hn:task-web:update' }"
                  @click="() => edit(row, false)"
                  v-if="
                    row.applyStatusName == '待审批' &&
                    row.userId == user_info_hn.uid
                  "
                >
                  更改
                </div>
                <div
                  class="pointer"
                  v-view="{ sign: 'hn:task-web:cancelTask' }"
                  @click="cancels(row)"
                  v-if="
                    row.applyStatusName == '待审批' &&
                    row.userId == user_info_hn.uid  &&
                    row.taskStatusName != '已取消'
                  "
                  :class="map.status == '-1' ? 'statuss' : ''"
                >
                  取消
                </div>
                <div
                  class="pointer"
                  v-view="{ sign: 'hn:task-web:delete' }"
                  @click="() => delList(row.id)"
                  v-if="(row.taskStatusName == '已取消' || row.applyStatusName == '审批不通过') && row.userId == user_info_hn.uid"
                >
                  删除
                </div>
              </div>

              <span slot="reference" class="iconfont icon-chakangengduo"></span>
            </el-popover>
          </div>
        </template>
      </Table>

      <!-- 子任务弹框 -->
      <SubTask v-if="subTaskPupupIsShow" :totalTaskInformation="totalTaskInformation"  :popupStates="subTaskPopupStatus" />
      <!-- 总任务任务报告弹框 -->
      <GeneralTask v-if="generalTaskReportId" @on-TaskReport="generalTaskReportId = null" :generalTaskReportId="generalTaskReportId"></GeneralTask>

      <el-dialog
        title="日志回放"
        v-if="visible.logShow"
        :visible.sync="visible.logShow"
        width="935px"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <div class="logTable">
          <div style="width: 436px; height: 479px">
            <!-- <video style="width: 436px; height: 479px; " :src="videoslist" controls autoplay ref="veo"></video> -->
            <VideoPlay v-if="videoslist" :datalist="videoslist" />
            <!-- <CloudRecordTimeBox /> -->
          </div>
          <div class="tableRight">
            <div class="anniu tableRight-down">
              <div class="linear-btn" @click="downLog">下载日志</div>
              <div
                class="linear-btn log"
                @click="historicalTrack(logData.deviceHardId, journalid)"
              >
                历史轨迹
              </div>
            </div>
            <div class="logBody">
              <div class="props-box">
                <div class="prop-field">
                  <div class="prop-field-k">无人机名称：</div>
                  <div class="prop-field-v">{{ logData.deviceName }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">无人机ID：</div>
                  <div class="prop-field-v">{{ logData.deviceHardId }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">飞行开始时间：</div>
                  <div class="prop-field-v">{{ logData.addTime }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">飞行结束时间：</div>
                  <div class="prop-field-v">{{ logData.endTime }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">所属任务：</div>
                  <div class="prop-field-v">{{ logData.taskTitle }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">场景类型：</div>
                  <div class="prop-field-v">{{ logData.taskTypeName }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">任务类型：</div>
                  <div class="prop-field-v">{{ logData.taskCateName }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">责任单位：</div>
                  <div class="prop-field-v">{{ logData.organizationName }}</div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">飞行时长：</div>
                  <div class="prop-field-v">
                    <!-- {{
                      logData.flightTime != null
                        ? logData.flightTime + "s"
                        : "暂无"
                    }} -->
                     {{ logData.flightTime }}
                  </div>
                </div>
                <div class="prop-field">
                  <div class="prop-field-k">飞行距离：</div>
                  <div class="prop-field-v">
                    {{
                      logData.flightDistance != null
                        ? logData.flightDistance + "m"
                        : "暂无"
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>

      <div class="out-content">
        <el-dialog
          :title="taskshow.list ? '预览航线' : '历史轨迹预览'"
          :close-on-click-modal="false"
          :visible.sync="taskshow.show"
          v-if="taskshow.show"
          :before-close="clearTask"
          class="taskDialong"
        >
          <Draw
            v-if="taskshow"
            :taskshow="taskshow.list"
            :historicalTrack="historicalTrackList"
            :readonly="taskshow.show"
            class="taskDraw"
          />
        </el-dialog>
      </div>

      <el-dialog
        title="选配AI"
        :close-on-click-modal="false"
        :visible.sync="visible.ai"
        width="452px"
      >
        <AiBooth ref="ai-booth" :dataSource="ai.dataSource" selection />
        <div style="display: flex; justify-content: center; margin-top: 12px">
          <!-- <div @click="handle_choose_ai(0)" class="linear-btn" style="margin-right: 20px">暂不选配</div> -->
          <div @click="handle_choose_ai()" class="linear-btn">确定</div>
        </div>
      </el-dialog>

      <el-dialog
        title="温馨提示"
        :close-on-click-modal="false"
        :visible.sync="visible.sp"
        width="384px"
        class="taskapprove"
      >
        <el-dialog
          width="484px"
          title="审批不通过原因"
          :visible.sync="innerVisible"
          :close-on-click-modal="false"
          append-to-body
        >
          <div style="color: #cce8ff; text-align: center; font-size: 20px">
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请输入审批不通过原因"
              v-model="reasonFailure"
            ></el-input>
          </div>
          <div
            class="button"
            style="
              display: flex;
              justify-content: space-between;
              padding: 27px 0 0 0;
            "
          >
            <div
              @click="
                () => {
                  (this.innerVisible = false), (this.reasonFailure = '');
                }
              "
              class="linear-btn"
            >
              取消
            </div>
            <div @click="handle_approve(2)" class="linear-btn">确定</div>
          </div>
        </el-dialog>
        <div style="color: #cce8ff; text-align: center; font-size: 20px">
          审批需求清单是否通过？
        </div>
        <div class="button">
          <div @click="handle_approve(1)" class="linear-btn">审批通过</div>
          <div @click="innerVisible = true" class="linear-btn">审批不通过</div>
        </div>
      </el-dialog>
      <el-dialog
        title="温馨提示"
        :close-on-click-modal="false"
        :visible.sync="visible.qx"
        width="384px"
        class="taskapprove"
      >
        <div style="color: #cce8ff; text-align: center; font-size: 20px">
          是否取消
        </div>
        <div class="button">
          <div @click="cancel(1)" class="linear-btn">确定</div>
          <div @click="cancel(0)" class="linear-btn">取消</div>
        </div>
      </el-dialog>
    </div>
  </Box>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import Draw from "@/components/draw";
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
import Table from "@/components/common/table";
import { props } from "./props";
import API from "@/api";
import AiBooth from "@/components/device/ai-booth";
import VideoPlay from "@/components/device/player/vidoeoJs";
import SubTask from "../subtask/index.vue"; // 子任务弹框
import GeneralTask from "../report/subTaskReport/index.vue"; // 总任务任务报告弹框
import CloudRecordTimeBox from '@/components/videoBox/CloudRecordTimeBox.vue'

export default {
  props: _.pick(props, ["onEdit", "edit"]),
  components: { Box, Search, Table, AiBooth, Draw, VideoPlay, SubTask, GeneralTask, CloudRecordTimeBox },
  data() {
    return {
      generalTaskReportId: null, //总任务报告id存储 
      innerVisible: false, // 处理需求内层弹框
      subTaskPupupIsShow: false, // 子任务列表弹框
      totalTaskInformation: null, // 总任务信息
      reasonFailure: "", // 处理需求不通过原因
      historicalTrackList: [], // 历史轨迹存储
      journalid: null,
      hide: true,
      visible: {
        ai: false,
        approve: false,
        sp: false,
        qx: false,
        qxid: null,
        logShow: false, //日志回访
      },
      columns: [
        { prop: "taskTitle", label: "任务名称", width: "300px" },
        { prop: "taskCateName", label: "任务类型", width: "150px" },
        { prop: "taskTypeName", label: "场景类型", width: "100px" },
        { prop: "flightName", label: "航线名称", width: "200px" },
        // { prop: "aiDispose", label: "配置AI", width: "200px" },
        {
          prop: "taskStatusName",
          slot: true,
          label: "任务状态",
          width: "100px",
        },
        { prop: "dutyOrganName", label: "需求单位", width: "150px" },
        {
          prop: "applyStatusName",
          slot: true,
          label: "审批状态",
          width: "100px",
        },
        { prop: "startedAt", slot: true, label: "飞行时间", width: "200px" },
        { prop: "origin", slot: true, label: "作业位置", width: "250px" },
        { prop: "action", slot: true, label: "操作", width: "200px" },
      ],
      dataSource: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      search: {},
      map: {
        status: {
          "-2": "已拒绝",
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          // 2: "执行中",
          3: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
        processing: {
          0: "待处理",
          1: "处理完成",
          2: "无需处理",
        },
      },
      ai: {
        dataSource: [],
        data: null,
      },
      approve: {
        data: null,
      },
      applist: null,

      taskshow: {
        show: false, // 预览航线是否展示
        list: null, // 预览航线存储数据
      },
      key: null, //日志推送key
      logData: null, // 接口返回日志信息
      videoslist: null, //视频数据
      logList: null, //点击日志存储列表任务信息
    };
  },
  computed: {
    ...mapGetters(["user_info_hn"]),
  },
  methods: {
    subTaskPopupStatus() {
      this.subTaskPupupIsShow = false
      this.async_get_list()
    },
    async taskFinish(id) { // 总需求点击完成
      const res = await API.TASK.sumTaskFinish(id)
      if(res.status == 1){
        this.$message.success('操作成功')
        this.async_get_list()
      } else {
        this.$message.warning(res.message)
      }
    },
    // ==============================================================总任务报告函数开始
    openGeneralTaskReport(id){
      this.generalTaskReportId = id
    },
    // ==============================================================总任务报告函数结束
    openSubTask(row) {  // 打开子任务列表弹框
      this.totalTaskInformation = row
      this.subTaskPupupIsShow = true
    },
    getColor(status) {
      switch (status) {
        case "待执行":
          return "color: #16D979";
          break;
        case "执行中":
          return "color: #FB8016";
          break;
        case "已完成":
          return "color: #009AFF";
          break;
        case "已拒绝":
          return "color: #F55252"
        default:
          break;
      }
    },
    getApplyColor(status) {
      switch (status) {
        case "待审批":
          return "color: #E6A23C";
          break;
        case "审批通过":
          return "color: #16D979";
          break;
        case "默认通过":
          return "color: #16D979";
          break;
        case "审批不通过":
          return "color: #FB8016";
          break;

        default:
          break;
      }
    },
    show_handle(data) {
      return (
        this.user_info_hn &&
        this.user_info_hn.departmentId == 1 &&
        data.processingStatus == 0
      );
    },
    async async_get_list() {
      const { map } = this;
      const { page, pageSize } = this.pagination;
      // let res = await API.TASK.List({
      //   ...this.search,
      //   pageNum: page,
      //   pageSize: pageSize,
      // });
      // res = res.data;
      // this.dataSource = res.data || [];
      // this.dataSource = this.dataSource.map((item) => ({
      //   ...item,
      //   taskStatusName: map.status[item.taskStatus],
      //   applyStatusName: map.apply[item.papplyStatus],
      //   processingStatusName: map.processing[item.processingStatus],
      // }));
      // // 根据角色显示选配的AI
      // // if(this.user_info_hn.departmentId != 60) {
      // //   this.dataSource.map(item => item.ai = item.aiDispose)
      // // }
      // this.pagination.page = res.pageNo;
      // this.pagination.total = res.totalCount;
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    },

    has_approve_auth(data) {
      const { user_info_hn } = this;
      const { taskPApplyList } = data;
      if (!taskPApplyList || !taskPApplyList.length) {
        return false;
      }

      const item = taskPApplyList.find((item) => item.id == user_info_hn.id);
      if (!item) {
        return false;
      }

      return true;
    },

    async async_get_ai_list(data) {
      try {
        let res = await API.AI.List();
        res = res.data;
        this.ai.dataSource = res.map((item) => ({
          title: item.value,
          id: item.key,
          icon: item.path,
          checked: false,
        }));
      } catch (e) {
        console.error(e);
      }
    },

    async handle_show_choose_ai(data) {
      await this.async_get_ai_list(data);
      if (data.aiDispose) {
        let select = data.aiDispose.split(",");
        this.ai.dataSource.map((item) => {
          if (select.includes(item.title)) {
            item.checked = true;
          }
        });
      }
      this.visible.ai = true;
      this.ai.data = data;
    },

    async handle_choose_ai() {
      const ais = this.$refs["ai-booth"].get_checked_nodes();
      let res
      try {
        // if(this.user_info_hn.departmentId != 60) {
        //   res = await API.TASK.EditAi({
        //     id: this.ai.data.id,
        //     aiDispose: ais.map((item) => item.id),
        //     disposeAiChoose: ais.length > 0 ? 1 : 0,
        // });
        // } else {
          res = await API.TASK.EditAi({
            id: this.ai.data.id,
            // ais: ais.map((item) => item.id),
            // aiIsRequired: ais.length > 0 ? 1 : 0,
            aiDispose: ais.map((item) => item.id),
            disposeAiChoose: ais.length > 0 ? 1 : 0,
          });
        // }
        if (res.status === 1) {
          this.$el_message("处理成功");
          this.async_get_list();
          this.visible.ai = false;
        } else {
          this.$message.error(res.message);
        }
      } catch (e) {
        console.error(e);
      }
    },

    handle_show_approve(data) {
      this.applist = data;
      this.visible.sp = true;
    },
    async handle_approve(num) {
      // 审批消息是否通过
      if (num == 2 && !this.reasonFailure) {
        this.$message.warning("请输入审批不通过原因");
      } else {
        let obj = {
          id: this.applist.id,
          status: num,
        };
        if (this.reasonFailure) {
          obj.approvalRemark = this.reasonFailure;
        }
        try {
          const res = await API.TASK.EditPApplyStatus(obj);
          if (res.status == 1) {
            if (num == 1) {
              this.$el_message("审批通过");
            } else {
              this.$message.error("审批不通过");
            }
            this.async_get_list();
            this.visible.sp = false;
            this.innerVisible = false;
            this.reasonFailure = "";
          } else {
            this.$el_message(res.message, null, "error");
          }
        } catch (e) {
          console.error(e);
        }
      }
    },

    async handle_report(row) {
      const res = await API.TASK.GetReport({
        id: row.id,
      });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(res);
      a.download = `${row.taskTitle}.docx`;
      a.click();
    },
    async delList(id) {
      try {
        const res = await API.TASK.Del(id);
        if (res.status == 1) {
          this.$el_message("删除成功");
          if (this.dataSource.length === 1) {
            if (this.pagination.page > 1) {
              this.pagination.page--;
            }
          }
          this.async_get_list();
        } else {
          this.$el_message(res.message, null, "error");
        }
      } catch (e) {
        console.error(e);
      }
    },
    cancels(data) {
      this.visible.qx = true;
      this.visible.qxid = data.id;
    },
    async cancel(value) {
      if (value) {
        const res = await API.TASK.Edit({
          id: this.visible.qxid,
          taskStatus: -1,
        });
        this.async_get_list();
        this.visible.qx = false;
      } else {
        this.visible.qx = false;
      }
    },
    seeAirline(id) {
      this.getFligth(id);
    },
    async getFligth(id) {
      // 预览航线
      let res = await API.TASK.viewFlight(id);
      if (res.status === 1) {
        this.historicalTrackList = [];
        this.taskshow.list = res.data;
        this.taskshow.show = true;
      } else {
        this.$message.error(res.message);
      }
    },
    async historicalTrack(deviceHardId, taskId) {
      // 历史轨迹
      let res = await API.TASK.historicalTrack({ deviceHardId, taskId });
      if (res.code == 200 && res.data.track.length > 0) {
        let fdata = [];
        if (res.data.track.length > 500) {
          let pointList = Math.floor(res.data.track.length / 500);
          for (let j = 0; j < res.data.track.length; j++) {
            if (j % pointList === 0) {
              fdata.push(res.data.track[j].longitude * 1);
              fdata.push(res.data.track[j].latitude * 1);
              fdata.push(res.data.track[j].height * 1);
            }
          }
        } else {
          for (let j = 0; j < res.data.track.length; j++) {
            fdata.push(res.data.track[j].longitude * 1);
            fdata.push(res.data.track[j].latitude * 1);
            fdata.push(res.data.track[j].height * 1);
          }
        }
        this.historicalTrackList = fdata;
        this.taskshow.list = null;
        this.taskshow.show = true;
      } else {
        this.$message.error("未查询到历史轨迹");
      }
    },
    clearTask(done) {
      this.taskshow.list = null;
      this.taskshow.show = false;
      done && done();
    },
    // 打开日志推送接口
    async log(value) {
      this.journalid = value.id;
      this.logList = value;
      const res = await API.TASK.taskLog({
        taskId: value.id,
        type: 0,
      });
      if (res.status === 1) {
        this.key = res.data.key;
        this.logData = res.data;
        if (this.logData.flightTime != null) {
          console.log(this.logData.flightTime);
          this.logData.flightTime = this.formatSeconds(this.logData.flightTime) 
        }else {
          this.logData.flightTime = '暂无'
        }
        this.visible.logShow = true;
        this.videoslist =
          res.data.videos.length >= 1 ? res.data.videos[0] : null;
        console.log(this.videoslist);
      } else {
        this.$message.error(res.message);
      }
    },
    formatSeconds(value) {
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
      result = `${h}:${m}:${s}`
      return result
    },
    // 关闭日志弹框
    handleClose(done) {
      this.videoslist = null;
      done && done();
    },
    // 日志下载
    async downLog() {
      try {
        const blob = await API.TASK.exportData({
          taskId: this.logData.taskId,
        });
        if (blob) {
          let binaryData = [];
          binaryData.push(blob);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob(binaryData));
          a.download = `${this.logList.taskTitle}.xls`;
          a.click();
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.async_get_list();
    this.async_get_ai_list();
  },
  destroyed() {},
};
</script>
<style>
.taskapprove .el-dialog__body {
  padding: 28px 20px 0px 20px !important;
}
</style>
<style lang="scss" scoped>

::v-deep {
  .out-content {
    .el-dialog {
      
      margin-top: 80px !important;
    }
  }
  .el-textarea__inner {
    opacity: 0.8;
    background-color: #122a53;
    border: 1px solid #488cff;
    color: #9fc4ff;
  }
}
.page-service-task-list {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .task-table {
    flex: 1;
    margin-top: 27px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .origin {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      .iconfont {
        cursor: pointer;
        font-size: 26px;
        color: #9fc4ff;
        margin-right: 4px;
        &:last-child {
          margin-right: 0px;
        }
        line-height: 26px;
      }
    }
  }
}
</style>
<style lang="scss">
.more-popover {
  background-color: #0a234cb3;
  min-width: 120px;
  max-width: 120px;
  border: 1px solid #009aff;
  display: flex;
  justify-content: center;
  padding: 0;
  &-box {
    width: 60px;
    color: #9BBFF8;
    margin: 9px 0;
    text-align: center;
    div {
      padding-bottom: 18px;
    }
    div:last-child {
      padding-bottom: 0;
    }
  }
  .pointer {
    cursor: pointer;
  }
}
.more-popover-box .pointer:hover {
  color: #35a2ff;
}
.taskapprove {
  .button {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding: 28px 55px;
  }
}

.taskDialong {
  width: 100%;
  .taskDraw {
    top: 32px !important;
    height: 800px !important;
  }
  .el-dialog__body {
    height: 742px;
  }
}

.logTable {
  display: flex;
  .tableRight {
    padding-left: 16px;
    width: 436px;
    &-down {
      display: flex;
      .log {
        margin-left: 50px;
      }
    }
  }
  .logBody {
    padding-top: 14px;
    display: flex;
    .props-box {
      .prop-field {
        margin-bottom: 22px;
      }
    }
  }
  .logFooter {
    display: flex;
    align-items: center;
    .block {
      width: 220px;
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
    .el-slider__button {
      width: 11px;
      height: 11px;
    }
  }

  .props-box {
    flex: 1;
    box-sizing: border-box;
    .prop-field {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
      .prop-field-k {
        font-size: 16px;
        color: #cce8ff;
        letter-spacing: 0;
        font-weight: 400;
        width: 120px;
        text-align: left;
      }
      .prop-field-v {
        font-size: 16px;
        color: #cce8ff;
        letter-spacing: 0;
        font-weight: 400;
        max-width: 300px;
      }
    }
  }
}

.statuss {
  pointer-events: none;
}

.anniu {
  display: flex;
  div:nth-child(1) {
    margin-right: 50px;
  }
}
</style>
